import { BaseModel, Validations, SaveType, AppConfig } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { UsuarioChile } from "./usuario_chile.model"
import { Lugar } from "./lugares.model"
import { Establecimiento } from "./establecimientos.model"
import { UsuarioRegistroBase } from "./usuarios_registro_base.model"
import { PreferenciaArea, PreferenciaUniversidad } from "@puntaje/carreras/api-services"
declare const config: AppConfig

export class UsuarioRegistro extends UsuarioRegistroBase {
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public email: string
    public fecha_nacimiento: Date
    public password: string
    public password_confirmation: string

    public egresado: number
    public genero: number
    public lugar_id: number
    public establecimiento_id: number
    public rut: string
    public telefono: string
    public celular: string
    public codigo_etnia: number
    public direccion: string
    public profesor: boolean
    public nivel_id: number
    public typoEnvio: string
    public area_id: number

    public preferenciaUniversidades: PreferenciaUniversidad[] = []
    public preferenciaAreas: PreferenciaArea[] = []

    @SaveType(() => Lugar) public lugar: Lugar
    @SaveType(() => Establecimiento) public colegio: Establecimiento

    public static global_validations = [Validations.equalPasswords(["password", "password_confirmation"])]

    constructor() {
        super()
        this.profesor = false
        this.lugar = new Lugar()
        //this.colegio = new Establecimiento()
    }

    public nombreCompleto() {
        return this.nombre + " " + this.apellido_paterno + " " + this.apellido_materno
    }

    public toString() {
        return this.email
    }

    public toUsuario() {
        var user = new Usuario()
        user.nombre = this.nombre
        user.apellido_paterno = this.apellido_paterno
        user.apellido_materno = this.apellido_materno
        user.fecha_nacimiento = this.fecha_nacimiento
        user.email = this.email
        user.password = this.password
        user.password_confirmation = this.password_confirmation
        user.tipo_envio = this.typoEnvio
        user.profesor = this.profesor
        if (this.lugar && this.lugar.id) {
            user.lugar_id = this.lugar.id
        }
        if (this.colegio && !this.egresado) {
            user.establecimiento_id = this.colegio.id
        }
        if (this.nivel_id) {
            user.nivel_id = this.nivel_id
        }
        ;(user as any).pais = config.plataforma.pais
        ;(user as any).rol = "Estudiante"
        user.usuario_colombia = null
        user.genero = this.genero
        if (!user.usuario_chile) {
            user.usuario_chile = new UsuarioChile()
        }
        user.usuario_chile.rut = this.rut
        user.usuario_chile.telefono = this.telefono
        user.usuario_chile.celular = this.celular
        user.usuario_chile.codigo_etnia = this.codigo_etnia
        user.usuario_chile.direccion = this.direccion

        if (this.preferenciaUniversidades.length > 0) {
            user.preferencia_universidades = this.preferenciaUniversidades
        }

        if (this.area_id) {
            user.preferencia_areas ||= []
            user.preferencia_areas.push(Object.assign(new PreferenciaArea(), { areaId: this.area_id }))
        }

        return user
    }
}

import { BaseForm, Validations, GroupValidator, AppConfig } from "@puntaje/shared/core"

import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"

import { Clasificaciones } from "@puntaje/nebulosa/api-services"

import { UsuarioChileForm } from "./usuario_chile.form"
import { HttpClient } from "@angular/common/http"

declare const config: AppConfig

export class UsuarioRegistroAlClForm extends BaseForm {
    public static get globalValidations(): GroupValidator[] {
        return [Validations.equalPasswords(["password", "password_confirmation"])]
    }

    companyName: string = ""

    public static get formParams(): any {
        let companyName = config.plataforma.info.companyName

        return {
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                placeholder: "Nombre",
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Apellido paterno",
                type: "text",
                visible: true,
                placeholder: "Apellido paterno",
                validations: [Validators.required, Validators.minLength(2)]
            },
            rut: {
                label: "RUT",
                type: "text",
                visible: true,
                placeholder: "11111111-1",
                validations: [Validators.required, Validations.validateRut],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueRut }]
            },
            email: {
                label: "Email",
                type: "text",
                visible: true,
                placeholder: "correo@mail.com",
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmail }]
            },
            serial: {
                type: "hidden"
            },
            lugar: {
                label: "Comuna",
                type: "autocomplete",
                visible: true,
                placeholder: "Escribe y selecciona entre las opciones",
                options: { class: Lugares, params: { lugar_tipo: "Comuna" } }
            },
            password: {
                label: "Contraseña",
                type: "password",
                visible: true,
                placeholder: "Contraseña",
                validations: [Validators.required, Validators.minLength(8)],
                serviceValidators: [{ class: HttpClient, validator: Validations.checkPasswordBreach }]
            },
            password_confirmation: {
                label: "Confirmar contraseña",
                type: "password",
                visible: true,
                placeholder: "Repetir contraseña",
                global: true,
                validations: [Validators.required, Validators.minLength(8)]
            },
            terminos_y_condiciones: {
                innerhtml:
                    "He leído y acepto las <a href='/condicionesUso' target='_blank'>condiciones generales de uso de " +
                    companyName +
                    "</a>",
                type: "checkbox",
                visible: true,
                validations: [Validators.requiredTrue],
                customError: "Debe aceptar los términos generales de uso de " + companyName
            },
            telefono: {
                label: "Teléfono celular",
                type: "text",
                visible: true,
                placeholder: "981234567",
                validations: [Validators.required, Validations.validateTelefonoChile]
            },
            egresado: { label: "Egresado", type: "checkbox", visible: true },
            colegio: { label: "Colegio", type: "text", visible: true, validations: [Validators.required] },
            nivel_id: {
                label: "Curso",
                type: "select",
                visible: true,
                options: { class: Clasificaciones, params: { clasificacion_tipo: { clasificacion_tipo: "curso" } } },
                validations: [Validators.required]
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true },
            lugar_id: { label: "Lugar Id", type: "text", visible: true }
        }
    }
}

<cui-logged-layout [freeContentStyle]="false" [hideTitleOnPrint]="false">
    <cui-layout-header>
        <navbar *ngIf="authService.isLoggedIn()"></navbar>
    </cui-layout-header>
    <cui-layout-footer>
        <footer-component></footer-component>
        <alerta-box></alerta-box>
        <typeform-widgets-view></typeform-widgets-view>
    </cui-layout-footer>
</cui-logged-layout>

import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType,
    MensajesLoginType,
    ComponentsMap,
    CargaNominaConf
} from "@puntaje/shared/core"

export class AprendoLibreConfig implements AppConfiguration {
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.svg",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.svg",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.svg",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquareLight.png",
            logoInforme: "assets/img/logos/logoSmall.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            ayuda: {
                showUploadDate: false
            },
            sharedLayout: {
                backgroundImage: ""
            }
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = null

    plataforma = {
        name: "Aprendolibre",
        id: null,
        pais: "chile",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        canTextHighlight: true,
        identificadorUsuario: ["rut", "usuario_id"],
        identificadorUsuarioAlias: {
            rut: "RUT",
            usuario_id: "ID"
        },
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        info: {
            social: {
                facebook: "https://www.facebook.com/aprendolibrelatam/",
                youtube: "https://www.youtube.com/c/AprendoLibrecl",
                instagram: "https://www.instagram.com/aprendolibrelatam",
                linkedin: "https://www.linkedin.com/company/open-green-road",
                twitch: "https://www.twitch.tv/aprendolibre",
                tiktok: "https://www.tiktok.com/@aprendolibre"
            },
            soporte: {
                mail: "monitores@ogr.cl",
                // telefono: "<a href='tel:+56963235120'>(+56) 9 6323 5120</a><br /><a href='tel:+56935783784'>(+56) 9 3578 3784</a><br /><a href='tel:+56935867757'>(+56) 9 3586 7757</a><br /><a href='tel:+56936641076'>(+56) 9 3664 1076</a>",
                telefonos: ["(+56) 9 45387501", "(+57) 3 166784207"]
            },
            contacto: {
                telefono: "(+56) 9 3578 3784<br />(+56) 9 3586 7757<br />(+56) 9 3664 1076",
                mail: "ventas@ogr.cl"
            },
            companyName: "AprendoLibre.cl",
            companySite: "www.aprendolibre.cl"
        },
        evaluacionDefault: "curricular",
        resumenGeneral: {
            curriculum: "simce",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: ["curricular"],
        evaluacionTipos: ["curricular", "diagnostico curricular"],
        añoInicial: 2017,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 30, 23, 59, 59]
            }
        ],
        cursosDefault: [
            { id: 1, clasificacion: "Primero Básico", label: "1º Básico" },
            { id: 2, clasificacion: "Segundo Básico", label: "2º Básico" },
            { id: 3, clasificacion: "Tercero Básico", label: "3º Básico" },
            { id: 4, clasificacion: "Cuarto Básico", label: "4º Básico" },
            { id: 5, clasificacion: "Quinto Básico", label: "5º Básico" },
            { id: 6, clasificacion: "Sexto Básico", label: "6º Básico" },
            { id: 7, clasificacion: "Séptimo Básico", label: "7º Básico" },
            { id: 8, clasificacion: "Octavo Básico", label: "8º Básico" },
            { id: 9, clasificacion: "Primero Medio", label: "1º Medio" },
            { id: 10, clasificacion: "Segundo Medio", label: "2º Medio" },
            { id: 11, clasificacion: "Tercero Medio", label: "3º Medio" },
            { id: 12, clasificacion: "Cuarto Medio", label: "4º Medio" },
            { id: 13, clasificacion: "Egresado", label: "Egresado" },
            {
                id: 14,
                clasificacion: "Primer Nivel de Transición",
                label: "1º Transición"
            },
            {
                id: 15,
                clasificacion: "Segundo Nivel de Transición",
                label: "2º Transición"
            }
        ],
        iconSet: {
            noticiasShowcase: "megafono",
            establecimientosShowcase: "establecimiento2",
            cursosShowcase: "comunidad"
        },
        showLinkAlumnoAlCompartir: true,
        hideReplicarPlan: true,
        hideReforzamientos: true,
        disableZopim: false,
        streamingDefault: "curricular",
        planClasesRoute: "/plan_estudios",
        planClasesRouteDocentes: "/plan_estudios",
        loginGoogle: true,
        planClasesCountInWeeks: false,
        nombreSesion: "Sesión",
        nombreSesiones: "Sesiones",
        cursosStreaming: [
            { id: 1, clasificacion: "Primero Básico" },
            { id: 2, clasificacion: "Segundo Básico" },
            { id: 3, clasificacion: "Tercero Básico" },
            { id: 4, clasificacion: "Cuarto Básico" },
            { id: 5, clasificacion: "Quinto Básico" },
            { id: 6, clasificacion: "Sexto Básico" },
            { id: 7, clasificacion: "Séptimo Básico" },
            { id: 8, clasificacion: "Octavo Básico" }
        ],
        customErrorMessage:
            "<p>Si experimentaste un problema, te recomendamos utilizar Google Chrome y eliminar las cookies y caché de tu navegador, cerrarlo y volverlo a abrir.</p><p>Si el error persiste, escribe a <b>contacto@ogr.cl</b>, indicando tu RUT, el detalle de la sección en que te encontrabas y el código de error puesto más abajo.</p><p>¡Con más información podemos identificar el error y resolverlo!</p>",
        hojaRespuestaEjemploLink:
            "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/latex/hoja_aprendolibre_2_2022.pdf",
        enableTipoEscala: true,
        planClasesUseNiveles: true,
        showcaseBoxBlogWithTitle: true
    } as PlataformaType

    evaluaciones = {
        // ensayo, curricular, diagnostico ensayo
        simce: {
            alias: "evaluación SIMCE",
            cantidadPreguntas: 4,
            clasificaciones: {
                estadisticas: ["eje temático LGE"],
                estadisticasEvolutivas: "eje temático LGE",
                estadisticasEvolutivasNiveles: true,
                groupBy: ["eje temático LGE,objetivo de aprendizaje"],
                desempenoEvaluacion: "eje temático LGE,objetivo de aprendizaje",
                clasificacionTiposTablaEspecificaciones: ["eje temático LGE"],
                concats: { "eje temático LGE": "curso" },
                methods: "curso",
                configuration: [
                    {
                        clasificacionTipo: "eje temático LGE",
                        name: "Eje de contenidos",
                        htmlId: "eje",
                        hasHijos: true
                    }
                ],
                cambiarPregunta: ["eje temático LGE", "objetivo de aprendizaje"]
            },
            clasificacionMaterial: {
                clasificacion: "eje temático LGE",
                label: "eje"
            },
            planPersonalClasificacionMateriales: [
                {
                    clasificacion: "eje temático LGE",
                    label: "eje"
                }
            ],
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático LGE",
                    compuesto: false,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "habilidad cognitiva LGE",
                    compuesto: false,
                    alumnos: false,
                    profesores: true
                },
                {
                    nombre: "objetivo de aprendizaje",
                    compuesto: false,
                    alumnos: false,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursos: [
                { clasificacion: "Primero Básico", label: "1º Básico" },
                { clasificacion: "Segundo Básico", label: "2º Básico" },
                { clasificacion: "Tercero Básico", label: "3º Básico" },
                { clasificacion: "Cuarto Básico", label: "4º Básico" },
                { clasificacion: "Quinto Básico", label: "5º Básico" },
                { clasificacion: "Sexto Básico", label: "6º Básico" },
                { clasificacion: "Séptimo Básico", label: "7º Básico" },
                { clasificacion: "Octavo Básico", label: "8º Básico" },
                { clasificacion: "Primero Medio", label: "1º Medio" },
                { clasificacion: "Segundo Medio", label: "2º Medio" },
                { clasificacion: "Tercero Medio", label: "3º Medio" },
                { clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            cursosMaterial: [],
            estrellas: [
                { min: 140, max: 181 },
                { min: 182, max: 223 },
                { min: 224, max: 265 },
                { min: 266, max: 307 },
                { min: 308, max: 349 },
                { min: 350, max: 387 }
            ],
            nivelTipoInstrumentoInformeFinal: ["2º Básico", "4º Básico", "6º Básico", "8º Básico", "2º Medio"],
            rangoCalificacion: {
                min: 0,
                tickInterval: 25,
                max: 387
            },
            distribucionTramoPuntaje: {
                Lenguaje: {
                    2: [0, 215, 265],
                    4: [0, 241, 284],
                    6: [0, 233, 279],
                    8: [0, 244, 292],
                    10: [0, 250, 295]
                },
                Matemática: {
                    4: [0, 245, 295],
                    6: [0, 226, 286],
                    8: [0, 247, 297],
                    10: [0, 252, 319]
                },
                "Ciencias Naturales": {
                    4: [0, 247, 282],
                    6: [0, 226, 285],
                    8: [0, 248, 297],
                    10: [0, 250, 296]
                },
                "Historia y Ciencias Sociales": {
                    6: [0, 226, 285],
                    8: [0, 243, 296],
                    10: [0, 250, 296]
                }
            },
            informeFinal: {
                cursos: [
                    { id: 1, clasificacion: "Primero Básico", label: "1º Básico" },
                    { id: 2, clasificacion: "Segundo Básico", label: "2º Básico" },
                    { id: 3, clasificacion: "Tercero Básico", label: "3º Básico" },
                    { id: 4, clasificacion: "Cuarto Básico", label: "4º Básico" },
                    { id: 5, clasificacion: "Quinto Básico", label: "5º Básico" },
                    { id: 6, clasificacion: "Sexto Básico", label: "6º Básico" },
                    { id: 7, clasificacion: "Séptimo Básico", label: "7º Básico" },
                    { id: 8, clasificacion: "Octavo Básico", label: "8º Básico" }
                ]
            },
            cursoTipo: "curso"
        },
        curricular: {
            alias: "prueba",
            escalaEvaluacion: "nota",
            cantidadPreguntas: 4,
            clasificaciones: {
                estadisticas: ["eje temático LGE"],
                estadisticasEvolutivas: "eje temático LGE",
                estadisticasEvolutivasNiveles: true,
                groupBy: ["eje temático LGE,objetivo de aprendizaje"],
                desempenoEvaluacion: "eje temático LGE,objetivo de aprendizaje",
                clasificacionTiposTablaEspecificaciones: ["eje temático LGE"],
                configuration: [
                    {
                        clasificacionTipo: "eje temático LGE",
                        name: "Eje de contenidos",
                        htmlId: "eje",
                        hasHijos: true
                    }
                ],
                cambiarPregunta: ["eje temático LGE", "objetivo de aprendizaje"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "eje temático LGE",
                label: "eje"
            },
            planPersonalClasificacionMateriales: [
                {
                    clasificacion: "eje temático LGE",
                    label: "eje"
                }
            ],
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático LGE",
                    compuesto: false,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "habilidad cognitiva LGE",
                    compuesto: false,
                    alumnos: false,
                    profesores: true
                },
                {
                    nombre: "objetivo de aprendizaje",
                    compuesto: false,
                    alumnos: false,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursos: [
                { clasificacion: "Primero Básico", label: "1º Básico" },
                { clasificacion: "Segundo Básico", label: "2º Básico" },
                { clasificacion: "Tercero Básico", label: "3º Básico" },
                { clasificacion: "Cuarto Básico", label: "4º Básico" },
                { clasificacion: "Quinto Básico", label: "5º Básico" },
                { clasificacion: "Sexto Básico", label: "6º Básico" },
                { clasificacion: "Séptimo Básico", label: "7º Básico" },
                { clasificacion: "Octavo Básico", label: "8º Básico" }
            ],
            cursosMaterial: [
                { id: 1, clasificacion: "Primero Básico", label: "1º Básico" },
                { id: 2, clasificacion: "Segundo Básico", label: "2º Básico" },
                { id: 3, clasificacion: "Tercero Básico", label: "3º Básico" },
                { id: 4, clasificacion: "Cuarto Básico", label: "4º Básico" },
                { id: 5, clasificacion: "Quinto Básico", label: "5º Básico" },
                { id: 6, clasificacion: "Sexto Básico", label: "6º Básico" },
                { id: 7, clasificacion: "Séptimo Básico", label: "7º Básico" },
                { id: 8, clasificacion: "Octavo Básico", label: "8º Básico" }
            ],
            estrellas: [
                { min: 1, max: 2 },
                { min: 2, max: 3 },
                { min: 3, max: 4 },
                { min: 4, max: 5 },
                { min: 5, max: 6 },
                { min: 6, max: 7 }
            ],
            // nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            nivelTipoInstrumentoInformeFinal: [
                "1º Básico",
                "2º Básico",
                "3º Básico",
                "4º Básico",
                "5º Básico",
                "6º Básico",
                "7º Básico",
                "8º Básico"
            ],
            rangoCalificacion: {
                min: 1,
                tickInterval: 0.5,
                max: 7
            },
            streamingChannelAlias: "Clases Curriculares",
            informeFinal: {
                cursos: [
                    { id: 1, clasificacion: "Primero Básico", label: "1º Básico" },
                    { id: 2, clasificacion: "Segundo Básico", label: "2º Básico" },
                    { id: 3, clasificacion: "Tercero Básico", label: "3º Básico" },
                    { id: 4, clasificacion: "Cuarto Básico", label: "4º Básico" },
                    { id: 5, clasificacion: "Quinto Básico", label: "5º Básico" },
                    { id: 6, clasificacion: "Sexto Básico", label: "6º Básico" },
                    { id: 7, clasificacion: "Séptimo Básico", label: "7º Básico" },
                    { id: 8, clasificacion: "Octavo Básico", label: "8º Básico" }
                ]
            },
            cursoTipo: "curso"
        },
        "curricular PIE": {
            alias: "prueba",
            cantidadPreguntas: 4,
            clasificaciones: {
                estadisticas: ["eje temático LGE"],
                groupBy: ["eje temático LGE,objetivo de aprendizaje"],
                configuration: [
                    {
                        clasificacionTipo: "eje temático LGE",
                        name: "Eje de contenidos",
                        htmlId: "eje",
                        hasHijos: true
                    }
                ]
            },
            clasificacionMaterial: {
                clasificacion: "eje temático LGE",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático LGE",
                    compuesto: false,
                    alumnos: true,
                    profesores: true
                }
            ],
            cursoTipo: "curso"
        },
        "diagnostico curricular": {
            alias: "prueba de diagnóstico",
            clasificaciones: {
                estadisticas: ["eje temático LGE"],
                groupBy: ["eje temático LGE,objetivo de aprendizaje"],
                clasificacionTiposTablaEspecificaciones: [],
                configuration: [
                    {
                        clasificacionTipo: "eje temático LGE",
                        name: "Eje de contenidos",
                        htmlId: "eje",
                        hasHijos: true
                    }
                ]
            },
            cursoTipo: "curso"
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3", "Columna 4", "Columna 5"]
    }

    aditionalClass: true

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2]
        ],
        alternativas: 4,
        dimSectoresByMarkers: {
            10: {
                "1,2": { cols: 4, rows: 10 },
                "2,3": { cols: 4, rows: 10 },
                "3,4": { cols: 4, rows: 10 },
                "5,2": { cols: 4, rows: 10 },
                "9,6": { cols: 10, rows: 10 },
                "6,10": { cols: 10, rows: 10 }
            }
        },
        rutFormaByMarkers: {
            10: {
                rutMarkerUp: 6,
                rutMarkerBottom: 10,
                rutCols: 11,
                formaMarkerUp: 9,
                formaMarkerBottom: 6,
                formaCols: 11
            }
        }
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o RUT (sin puntos, con dígito verificador)",
        invalidLoginId: "Debes incluir un correo electrónico o RUT válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o RUT/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.',
        invalidLoginInactiveEstablishments:
            "<p>Su cuenta no se encuentra asociada a ningún establecimiento activo.</p><p>Para activar su cuenta le rogamos comunicarse con el administrador de su establecimiento o con su monitor de Aprendolibre.cl al correo <b>aprendolibre@ogr.cl</b> o al teléfono <b>(+56) 9 3578 3784</b>, <b>(+56) 9 3586 7757</b> o al <b>(+56) 9 3664 1076</b>.</p>"
    } as MensajesLoginType

    mensajesInformativos = {
        problemasPlataforma: ``
    }

    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: true,
        buscador: true
    }
    componentsInSubmodule = {
        subirPruebaPropia: {
            skipContestableTipos: false
        }
    } as ComponentsMap

    cargaNomina = {
        nomina: {
            profesores: "NominaProfeAprendolibreChile"
        }
    } as CargaNominaConf
}

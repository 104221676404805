import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import {
    ClasePlanClaseComponent,
    PlanClaseComponent,
    PlanClaseEditComponent,
    PlanClaseNewComponent,
    PlanClasesComponent
} from "@puntaje/puntaje/core"

const routes: Routes = [
    {
        path: "plan_clases/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClaseNewComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_clases/:id/edit",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClaseEditComponent,
                canActivate: [AuthGuard],
                data: {
                    urlRedirect: "/tu_curso"
                }
            }
        ]
    },
    {
        path: "plan_clases/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClaseComponent,
                canActivate: [AuthGuard]
            }
        ]
    },
    {
        path: "plan_clases/:id/sesiones/:sesionId",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [AuthGuard],
                data: {
                    planUrl: "/plan_clases",
                    isLibroFrom: "docente",
                    enableReporte: false,
                    showVerLibro: false
                }
            }
        ]
    },
    {
        path: "plan_clases",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesComponent,
                canActivate: [AuthGuard],
                data: {
                    enableSiteWarning: false
                }
            }
        ]
    },
    {
        path: "tu_curso",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesComponent,
                canActivate: [AuthGuard],
                data: {
                    enableSiteWarning: false
                }
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfesoresPlanClasesRoutingModule {}

import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { PreguntasFrecuentesComponent } from "./preguntas-frecuentes/preguntas-frecuentes.component"
import { AyudaComponent, AyudasComponent, HomeAyudasProfesorComponent } from "@puntaje/puntaje/core"

export const ayudaRoutes: Routes = [
    {
        path: "ayudas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeAyudasProfesorComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ayudas/materiales",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AyudasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ayudas/preguntas_frecuentes",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PreguntasFrecuentesComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ayudas/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: AyudaComponent, canActivate: [AuthGuard] }]
    }
]

export const ayudaRouting = RouterModule.forChild(ayudaRoutes)

import { Component, OnInit, Input } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store } from "@ngrx/store"
import { State, GetStoreConfig } from "@puntaje/puntaje/store"
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms"
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { HttpClient } from "@angular/common/http"

@Component({
    selector: "landing-aprendolibre",
    templateUrl: "./landing-aprendolibre.component.html",
    styleUrls: ["./landing-aprendolibre.component.scss"]
})
export class LandingAprendolibreComponent implements OnInit {
    config: typeof config
    @Input() pais: string = "mexico"
    @Input() nombreDocente: string = "profesor"
    @Input() nombreDocentePlural: string = "profesores"
    @Input() isBeta: boolean = true
    @Input() urlRegistro: string
    @Input() postPerfiles: string[] = ["todos"]

    contactoForm: UntypedFormGroup
    submitted: boolean

    constructor(protected store: Store<State>, private fb: UntypedFormBuilder, protected http: HttpClient) {}

    get contactoNombre() {
        return this.contactoForm.get("nombre")
    }
    get contactoApellidos() {
        return this.contactoForm.get("apellidos")
    }
    get contactoEmail() {
        return this.contactoForm.get("email")
    }
    get contactoTelefono() {
        return this.contactoForm.get("telefono")
    }
    get contactoEstablecimiento() {
        return this.contactoForm.get("establecimiento")
    }

    sendContacto() {
        if (this.contactoForm.valid) {
            let url = environment.endpoints.base + "/contacto_profesor"
            this.http
                .post(url, {
                    contacto: {
                        nombre: this.contactoNombre.value,
                        apellidos: this.contactoApellidos.value,
                        email: this.contactoEmail.value,
                        telefono: this.contactoTelefono.value,
                        establecimiento: this.contactoEstablecimiento.value,
                        send_to: "mexico@aprendolibre.com"
                    }
                })
                .toPromise()
                .then(response => {
                    this.contactoForm.reset()
                    this.submitted = true
                })
        }
    }

    ngOnInit(): void {
        this.config = config
        this.contactoForm = this.fb.group({
            nombre: ["", Validators.required],
            apellidos: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            telefono: ["", Validators.required],
            establecimiento: ["", Validators.required]
        })
        this.store.dispatch(new GetStoreConfig())
    }
}

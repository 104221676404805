import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { PlanClasesModule } from "@puntaje/puntaje/new-modules/plan-clases"
import { PlanEstudiosModule } from "@puntaje/puntaje/new-modules/plan-estudios"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"

import { routing } from "./profesores_plan_estudios.routing"
import { PuntajePlanClasesModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ProfesoresLayoutsModule,
        PlanClasesModule,
        PlanEstudiosModule,
        SharedModule,
        routing,
        PuntajePlanClasesModule
    ],
    exports: [],
    declarations: [],
    providers: []
})
export class ProfesoresPlanEstudiosModule {}

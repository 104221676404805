import { Component, OnInit, Input } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "footer-aprendolibre",
    templateUrl: "./footer-aprendolibre.component.html",
    styleUrls: ["../landing-aprendolibre.component.scss"]
})
export class FooterAprendolibreComponent implements OnInit {
    config: typeof config
    @Input() pais: string = "mexico"
    constructor() {}

    ngOnInit() {
        this.config = config
    }
}

import { Component, OnInit, Input } from "@angular/core"
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from "@angular/forms"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Asociacion, Asociaciones, Establecimientos, Establecimiento } from "@puntaje/puntaje/api-services"
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { AuthService, Validations } from "@puntaje/shared/core"

interface ContactForm {
    establecimiento_id: number
    telefono: string
    comentario: string
}

@Component({
    selector: "ayuda-contacto",
    templateUrl: "./ayuda-contacto.component.html",
    styleUrls: ["./ayuda-contacto.component.scss"]
})

export class AyudaContactoComponent implements OnInit {
    @Input() usuarioId: any
    showSuccess: boolean
    submitted: boolean
    asociaciones: Asociacion[]
    establecimientos: Establecimiento[] = []
    form: UntypedFormGroup
    establecimientoIdSelected: number
    constructor(protected formBuilder: UntypedFormBuilder, protected establecimientosService: Establecimientos,
        protected asociacionesService: Asociaciones, protected http: HttpClient,
        protected auth: AuthService) { }

    ngOnInit() {
        this.buildForm()
        this.getEstablecimientos()
    }

    buildForm() {
        this.form = this.formBuilder.group({
            establecimiento_id: [
                undefined,
                [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(250)]
            ],
            telefono: ["", [<any>Validators.required, <any>Validators.minLength(5), <any>Validators.maxLength(15), Validations.validateCelularChile]],
            mensaje: ["", [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(300)]]
        })
    }

    getEstablecimientos() {
        this.asociacionesService
            .where({
                usuario_id: this.usuarioId,
                include: "establecimientos"
            })
            .then((asociaciones: Asociacion[]) => {
                this.establecimientosService.where({ mios: 1 }).then((establecimientos: Establecimiento[] = []) => {
                    if (asociaciones && asociaciones.length > 0) {
                        this.asociaciones = asociaciones
                        this.establecimientos = ([] as Establecimiento[]).concat.apply(
                            [],
                            this.asociaciones.map(x => x.establecimientos)
                        )
                    }
                    if (establecimientos && establecimientos.length > 0 && this.establecimientos) {
                        establecimientos.forEach(establecimiento => {
                            if (!this.establecimientos.find(e => e.id == establecimiento.id))
                                this.establecimientos.push(establecimiento)
                        })
                    }
                    if (this.establecimientos && this.establecimientos.length == 1) {
                        this.establecimientoIdSelected = this.establecimientos[0].id
                    }
                })
            })
    }

    save(model: ContactForm, isValid: boolean) {
        var header = new HttpHeaders({ Authorization: this.auth.getToken() })
        var opts: { headers?: HttpHeaders; observe: "response"; responseType?: "json" } = {
            headers: header,
            observe: "response"
        }
        this.showSuccess = false
        this.submitted = true
        let mail_data = { contacto: model }
        if (isValid) {
            let url = environment.endpoints.base + "/contacto_ayuda_docente"
            this.http
                .post(url, mail_data, opts)
                .toPromise()
                .then(response => {
                    this.submitted = false
                    this.buildForm()
                    this.showSuccess = true
                })
        }
    }
}

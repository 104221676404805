import { PuntajeBlogModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { BlogModule } from "@puntaje/puntaje/new-modules/blog"

import { profesoresBlogRouting } from "./profesores_blog.routing"
import { UtilModule, ModelViewModule } from "@puntaje/shared/core"

import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ProfesoresLayoutsModule,
        UtilModule,
        BannersModule,
        ModelViewModule,
        BlogModule,
        profesoresBlogRouting,
        PuntajeBlogModule
    ],
    declarations: [],
    exports: []
})
export class ProfesoresBlogModule {}

import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { PlanPersonal } from "@puntaje/puntaje/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./detalle-plan-alumno.component.html"
})
export class DetallePlanAlumnoComponent implements OnInit {
    sub: any
    planPersonalId: number
    usuarioId: number
    planPersonal: PlanPersonal
    titulo: string = ""

    constructor(protected titleService: TitleService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.detalle_plan_alumno")
        this.sub = this.route.params.subscribe(params => {
            this.planPersonalId = +params["id"]
            this.usuarioId = +params["usuario_id"]
        })
    }

    onReady(planPersonal) {
        this.planPersonal = planPersonal
        this.titulo = this.planPersonal.plan_personal
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}

<h3 class="titulo_pauta">Pauta</h3>
<p class="pauta-text-descripcion" *ngIf="showDescripcion">
    Las preguntas marcadas con (P) corresponden a preguntas piloto y no se consideran en el cálculo de la calificación.
</p>
<br />
<loading-layout #loadingLayout>
    <div class="multiple-col" *ngIf="showPauta">
        <ul class="lista-pauta">
            <li *ngFor="let obj of pauta; let i = index" class="respuesta">
                <span class="numero">{{ i + 1 }}:</span>
                <ng-container *ngIf="obj.letra; else otrosContestables">
                    <span class="valor">{{ obj.letra | uppercase }}</span>
                </ng-container>
                <ng-template #otrosContestables>
                    {{ preguntas[i].contestable.contestable_tipo.contestable_tipo }}
                </ng-template>
                <label class="piloto-activo" *ngIf="obj.piloto">(P)</label>
            </li>
        </ul>
    </div>
    <div class="edit-button-container">
        <div class="edit-button-wrap">
            <cui-button [isBlock]="true" (click)="edit()">Editar Pauta y Clasificaciones</cui-button>
        </div>
        <div
            class="edit-tooltip"
            [tooltip]="
                'Solo se pueden editar los tipos de respuesta si la prueba no ha sido contestado por alumnos. De lo contrario, solo se pueden corregir alternativas y pilotajes'
            "
        >
            <fa name="question-circle-o"></fa>
        </div>
    </div>
</loading-layout>

<generic-modal [buttonClicked]="openModal">
    <modal-titulo>
        <cui-title-headline>Editar Pauta y Clasificaciones</cui-title-headline>
    </modal-titulo>
    <modal-contenido>
        <cui-tabs justifyContent="space-around">
            <cui-tab title="Editar Pauta" ariaLabelledBy="editar pauta">
                <subir-pauta-mixta
                    [hideHeading]="true"
                    [hideContinue]="true"
                    [disableChangePuntuacion]="true"
                    [disableChangeContestableTipo]="hasInstancias"
                    [disableChangeNumeroAlternativas]="false || hasInstancias"
                    [disableChangeNumeroPreguntas]="hasInstancias"
                    [pauta]="pautaToEdit"
                    (changeNumeroPreguntas)="updateNumeroPreguntas($event)"
                    (changeData)="updatePauta($event)"
                    [generadorInstrumento]="instrumento.generador_instrumento"
                ></subir-pauta-mixta>
            </cui-tab>

            <cui-tab title="Editar Clasificaciones" ariaLabelledBy="editar clasificaciones">
                <clasificador-recursos
                    [curriculum]="curriculum"
                    [asignaturaId]="instrumento.generador_instrumento.asignatura_plataforma.asignatura_id"
                    [numeroItems]="numeroPreguntasEdit"
                    [items]="preguntas"
                    (changeData)="updateClasificaciones($event)"
                ></clasificador-recursos>
            </cui-tab>
        </cui-tabs>
    </modal-contenido>
    <modal-botones>
        <cui-button (click)="continueModal()">Guardar</cui-button>
        <cui-button (click)="cancelModal()">Cancelar</cui-button>
    </modal-botones>
</generic-modal>

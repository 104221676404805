<ll-titulo>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-titulo>
<ll-tabs>
    <ul class="nav nav-tabs nav-justified">
        <li role="presentation" class="last-child">
            <a data-toggle="tab" href="#videos">Clases Anteriores</a>
        </li>
    </ul>
</ll-tabs>
<div class="clearfix">
    <div class="tab-content">
        <streaming-videos-list
            [tracking]="tracking"
            [enableMaterialRelacionado]="true"
            [tipoStreaming]="tipoStreaming"
        ></streaming-videos-list>
    </div>
</div>

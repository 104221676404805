<div class="country-container">
    <a
        href="https://www.aprendolibre.mx/landing"
        [class.selected]="pais == 'mexico'"
        [class.outside]="pais != 'mexico'"
    >
        <span class="pais mexico"></span>
        <span class="texto">México</span>
    </a>
    <a
        href="https://www.aprendolibre.net/landing"
        [class.selected]="pais == 'colombia'"
        [class.outside]="pais != 'colombia'"
    >
        <span class="pais colombia"></span>
        <span class="texto">Colombia</span>
    </a>
    <a
        href="https://alumnos.aprendolibre.cl/landing"
        [class.selected]="pais == 'chile'"
        [class.outside]="pais != 'chile'"
    >
        <span class="pais chile"></span>
        <span class="texto">Chile</span>
    </a>
</div>

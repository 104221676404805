import { NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { TranslationsModule } from "@puntaje/shared/core"
import { routing } from "./profesores_plan_personales.routing"
import { PlanPersonalesModule } from "@puntaje/puntaje/new-modules/plan-personales"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { PuntajePlanPersonalesModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ProfesoresLayoutsModule,
        PlanPersonalesModule,
        routing,
        EnsayosModule,
        SharedModule,
        NebuModule,
        TranslationsModule,
        PuntajePlanPersonalesModule
    ],
    exports: [],
    declarations: [],
    providers: []
})
export class ProfesoresPlanPersonalesModule {}

<ng-container *ngIf="!saved">
    <form
        autocomplete="off"
        novalidate
        [formGroup]="form"
        easyScroll
        [scrollOnVariableChange]="currentStep"
        [easyScrollPadding]="-240"
    >
        <ng-container *ngIf="currentStep == 0">
            <form-input
                [customError]="false && form.controls.rut.errors?.duplicateRut?.valid == false"
                [disabled]="form.controls.serial.value"
                [form]="form"
                [params]="params['rut']"
                [(value)]="usuarioRegistro['rut']"
                [key]="'rut'"
            >
                <!-- <span class="custom-error">
                    Si el rut te pertenece, haz clic
                    <a href="javascript:void(0)" (click)="validarSerialCarnet()">acá</a>
                    para validarlo y recuperar tu cuenta.
                </span> -->
            </form-input>

            <form-input
                [form]="form"
                [params]="params['nombre']"
                [(value)]="usuarioRegistro['nombre']"
                [key]="'nombre'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['apellido_paterno']"
                [(value)]="usuarioRegistro['apellido_paterno']"
                [key]="'apellido_paterno'"
            ></form-input>
        </ng-container>
        <ng-container *ngIf="currentStep == 1">
            <form-input
                [form]="form"
                [params]="params['telefono']"
                [(value)]="usuarioRegistro['telefono']"
                [key]="'telefono'"
                [withLabel]="false"
            >
                <label for="telefono" [attr.for]="'telefono'">
                    Teléfono celular
                    <fa
                        [name]="'question-circle-o'"
                        [tooltip]="
                            'Te recomendamos agregar tu celular para que puedas activar o recuperar tu cuenta fácilmente a través de SMS.'
                        "
                    ></fa>
                </label>
            </form-input>
            <form-input
                [form]="form"
                [params]="params['email']"
                [(value)]="usuarioRegistro['email']"
                [key]="'email'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['password']"
                [enablePasswordBar]="true"
                [(value)]="usuarioRegistro['password']"
                [key]="'password'"
            ></form-input>
            <form-input
                [form]="form"
                [params]="params['password_confirmation']"
                [(value)]="usuarioRegistro['password_confirmation']"
                [key]="'password_confirmation'"
            ></form-input>
        </ng-container>
        <ng-container *ngIf="currentStep == 2">
            <label [attr.for]="'lugarColegio'">Comuna de tu colegio</label>
            <autocomplete
                [placeholder]="'Escribe y selecciona entre las opciones'"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="lugarColegio"
                [options]="filteredLugarColegio"
                (filterChange)="getLugarColegio($event)"
                (controlValue)="updateLugarColegio($event)"
                class="autocomplete"
            ></autocomplete>

            <label [attr.for]="'colegio'">
                {{ params["colegio"].label }}
                <span class="required-mark"></span>
            </label>
            <autocomplete
                [placeholder]="'Escribe y selecciona entre las opciones'"
                [formControlName]="'colegio'"
                [options]="filteredEstablecimientos"
                (filterChange)="getEstablecimientos($event)"
                (controlValue)="updateColegio($event)"
                showTextFun="toStringWithIdPais"
                class="autocomplete"
                [disabled]="!lugarColegio"
            ></autocomplete>
            <form-input-error
                [form]="form"
                [params]="params['colegio']"
                [value]="usuarioRegistro['colegio']"
                [key]="'colegio'"
            ></form-input-error>

            <form-input
                [form]="form"
                [params]="params['nivel_id']"
                [(value)]="usuarioRegistro['nivel_id']"
                [key]="'nivel_id'"
            ></form-input>

            <form-input
                [form]="form"
                [params]="params['terminos_y_condiciones']"
                [(value)]="usuarioRegistro['terminos_y_condiciones']"
                [key]="'terminos_y_condiciones'"
            ></form-input>
        </ng-container>
        <div class="btn-container">
            <button (click)="previousStep()" type="button" class="btn btn-default previous-btn" *ngIf="currentStep > 0">
                <fa name="arrow-left" class="icono-left"></fa>
                Anterior
            </button>
            <button
                (click)="nextStep()"
                type="button"
                class="btn btn-default next-btn"
                *ngIf="currentStep < steps.length - 1"
            >
                Siguiente
                <fa name="arrow-right" class="icono-right"></fa>
            </button>
            <button
                (click)="save()"
                type="button"
                class="btn btn-default submit-btn"
                *ngIf="currentStep == steps.length - 1"
            >
                Regístrate
                <fa name="check-square-o" class="icono-right"></fa>
            </button>
        </div>
        <a class="link-back" (click)="back()">Volver a la página de Inicio</a>
    </form>
</ng-container>

<ng-container *ngIf="saved">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>

<!-- <ng-container *ngIf="openModal">
    <generic-modal [buttonClicked]="openModal">
        <modal-titulo>
            Advertencia
        </modal-titulo>
        <modal-contenido>
            <p>Te sugerimos agregar tu celular para recuperar tu contraseña con SMS.</p>
        </modal-contenido>
        <modal-botones>
            <button class="btn btn-default" (click)="genericModal.close()">Agregar Celular</button>
            <button class="btn btn-default" (click)="goOmitir()">Omitir</button>
        </modal-botones>
    </generic-modal>
</ng-container> -->

<!-- <div *ngIf="selectEnvioConfirmacion">
    <modal-select-tipo-envio (tipo)="validarTipoEnvio($event)" [telefono]="telefono"></modal-select-tipo-envio>
</div> -->

<!-- <generic-modal #recuperarCuentaModal [buttonClicked]="openModalRecuperar" [id]="'recuperar-cuenta'">
    <modal-titulo>Valida tu Identidad</modal-titulo>
    <modal-contenido>
        <alumno-recuperar-cuenta
            [rut]="usuarioRegistro['rut']"
            (onValidation)="onValidation($event)"
        ></alumno-recuperar-cuenta>
    </modal-contenido>
</generic-modal> -->

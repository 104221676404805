import { Component, OnInit, Input, Directive } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Directive({ selector: "titulo" })
export class AcercaDeAprendolibreTitulo {}

@Directive({ selector: "descripcion" })
export class AcercaDeAprendolibreContenido {}

@Component({
    selector: "acerca-de-aprendolibre",
    templateUrl: "./acerca-de-aprendolibre.component.html",
    styleUrls: ["../landing-aprendolibre/landing-aprendolibre.component.scss"]
})
export class AcercaDeAprendolibreComponent implements OnInit {
    constructor() {}
    config: typeof config
    @Input() pais: string = ""

    ngOnInit() {
        this.config = config
    }
}

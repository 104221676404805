<footer>
    <div class="top-footer"></div>
    <div class="footer-wrap clearfix">
        <div class="row">
            <div class="col-md-4 col-sm-6 footer-col">
                <h3>Sobre este sitio</h3>
                <ul>
                    <li>
                        <a routerLink="/acerca_de">Acerca de Aprendo Libre</a>
                    </li>
                    <li>
                        <a routerLink="/preguntas_frecuentes">Centro de ayuda</a>
                    </li>
                    <li>
                        <a routerLink="/prensa">Prensa</a>
                    </li>
                    <li>
                        <a routerLink="/condicionesUso">Términos y condiciones</a>
                    </li>
                </ul>
            </div>

            <div class="col-md-4 col-sm-6 footer-col">
                <h3 *ngIf="config.app.name === 'alumnos'; else tituloDocentes">Contáctanos</h3>
                <ng-template #tituloDocentes>
                    <h3>Si eres docente y tienes dudas, contáctanos:</h3>
                </ng-template>
                <ul>
                    <li *ngIf="!hidePhones">
                        <p>Llámanos o escríbenos por Whatsapp:</p>
                        <span class="info" *ngFor="let phone of supportPhones">
                            <a href="tel:{{ phone }}">
                                <fa name="phone" class="icon-phone"></fa>
                            </a>
                            <a href="https://wa.me/{{ trimSpaces(phone) }}" target="_blank">
                                <fa name="whatsapp" class=""></fa>
                            </a>
                            {{ phone }}
                            <br />
                        </span>
                    </li>
                    <li>
                        <p>E-mail de monitores para ayuda a {{ profileName }}:</p>
                        <fa name="envelope" class="icon-phone"></fa>
                        <a href="mailto:{{ supportMail }}">
                            {{ supportMail }}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="col-md-4 col-sm-6 footer-col">
                <h3>Encuéntranos en redes sociales</h3>
                <div class="socialMedia">
                    <a
                        href="{{ config.plataforma.info.social.facebook }}"
                        class="facebook"
                        target="_blank"
                        title="Facebook"
                    >
                        <fa name="facebook"></fa>
                    </a>
                    <a
                        href="{{ config.plataforma.info.social.instagram }}"
                        class="instagram"
                        target="_blank"
                        title="Instagram"
                    >
                        <fa name="instagram"></fa>
                    </a>
                    <a
                        href="{{ config.plataforma.info.social.youtube }}"
                        class="youtube"
                        target="_blank"
                        title="Youtube"
                    >
                        <fa name="youtube"></fa>
                    </a>
                    <a href="{{ config.plataforma.info.social.tiktok }}" class="tiktok" target="_blank" title="TikTok">
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="tiktok"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            class="svg-inline--fa fa-tiktok fa-w-14"
                        >
                            <path
                                fill="currentColor"
                                d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                                class=""
                            ></path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="bottom-footer">
        <div class="logo-ogr">
            <div>Desarrollado por</div>
            <a class="footer-logo" href="http://ogr.cl/">
                <img
                    class="footer-logo"
                    src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/logo_ogr_light.png"
                    alt="PuntajeNacional.cl"
                />
            </a>
        </div>

        <a class="footer-logo" routerLink="/landing">
            <img
                src="{{ config.app.assets.logoLargeLight }}"
                alt="{{ config.plataforma.info.companyName }}"
                title="{{ config.plataforma.info.companyName }}"
            />
        </a>
    </div>
</footer>

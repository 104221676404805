<generic-modal [id]="modalId" [modalLarge]="true" (closeCrossCallback)="closeCross()">
    <modal-titulo>Vista Previa Evaluación #{{ evaluacionInstancia?.evaluacion_id }}</modal-titulo>
    <modal-contenido>
        <loading-layout #loadingLayout>
            <respuestas-ensayo
                [evaluacionInstanciaId]="evaluacionInstanciaId"
                (onEvaluacionInstanciaReady)="onEvaluacionInstanciaReady($event)"
                [enableDudas]="false"
                [enableReporte]="false"
                [resultadosAlumno]="true"
                [enableDesempeno]="false"
            ></respuestas-ensayo>
        </loading-layout>
    </modal-contenido>
</generic-modal>

import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

import { TokenGuard } from "@puntaje/puntaje/services"
import { InstrumentoComponent, PruebasPropiasComponent, SubirPruebaPropiaComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "instrumentos/subir_prueba_propia",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: SubirPruebaPropiaComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "instrumentos/pruebas_propias",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PruebasPropiasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "instrumentos/:id",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: InstrumentoComponent, canActivate: [AuthGuard], data: { showButtonGuardar: true } }
        ]
    }
]

export const routing = RouterModule.forChild(routes)

<div class="mascara" id="topPage">
    <div class="top-wrap">
        <countrys [pais]="pais"></countrys>
        <div class="login-placement">
            <login-cb
                [urlRegister]="urlRegistro"
                [enableContactUs]="false"
                [enableRegister]="true"
                [enablePasswordRecovery]="true"
            ></login-cb>
        </div>
    </div>
    <section id="section1" class="caja">
        <div class="caja-inner-margin">
            <div class="logo-landing">
                <div class="logo-wrap">
                    <img
                        class="logo"
                        src="{{ config.app.assets.logoLarge }}"
                        alt="{{ config.plataforma.info.companyName }}"
                        title="{{ config.plataforma.info.companyName }}"
                    />
                    <span class="pull-right beta" *ngIf="isBeta">Beta</span>
                </div>
            </div>
            <h1 class="titulo principal">
                <em>Apoyando</em>
                &nbsp;a comunidades educativas a lo largo del mundo.
            </h1>
            <div class="landing-top-image-wrap" anim-on-scroll-fade-in>
                <img
                    class="landing-top-image"
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/landing_top.png"
                    alt="{{ config.plataforma.info.companyName }}"
                    title="{{ config.plataforma.info.companyName }}"
                />
                <img
                    class="landing-top-image-responsive"
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/landing_top_mobile.png"
                    alt="{{ config.plataforma.info.companyName }}"
                    title="{{ config.plataforma.info.companyName }}"
                />
            </div>
        </div>
    </section>
    <section id="section2" class="caja">
        <div class="caja-inner-margin">
            <h1 class="titulo">¿Por qué elegir Aprendo Libre?</h1>
            <div class="row">
                <div class="col-md-4">
                    <div class="info-item" anim-on-scroll-enter-from-top>
                        <h2>Apoyo para estudiantes</h2>
                        <p>
                            Amplía tus conocimientos con nuestra biblioteca con miles de recursos de apoyo a tus clases.
                            2
                        </p>
                        <img
                            class="landing-top-image"
                            src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/info1.png"
                            alt="{{ config.plataforma.info.companyName }}"
                            title="{{ config.plataforma.info.companyName }}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="info-item" anim-on-scroll-enter-from-top>
                        <h2>Herramientas para {{ nombreDocentePlural }}</h2>
                        <p>
                            Guía a tus estudiantes de mejor manera con nuestra herramientas de evaluación
                            personalizables y estadísticas agregadas.
                        </p>
                        <img
                            class="landing-top-image"
                            src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/info2.png"
                            alt="{{ config.plataforma.info.companyName }}"
                            title="{{ config.plataforma.info.companyName }}"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="info-item" anim-on-scroll-enter-from-top>
                        <h2>Material verificado</h2>
                        <p>
                            Todas las preguntas, guías, videos, entre otros han sido verificados por nuestro equipo
                            docente de vasta experiencia.
                        </p>
                        <img
                            class="landing-top-image"
                            src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/info3.png"
                            alt="{{ config.plataforma.info.companyName }}"
                            title="{{ config.plataforma.info.companyName }}"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="section3" class="caja">
        <div class="caja-inner-margin">
            <h1 class="titulo">¡Únete a la comunidad de apoyo escolar y accede a miles de recursos!</h1>
            <div class="register-container">
                <div class="register-item" anim-on-scroll-enter-from-left>
                    <cui-button-link class="btn-link btn_style" routerLink="/usuarios/registro">
                        Estudiantes
                    </cui-button-link>
                    <img
                        class="landing-top-image estudiantes"
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/estudiantes.png"
                        alt="Estudiantes"
                        title="Estudiantes"
                    />
                </div>
                <div class="register-item" anim-on-scroll-enter-from-right>
                    <cui-button-link class="btn_style" (click)="modalContactoProfesores.buttonPressed()">
                        {{ nombreDocentePlural | capitalize }}
                    </cui-button-link>
                    <img
                        class="landing-top-image"
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/maestros.png"
                        alt="{{ nombreDocentePlural | capitalize }}"
                        title="{{ nombreDocentePlural | capitalize }}"
                    />
                </div>
            </div>
        </div>
    </section>
    <section id="section4" class="caja">
        <div class="caja-inner-margin" *ngIf="pais == 'chile'">
            <h1 class="titulo">
                ¿Necesitas prepararte para la Prueba de Acceso a la Educación Superior y Prueba de Invierno?
            </h1>
            <div class="logo-wrap">
                <img
                    class="logo"
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/logos/logoLarge.png"
                    alt="Puntaje Nacional"
                    title="Puntaje Nacional"
                />
            </div>
            <div class="btn-container">
                <cui-button-link href="https://www.puntajenacional.cl/" class="btn-link btn_style">
                    Accede aquí
                </cui-button-link>
            </div>
        </div>
        <div class="caja-inner-margin" *ngIf="pais == 'mexico'">
            <h1 class="titulo">¿Necesitas prepararte para la PAA?</h1>
            <div class="logo-wrap">
                <img
                    class="logo"
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/logoPAA.png"
                    alt="Práctica Oficial para la PAA"
                    title="Práctica Oficial para la PAA"
                />
            </div>
            <div class="btn-container">
                <cui-button-link href="https://paa.aprendolibre.com/" class="btn-link btn_style">
                    Accede a la Práctica Oficial aquí
                </cui-button-link>
            </div>
        </div>
        <div class="caja-inner-margin" *ngIf="pais == 'colombia'">
            <h1 class="titulo">¿Necesitas prepararte para el Saber 11?</h1>
            <div class="logo-wrap">
                <img
                    class="logo"
                    src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/logo-pnco.png"
                    alt="Práctica Oficial para el Saber 11"
                    title="Práctica Oficial para el Saber 11"
                />
            </div>
            <div class="btn-container">
                <cui-button-link href="https://www.puntajenacional.co/" class="btn-link btn_style">
                    Regístrate gratis
                    <b>aquí</b>
                </cui-button-link>
            </div>
        </div>
    </section>
    <section id="section5" class="caja">
        <div class="caja-inner-margin" *ngIf="pais != 'chile'">
            <h1 class="titulo">¿Eres estudiante de primaria o secundaria?</h1>
            <p class="subtitulo">
                Aprende de manera gratuita lo que quieras sobre matemáticas, español y ciencias a través de nuestros:
            </p>
            <p class="materiales">
                <span>Planes de estudios</span>
                <span class="separador"></span>
                <span>Clases online</span>
                <span class="separador"></span>
                <span>Cápsulas educativas</span>
                <span class="separador"></span>
                <span>Practica</span>
            </p>
        </div>
        <div class="caja-inner-margin" *ngIf="pais == 'chile'">
            <h1 class="titulo">¿Eres estudiante de básica?</h1>
            <p class="subtitulo">
                Aprende de manera gratuita lo que quieras sobre matemática, lenguaje, historia y ciencias:
            </p>
            <p class="materiales">
                <span>Planes de clases</span>
                <span class="separador"></span>
                <span>Clases online</span>
                <span class="separador"></span>
                <span>Cápsulas educativas</span>
                <span class="separador"></span>
                <span>Juegos interactivos</span>
            </p>
        </div>
    </section>
    <section id="section6" class="caja">
        <div class="caja-inner-margin">
            <h1 class="titulo">¿Eres {{ nombreDocente }}?</h1>
            <p class="subtitulo">Apoyamos tu labor con herramientas que te ayudan a enseñar de manera más eficiente</p>
            <div class="info-layout">
                <div class="info-item" anim-on-scroll-enter-from-top>
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/herramientas1.png"
                        alt="Planes de clases"
                        title="Planes de clases"
                    />
                    <p>Planes de clases</p>
                </div>
                <div class="info-item" anim-on-scroll-enter-from-top>
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/herramientas2.png"
                        alt="Biblioteca con material audiovisual descargable"
                        title="Biblioteca con material audiovisual descargable"
                    />
                    <p>Biblioteca con material audiovisual descargable</p>
                </div>
                <div class="info-item" anim-on-scroll-enter-from-top>
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/herramientas3.png"
                        alt="Evaluaciones"
                        title="Evaluaciones"
                    />
                    <p>Evaluaciones</p>
                </div>
                <div class="info-item" anim-on-scroll-enter-from-top>
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/herramientas4.png"
                        alt="Estadísticas"
                        title="Estadísticas"
                    />
                    <p>Estadísticas</p>
                </div>
                <div class="info-item" anim-on-scroll-enter-from-top *ngIf="pais != 'chile'">
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre_mexico/landing/herramientas5.png"
                        alt="Clases y contenido propio"
                        title="Clases y contenido propio"
                    />
                    <p>Clases y contenido propio</p>
                </div>
                <div class="info-item" anim-on-scroll-enter-from-top *ngIf="pais == 'chile'">
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre/landing/simce.png"
                        alt="Apoyo preparación SIMCE"
                        title="Apoyo preparación SIMCE"
                    />
                    <p>Apoyo preparación SIMCE</p>
                </div>
                <div class="info-item" anim-on-scroll-enter-from-top *ngIf="pais == 'chile'">
                    <img
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/aprendolibre/landing/mineduc.png"
                        alt="Contenidos alineados planes del Mineduc"
                        title="Contenidos alineados planes del Mineduc"
                    />
                    <p>Contenidos alineados planes del Mineduc</p>
                </div>
            </div>
        </div>
    </section>
    <section id="section7" class="caja">
        <div class="caja-inner-margin">
            <h1 class="titulo">Únete a</h1>
            <div class="logo-wrap">
                <img
                    class="logo"
                    src="{{ config.app.assets.logoLarge }}"
                    alt="{{ config.plataforma.info.companyName }}"
                    title="{{ config.plataforma.info.companyName }}"
                />
            </div>
            <div class="btn-container">
                <cui-button-link class="btn-link btn_style" routerLink="/usuarios/registro">
                    Estudiantes
                </cui-button-link>
                <cui-button-link class="btn_style" (click)="modalContactoProfesores.buttonPressed()">
                    {{ nombreDocentePlural | capitalize }}
                </cui-button-link>
            </div>
        </div>
    </section>
    <section id="section8" class="caja">
        <div class="caja seccion_blog" id="caja_blog" *ngIf="pais == 'chile'">
            <div class="caja-inner-margin">
                <h2 class="sliding-title">
                    <span>
                        Blog
                        <ogr-icon name="megafono-o" class="icono"></ogr-icon>
                    </span>
                </h2>
                <div class="inner-wrap">
                    <blog-landing [postPerfiles]="postPerfiles"></blog-landing>
                </div>
            </div>
        </div>
    </section>
</div>
<footer-aprendolibre *ngIf="pais != 'mexico'" [pais]="pais"></footer-aprendolibre>
<footer-aprendolibre-mexico *ngIf="pais == 'mexico'"></footer-aprendolibre-mexico>
<generic-modal #modalContactoProfesores>
    <modal-titulo>Contacto</modal-titulo>
    <modal-contenido>
        <p>
            Los establecimientos educacionales que contratan los servicios de Aprendo Libre tienen acceso a nuestra
            plataforma de manera ilimitada.
        </p>
        <p>
            Permitiendo a los maestros evaluar, corregir, enviar materiales a sus alumnos y más con tan solo un par de
            clics.
        </p>
        <form [formGroup]="contactoForm">
            <p class="alert alert-success" *ngIf="submitted">
                Su información se ha enviado con éxito. Nos comunicaremos con usted a la brevedad.
            </p>
            <div
                class="form-group"
                [ngClass]="{
                    'has-success': contactoNombre.valid && contactoNombre.touched,
                    'has-error': contactoNombre.invalid && contactoNombre.touched
                }"
            >
                <label>Nombre *</label>
                <input formControlName="nombre" class="form-control" type="text" />
            </div>
            <div
                class="form-group"
                [ngClass]="{
                    'has-success': contactoApellidos.valid && contactoApellidos.touched,
                    'has-error': contactoApellidos.invalid && contactoApellidos.touched
                }"
            >
                <label>Apellidos *</label>
                <input formControlName="apellidos" class="form-control" type="text" />
            </div>
            <div
                class="form-group"
                [ngClass]="{
                    'has-success': contactoEmail.valid && contactoEmail.touched,
                    'has-error': contactoEmail.invalid && contactoEmail.touched
                }"
            >
                <label>Correo electrónico *</label>
                <input formControlName="email" class="form-control" type="text" />
            </div>
            <div
                class="form-group"
                [ngClass]="{
                    'has-success': contactoTelefono.valid && contactoTelefono.touched,
                    'has-error': contactoTelefono.invalid && contactoTelefono.touched
                }"
            >
                <label>Teléfono *</label>
                <input formControlName="telefono" class="form-control" type="text" />
            </div>
            <div
                class="form-group"
                [ngClass]="{
                    'has-success': contactoEstablecimiento.valid && contactoEstablecimiento.touched,
                    'has-error': contactoEstablecimiento.invalid && contactoEstablecimiento.touched
                }"
            >
                <label>Establecimiento educacional</label>
                <input formControlName="establecimiento" class="form-control" type="text" />
            </div>
        </form>
    </modal-contenido>
    <modal-botones>
        <cui-button (click)="sendContacto()" [disabled]="!contactoForm.valid" class="btn_style">Enviar</cui-button>
    </modal-botones>
</generic-modal>

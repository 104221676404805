<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container"><ogr-icon name="todos" class="icon"></ogr-icon></div>
            </div>
            <div class="showcasebox_title_text">¿Qué puedes hacer?</div>
        </div>
    </div>
    <div>
        <div class="content {{ item.clase }}" *ngFor="let item of menu">
            <div class="content_img {{ item.clase }}">
                <ng-container *ngIf="item.icon; else sinIcono">
                    <ogr-icon class="icono" name="{{ item.icon }}" align="top"></ogr-icon>
                </ng-container>
                <ng-template #sinIcono>
                    <img class="img" [src]="item.url" />
                </ng-template>
            </div>
            <div class="contentPalabra" (click)="irA(item.link, item.params)">
                {{ item.descripcion }}
            </div>
        </div>
    </div>
</div>

import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { HomeSimceComponent } from "./home_simce.component"
import { HomePruebasComponent } from "./home_pruebas.component"
import {
    EnsayoInstanciasComponent,
    EstadisticasComponent,
    EvaluacionComponent,
    EvaluacionesCorregirComponent,
    GenerarEnsayoProfesorComponent,
    LibroNotasComponent,
    ProgresoComponent,
    RealizarEnsayoComponent,
    ResultadosEnsayoAlumnoComponent,
    ResultadosEnsayoComponent,
    RevisarEnsayosComponent
} from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "ensayo_instancias",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayos/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [AuthGuard],
                data: {
                    enableBuscador: true,
                    enableReporte: true,
                    showTutorial: true,
                    showButtonGuardar: true,
                    showCompartirMasTarde: true
                }
            }
        ]
    },
    {
        path: "ensayos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [AuthGuard] }]
    },

    // TODO: linkear las cosas a evaluaciones en vez de a ensayos
    {
        path: "evaluaciones/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [AuthGuard],
                data: {
                    enableBuscador: true,
                    enableReporte: true,
                    showTutorial: true,
                    showButtonGuardar: true,
                    showCompartirMasTarde: true
                }
            }
        ]
    },
    {
        path: "evaluaciones/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/subir",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCorregirComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluaciones/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayo_instancias_estudiante/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoAlumnoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluaciones",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluaciones/resultados/historial",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "simce/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [AuthGuard],
                data: {
                    enableBuscador: true,
                    enableReporte: true,
                    showTutorial: true,
                    showButtonGuardar: true,
                    showCompartirMasTarde: true
                }
            }
        ]
    },
    {
        path: "simce/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "simce/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "simce/progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "simce/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "simce/libro",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: LibroNotasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "simce",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeSimceComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "pruebas/generar_prueba/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [AuthGuard],
                data: {
                    enableBuscador: true,
                    enableReporte: true,
                    showTutorial: true,
                    enableSiteWarning: false
                }
            }
        ]
    },
    {
        path: "pruebas/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "pruebas/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "pruebas/progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoComponent, canActivate: [AuthGuard] }]
    },
    /* {
        path: "pruebas/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [AuthGuard] }]
    }, */
    {
        path: "pruebas/libro",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: LibroNotasComponent, canActivate: [AuthGuard], data: { disableReforzamiento: true } }
        ]
    },
    {
        path: "pruebas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePruebasComponent, canActivate: [AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)

import { Component, OnInit, Input } from "@angular/core"
import {
    GrupoUsuario,
    GrupoUsuarios,
    GrupoUsuarioUsuarios,
    Usuario,
    Usuarios,
    Establecimiento,
    Establecimientos,
    EstablecimientoUsuarios,
    EstablecimientoMonitor,
    EstablecimientoMonitores
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    templateUrl: "ayuda_info.component.html",
    selector: "ayuda-info",
    styleUrls: ["ayuda_info.component.scss"]
})
export class AyudaInfoComponent implements OnInit {
    grupoUsuario: GrupoUsuario
    usuarios: Usuario[]
    establecimientos: Establecimiento[]
    establecimiento: Establecimiento
    establecimientoMonitores: EstablecimientoMonitor[]
    pais: string
    mostrarEstablacimiento = []
    monitores = []

    constructor(
        protected usuariosService: Usuarios,
        protected grupoUsuariosService: GrupoUsuarios,
        protected establecimientosService: Establecimientos,
        protected establecimientoUsuariosService: EstablecimientoUsuarios,
        protected establecimientoMonitoresService: EstablecimientoMonitores,
        protected auth: AuthService
    ) {}

    ngOnInit() {
        this.pais = config.plataforma.pais
        this.setData()
    }

    setData() {
        this.establecimientosService
            .where({
                include: "[asociaciones,monitores:[usuario_" + this.pais + "]]",
                establecimiento: { id: this.auth.getEstablecimientos(), activo: 1 }
            })
            .then((establecimientos: Establecimiento[]) => {
                this.establecimientos = establecimientos
            })
    }
}

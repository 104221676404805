import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "preguntas-frecuentes",
    templateUrl: "./preguntas-frecuentes.component.html",
    styleUrls: ["./preguntas-frecuentes.component.scss"]
})
export class PreguntasFrecuentesComponent implements OnInit {
    @Input() seccionesPreguntas: any[]

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor() {}

    ngOnInit() {
        this.loadingLayout.ready()
    }
}

<div class="showcasebox" [class.smallSize]="enableSmall" #wrapper (window:resize)="checkSize()">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container">
                    <ogr-icon name="libro" class="icon"></ogr-icon>
                </div>
            </div>
            <div class="showcasebox_title_text">Materiales</div>
        </div>
    </div>
    <div class="showcasebox_content">
        <p class="info_text">Encuentra guías y contenido educativo para diferentes asignaturas y cursos.</p>
        <div class="tabla-margin">
            <div class="tabla-asignaturas">
                <div class="tabla-asignaturas-row" *ngFor="let row of lista_asignaturas_by_row">
                    <div class="tabla-asignaturas-cell" *ngFor="let a of row">
                        <a
                            class="btn-{{ a.icono }}"
                            [routerLink]="['/materiales']"
                            [queryParams]="{ asignatura_id: a.id }"
                        >
                            <ogr-icon name="{{ a.icono }}" class="icon" align="text-top"></ogr-icon>
                            {{ a.abreviacion }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<noticias-aviso-home></noticias-aviso-home>
<div class="row">
    <div class="col-md-4 col-sm-6">
        <usuario-showcasebox></usuario-showcasebox>
        <br />
        <noticias-showcasebox [defaultNotFound]="false"></noticias-showcasebox>
        <br />
        <grupo-usuarios-profesor-showcase></grupo-usuarios-profesor-showcase>
        <br />
        <lista-establecimientos-profesor></lista-establecimientos-profesor>
        <br />
    </div>
    <div class="col-md-4 col-sm-6">
        <menu-showcase [menuCustom]="menu"></menu-showcase>
    </div>
    <div class="col-md-4 col-sm-6">
        <blog-showcasebox-profesor></blog-showcasebox-profesor>
        <br />
        <materiales-showcasebox evaluacionTipo="curricular"></materiales-showcasebox>
    </div>
</div>

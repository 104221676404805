import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { HomeEstablecimientosComponent } from "./home_establecimientos.component"
import { EstablecimientosAdministrarComponent } from "@puntaje/puntaje/core"

export const establecimientosRoutes: Routes = [
    {
        path: "establecimientos/administrar",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EstablecimientosAdministrarComponent,
                canActivate: [AuthGuard],
                data: {
                    showVaciar: true
                }
            }
        ]
    },
    {
        path: "establecimientos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeEstablecimientosComponent, canActivate: [AuthGuard] }]
    }
]

export const establecimientosRouting = RouterModule.forChild(establecimientosRoutes)

import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    templateUrl: "home_biblioteca.component.html"
})
export class HomeBibliotecaComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []

    constructor(protected store: Store<State>, protected loggedLayoutService: LoggedLayoutService) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()
        this.menuItems = [
            /*{
			route: '/materiales/',
			params: {tipo_instrumento: 'curricular', curso_id: 1, asignatura_id: this.asignaturasByEvaluacionTipo['curricular'][0].id, clasificaciones: ['Docente']},
			label: "Material Docente Curricular",
			text: "Encuentra guías y videos con material curricular. Organizados por asignatura, curso y eje temático.",
			linkText: "Ver material curricular",
			icon: "libro2-o"
		},*/
            {
                route: "/materiales/",
                params: {
                    tipo_instrumento: "curricular",
                    curso_id: 1,
                    asignatura_id: asignaturasByEvaluacionTipo["curricular"][0].id /*, clasificaciones: ['Alumno']*/
                },
                label: "Material Curricular",
                text: "Encuentra guías y videos con material curricular. Organizados por asignatura, curso y eje temático.",
                linkText: "Ver material curricular",
                icon: "libro2-o"
            },
            /*{
			route: '/materiales/',
			params: {tipo_instrumento: 'curricular', curso_id: 1, asignatura_id: this.asignaturasByEvaluacionTipo['curricular PIE'][0].id, clasificaciones: ['Programa de Integración Escolar']},
			label: "Material curricular PIE",
			text: "Encuentra guías y videos con material curricular PIE.",
			linkText: "Ver material curricular",
			icon: "libro2-o"
		},*/
            {
                route: "/recursos_interactivos/",
                params: {},
                label: "Recursos interactivos",
                text: "Encuentra juegos, recursos y actividades educativas.",
                linkText: "Ver recursos interactivos",
                icon: "amor-y-paz"
            }
            // {
            //     route: "/instrumentos/pruebas_propias/",
            //     params: {
            //         tipo_instrumento: "curricular",
            //         asignatura_id: +asignaturasByEvaluacionTipo["curricular"][0].id
            //     },
            //     label: "Mis instrumentos curriculares",
            //     text: "Lista de tus pruebas propias curriculares que has subido al sistema.",
            //     linkText: "Ver instrumentos",
            //     icon: "pdf"
            // }
            // {
            //     route: "/streaming/curricular",
            //     params: { tipo: "curricular" },
            //     label: "Clases en Vivo",
            //     text: "Mira las transmisiones en vivo y videos de clases curriculares.",
            //     linkText: "Ver streaming curricular",
            //     icon: "camara-video-o"
            // }
        ]
    }
}

import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { RouterModule } from "@angular/router"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
import { Angulartics2Module } from "angulartics2"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { ModalModule } from "ngx-bootstrap/modal"
import { PopoverModule } from "ngx-bootstrap/popover"
import { ProgressbarModule } from "ngx-bootstrap/progressbar"
import { TabsModule } from "ngx-bootstrap/tabs"
import { TooltipModule } from "ngx-bootstrap/tooltip"
import { FormModule, ModelViewModule, PaginatorModule, TranslationsModule, UtilModule } from "@puntaje/shared/core"
import { ImprimirModule } from "@puntaje/puntaje/new-modules/imprimir"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { CUIInputsModule, CUITextsModule, CUIContainersModule } from "@puntaje/shared/cui"

import { AbstractGenerarEnsayoComponent } from "./abstract_generar_ensayo.component"
import { AdminEvaluacionesMasivasComponent } from "./admin_evaluaciones_masivas.component"
import { CompartirReforzamientosComponent } from "./compartir_reforzamientos.component"
import { CompartirResultadosEvaluacionComponent } from "./compartir_resultados_evaluacion.component"
import { CorregirEnsayoCamaraComponent } from "./corregir-ensayo-camara/corregir-ensayo-camara.component"
import { CorregirEnsayosTabsContenidoProfesorComponent } from "./corregir-ensayos-tabs-contenido-profesor/corregir-ensayos-tabs-contenido-profesor.component"
import { CorregirEnsayosTabsProfesorComponent } from "./corregir-ensayos-tabs-profesor/corregir-ensayos-tabs-profesor.component"
import { DatosGeneralesEvaluacionComponent } from "./datos_generales_evaluacion.component"
import { DescargarCsvPrellenadoComponent } from "./descargar-csv-prellenado/descargar-csv-prellenado.component"
import { EditarEvaluacionComponent } from "./editar_evaluacion.component"
import { EliminarResultadosPorAlumnoComponent } from "./eliminar-resultados-evaluacion/eliminar-resultados-por-alumno.component"
import { EliminarEvaluacionComponent } from "./eliminar_evaluacion.component"
import { EnsayosListaLinksComponent } from "./ensayos-lista-links/ensayos-lista-links.component"
import { EnsayosMultiplesRecomendadosComponent } from "./ensayos-multiples-recomendados/ensayos-multiples-recomendados.component"
import { EnsayosBrevesComponent } from "./ensayos_breves.component"
import { EnsayosRecomendadosComponent } from "./ensayos_recomendados.component"
import { EnsayoInstanciasComponent } from "./ensayo_instancias.component"
import { EnsayoInstancias2Component } from "./ensayo_instancias2.component"
import { EnsayoInstanciaResumenComponent } from "./ensayo_instancia_resumen.component"
import { EnsayoInstanciaResumenAlumnoComponent } from "./ensayo_instancia_resumen_alumno.component"
import { EvaluacionInstanciaMultipleDetalleClasificacionesComponent } from "./evaluacion-instancia-multiple-detalle-clasificaciones/evaluacion-instancia-multiple-detalle-clasificaciones.component"

import { EvaluacionInstanciaMultipleResumenComponent } from "./evaluacion-instancia-multiple-resumen/evaluacion-instancia-multiple-resumen.component"
import { EvaluacionRecomendadaAlumnoCardComponent } from "./evaluacion-recomendada-alumno-card/evaluacion-recomendada-alumno-card.component"
import { EvaluacionRecomendadaEditComponent } from "./evaluacion-recomendada-edit/evaluacion-recomendada-edit.component"
import { EvaluacionRecomendadaNewComponent } from "./evaluacion-recomendada-new/evaluacion-recomendada.new.component"
import { EvaluacionesMultiplesCompartidasAlumnoComponent } from "./evaluaciones-multiples-compartidas-alumno/evaluaciones_multiples_compartidas_alumno.component"
import { EvaluacionesMultiplesCompartidasComponent } from "./evaluaciones-multiples-compartidas/evaluaciones_multiples_compartidas.component"
import { EvaluacionesMultiplesPresencialesComponent } from "./evaluaciones-multiples-presenciales/evaluaciones_multiples_presenciales.component"
import { EvaluacionesPendientesAsignaturaBoxComponent } from "./evaluaciones-pendientes-asignatura-box/evaluaciones-pendientes-asignatura-box.component"
import { EvaluacionesPendientesAsignaturasComponent } from "./evaluaciones-pendientes-asignaturas/evaluaciones-pendientes-asignaturas.component"
import { EvaluacionesRecomendadasAlumnoComponent } from "./evaluaciones-recomendadas-alumno/evaluaciones-recomendadas-alumno.component"
import { EvaluacionRecomendadasToolNavbarComponent } from "./evaluaciones-recomendadas-list/evaluacion-recomendadas.toolnavbar.component"
import { EvaluacionesRecomendadasListComponent } from "./evaluaciones-recomendadas-list/evaluaciones-recomendadas-list.component"
import { EvaluacionesCBShowcaseBoxComponent } from "./evaluaciones_cb_showcasebox.component"
import { EvaluacionesCompartidasComponent } from "./evaluaciones_compartidas.component"
import { EvaluacionesCompartidasAlumnoComponent } from "./evaluaciones_compartidas_alumno.component"
import { EvaluacionesInstanciasCBShowcaseBoxComponent } from "./evaluaciones_instancias_cb_showcasebox.component"
import { EvaluacionesPendientesShowcaseComponent } from "./evaluaciones_pendientes_showcase.component"
import { EvaluacionesPresencialesComponent } from "./evaluaciones_presenciales.component"
import { EvaluacionesTECShowcaseComponent } from "./evaluaciones_tec_showcase.component"
import { EvaluacionInteractivaComponent } from "./evaluacion_interactiva.component"
import { EvaluacionInteractivaStatusComponent } from "./evaluacion_interactiva_status.component"
import { EvaluacionInteractivaStatusService } from "./evaluacion_interactiva_status.service"
import { EvaluacionMenuPreguntasComponent } from "./evaluacion_menu_preguntas.component"
import { EvaluacionPortadaComponent } from "./evaluacion_portada.component"
import { EvaluacionTiempoComponent } from "./evaluacion_tiempo.component"
import { GenerarEnsayoMultipleProfesorComponent } from "./generar-ensayo-multiple-profesor/generar-ensayo-multiple-profesor.component"
import { GenerarEnsayoComponent } from "./generar_ensayo.component"
import { GenerarEnsayoAlumnoComponent } from "./generar_ensayo_alumno.component"
import { GenerarEnsayoAdaptiveComponent } from "./generar_ensayo_adaptive.component"
import { GenerarEnsayoProfesorComponent } from "./generar_ensayo_profesor.component"
import { GenerarEnsayoProfesor2Component } from "./generar_ensayo_profesor2.component"
import { GenerarEnsayoProfesorDynamicTabsComponent } from "./generar_ensayo_profesor_dynamic_tabs.component"
import { GenerarEvaluacionAlumnoGraduateComponent } from "./generar_evaluacion_alumno_graduate.component"
import { GenerarInstrumentoAdminComponent } from "./generar_instrumento_admin.component"
import { InprimirEnsayoExternoComponent } from "./imprimir_ensayo_externo.component"
import { LibroNotasComponent } from "./libro-notas/libro-notas.component"
import { ListaEvaluacionesPreHechasComponent } from "./lista_evaluaciones_pre_hechas.component"
import { ListaInstrumentosPreHechosComponent } from "./lista_instrumentos_pre_hechos.component"
import { MenuAsignaturasDropdownComponent } from "./menu_asignaturas_dropdown.component"
import { ModalImprimirEvaluacionAlumnosComponent } from "./modal-imprimir-evaluacion-alumnos/modal-imprimir-evaluacion-alumnos.component"
import { EvaluacionPreviewModalComponent } from "./modals/evaluacion_preview_modal.component"
import { PreguntasEnsayoComponent } from "./preguntas_ensayo.component"
import { PreguntaEnsayoComponent } from "./pregunta_ensayo.component"
import { ProgresoPreguntasEvaluacionSecuencialComponent } from "./progreso-preguntas-evaluacion-secuencial/progreso-preguntas-evaluacion-secuencial.component"
import { RealizarEnsayoService } from "./realizar_ensayo.service"
import { ResponderEvaluacionPropiaComponent } from "./responder-evaluacion-propia/responder-evaluacion-propia.component"
import { RespuestasEnsayoComponent } from "./respuestas_ensayo.component"
import { RespuestaEnsayoComponent } from "./respuesta_ensayo.component"
import { ResultadosEvaluacionComponent } from "./resultados-evaluacion/resultados-evaluacion.component"
import { ResultadosEvaluacionesAsignaturaBoxComponent } from "./resultados-evaluaciones-asignatura-box/resultados-evaluaciones-asignatura-box.component"
import { ResultadosEvaluacionesAsignaturasComponent } from "./resultados-evaluaciones-asignaturas/resultados-evaluaciones-asignaturas.component"
import { SubirRespuestasImagenAlumnoComponent } from "./subir-respuestas-imagen-alumno/subir-respuestas-imagen-alumno.component"
import { SubirRespuestasCSVComponent } from "./subir_respuestas_csv.component"
import { SubirRespuestasImagenComponent } from "./subir_respuestas_imagen.component"
import { SubirRespuestasImagenMultipleComponent } from "./subir_respuestas_imagen_multiple.component"
import { TablaEvaluacionFormaEstudiantesComponent } from "./tabla-evaluacion-forma-estudiantes/tabla-evaluacion-forma-estudiantes.component"
import { VerPreguntaComponent } from "./ver_pregunta.component"
import { ContestableSprComponent } from "./contestable-spr/contestable-spr.component"
import { RespuestaSprComponent } from "./respuesta-spr/respuesta-spr.component"
import { WidgetEvaluacionMenuPreguntasComponent } from "./widget-evaluacion-menu-preguntas/widget-evaluacion-menu-preguntas.component"
import { WidgetEvaluacionTiempoComponent } from "./widget-evaluacion-tiempo/widget-evaluacion-tiempo.component"
import { WidgetResponderEvaluacionPropiaComponent } from "./widget-responder-evaluacion-propia/widget-responder-evaluacion-propia.component"

import { ContestableLibreComponent } from "./contestable-libre/contestable-libre.component"
import { RespuestaLibreComponent } from "./respuesta-libre/respuesta-libre.component"
import { ContestableCompletableComponent } from "./contestable-completable/contestable-completable.component"
import { RespuestaCompletableComponent } from "./respuesta-completable/respuesta-completable.component"
import { ContestableImagenComponent } from "./contestable-imagen/contestable-imagen.component"
import { RespuestaPdfComponent } from "./respuesta-pdf/respuesta-pdf.component"
import { RespuestaImagenComponent } from "./respuesta-imagen/respuesta-imagen.component"
import { ResultadosEnsayosComponent } from "./resultados-ensayos/resultados-ensayos.component"
import { ContestableArchivoComponent } from "./contestable-archivo/contestable-archivo.component"
import { CalificacionComponent } from "./calificacion/calificacion.component"
import { VerRetroalimentacionComponent } from "./ver-retroalimentacion/ver-retroalimentacion.component"
import { RetroalimentacionIndividualComponent } from "./retroalimentacion-individual/retroalimentacion-individual.component"
import { MaterialesModule } from "@puntaje/puntaje/new-modules/materiales"
import { ThumbnailEvaluacionComponent } from "./thumbnail-evaluacion/thumbnail-evaluacion.component"
import { RecalificarEvaluacionComponent } from "./recalificar-evaluacion/recalificar-evaluacion.component"
import { EvaluacionInstanciaPreviewModalComponent } from "./modals/evaluacion-instancia-preview-modal/evaluacion-instancia-preview-modal.component"
import { HistorialEvaluacionesComponent } from "./historial-evaluaciones/historial-evaluaciones.component"
import { InstruccionesFijasComponent } from "./instrucciones-fijas/instrucciones-fijas.component"
import { CrearEvaluacionMultipleComponent } from "./crear-evaluacion-multiple/crear-evaluacion-multiple.component"
import { SimulacroRecomendadoAlumnoCardComponent } from "./simulacro-recomendado-alumno-card/simulacro-recomendado-alumno-card.component"
import { ContestableSmComponent } from "./contestable-sm/contestable-sm.component"
import { EvaluacionPorGeneradorComponent } from "./evaluacion-por-generador/evaluacion-por-generador.component"
import { EvaluacionEscalasGlobalesComponent } from "./evaluacion-escalas-globales/evaluacion-escalas-globales.component"
import { EvaluacionInstanciaEscalasService } from "./evaluacion_instancia_escalas.service"
import { EscalaGlobalAliasInstanciaComponent } from "./escala-global-alias-instancia/escala-global-alias-instancia.component"
import { EscalaGlobalAliasesInstanciaComponent } from "./escala-global-aliases-instancia/escala-global-aliases-instancia.component"
import { ResumenSemanalPracticarShowcaseboxComponent } from "./resumen-semanal-practicar-showcasebox/resumen-semanal-practicar-showcasebox.component"
import { EvaluacionSecuencialComponent } from "./evaluacion_secuencial.component"
import { EstadisticasModule } from "@puntaje/puntaje/new-modules/estadisticas"
import { AlertaEvaluacionTiempoComponent } from "./alerta-evaluacion-tiempo/alerta-evaluacion-tiempo.component"

@NgModule({
    imports: [
        Angular2FontawesomeModule,
        Angulartics2Module,
        CommonModule,
        CommonsLayoutsModule,
        CUIContainersModule,
        CUIInputsModule,
        CUITextsModule,
        EstadisticasModule,
        FormModule,
        FormsModule,
        ImprimirModule,
        InstrumentosModule,
        MaterialesModule,
        ModalModule,
        ModelViewModule,
        NebuModule,
        PaginatorModule,
        PopoverModule,
        ProgressbarModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        TabsModule,
        TooltipModule,
        TranslationsModule,
        UtilModule
    ],
    declarations: [
        AbstractGenerarEnsayoComponent,
        GenerarEnsayoComponent,
        EvaluacionTiempoComponent,
        PreguntasEnsayoComponent,
        EvaluacionMenuPreguntasComponent,
        PreguntaEnsayoComponent,
        EnsayoInstanciaResumenComponent,
        EnsayoInstanciasComponent,
        EnsayoInstancias2Component,
        RespuestaEnsayoComponent,
        RespuestasEnsayoComponent,
        EvaluacionesCompartidasComponent,
        EvaluacionesMultiplesCompartidasComponent,
        EvaluacionesMultiplesPresencialesComponent,
        GenerarEnsayoProfesorComponent,
        GenerarEnsayoProfesor2Component,
        GenerarEnsayoAlumnoComponent,
        GenerarEnsayoAdaptiveComponent,
        MenuAsignaturasDropdownComponent,
        EnsayoInstanciaResumenAlumnoComponent,
        SubirRespuestasCSVComponent,
        SubirRespuestasImagenComponent,
        EvaluacionesPendientesShowcaseComponent,
        EnsayoInstanciaResumenAlumnoComponent,
        EnsayosBrevesComponent,
        EvaluacionesPresencialesComponent,
        DatosGeneralesEvaluacionComponent,
        ListaInstrumentosPreHechosComponent,
        GenerarEvaluacionAlumnoGraduateComponent,
        ListaEvaluacionesPreHechasComponent,
        EnsayosRecomendadosComponent,
        EvaluacionesCompartidasAlumnoComponent,
        EditarEvaluacionComponent,
        VerPreguntaComponent,
        InprimirEnsayoExternoComponent,
        GenerarInstrumentoAdminComponent,
        EvaluacionInteractivaComponent,
        AdminEvaluacionesMasivasComponent,
        EvaluacionInteractivaStatusComponent,
        EvaluacionPortadaComponent,
        EvaluacionSecuencialComponent,
        CompartirResultadosEvaluacionComponent,
        EliminarEvaluacionComponent,
        EvaluacionesTECShowcaseComponent,
        GenerarEnsayoProfesorDynamicTabsComponent,
        EvaluacionesCBShowcaseBoxComponent,
        EvaluacionesInstanciasCBShowcaseBoxComponent,
        CompartirReforzamientosComponent,
        GenerarEnsayoMultipleProfesorComponent,
        EnsayosMultiplesRecomendadosComponent,
        SubirRespuestasImagenMultipleComponent,
        EvaluacionesMultiplesCompartidasAlumnoComponent,
        CorregirEnsayoCamaraComponent,
        SubirRespuestasImagenAlumnoComponent,
        EvaluacionPreviewModalComponent,
        ProgresoPreguntasEvaluacionSecuencialComponent,
        EvaluacionInstanciaMultipleResumenComponent,
        EvaluacionInstanciaMultipleDetalleClasificacionesComponent,
        TablaEvaluacionFormaEstudiantesComponent,
        DescargarCsvPrellenadoComponent,
        ModalImprimirEvaluacionAlumnosComponent,
        CorregirEnsayosTabsProfesorComponent,
        CorregirEnsayosTabsContenidoProfesorComponent,
        ResponderEvaluacionPropiaComponent,
        EvaluacionesPendientesAsignaturasComponent,
        EvaluacionesPendientesAsignaturaBoxComponent,
        ResultadosEvaluacionesAsignaturasComponent,
        ResultadosEvaluacionesAsignaturaBoxComponent,
        ResultadosEvaluacionComponent,
        EliminarResultadosPorAlumnoComponent,
        LibroNotasComponent,
        EvaluacionRecomendadaAlumnoCardComponent,
        EvaluacionesRecomendadasAlumnoComponent,
        EvaluacionesRecomendadasListComponent,
        EvaluacionRecomendadaNewComponent,
        EvaluacionRecomendadaEditComponent,
        EvaluacionRecomendadasToolNavbarComponent,
        EnsayosListaLinksComponent,
        ContestableSprComponent,
        RespuestaSprComponent,
        ContestableLibreComponent,
        RespuestaLibreComponent,
        ContestableCompletableComponent,
        RespuestaCompletableComponent,
        ContestableImagenComponent,
        RespuestaImagenComponent,
        ContestableArchivoComponent,
        RespuestaPdfComponent,
        ResultadosEnsayosComponent,
        CalificacionComponent,
        VerRetroalimentacionComponent,
        RetroalimentacionIndividualComponent,
        ThumbnailEvaluacionComponent,
        RecalificarEvaluacionComponent,
        EvaluacionInstanciaPreviewModalComponent,
        HistorialEvaluacionesComponent,
        InstruccionesFijasComponent,
        WidgetEvaluacionMenuPreguntasComponent,
        WidgetEvaluacionTiempoComponent,
        WidgetResponderEvaluacionPropiaComponent,
        CrearEvaluacionMultipleComponent,
        SimulacroRecomendadoAlumnoCardComponent,
        ContestableSmComponent,
        EvaluacionPorGeneradorComponent,
        EvaluacionEscalasGlobalesComponent,
        EscalaGlobalAliasInstanciaComponent,
        EscalaGlobalAliasesInstanciaComponent,
        ResumenSemanalPracticarShowcaseboxComponent,
        AlertaEvaluacionTiempoComponent
    ],
    exports: [
        GenerarEnsayoComponent,
        PreguntaEnsayoComponent,
        PreguntasEnsayoComponent,
        EvaluacionTiempoComponent,
        EvaluacionMenuPreguntasComponent,
        EnsayoInstanciaResumenComponent,
        RespuestasEnsayoComponent,
        EnsayoInstanciasComponent,
        EnsayoInstancias2Component,
        EvaluacionesCompartidasComponent,
        EvaluacionesMultiplesCompartidasComponent,
        EvaluacionesMultiplesPresencialesComponent,
        GenerarEnsayoProfesorComponent,
        GenerarEnsayoProfesor2Component,
        GenerarEnsayoAlumnoComponent,
        GenerarEnsayoAdaptiveComponent,
        MenuAsignaturasDropdownComponent,
        EnsayoInstanciaResumenAlumnoComponent,
        SubirRespuestasCSVComponent,
        SubirRespuestasImagenComponent,
        EvaluacionesPendientesShowcaseComponent,
        EnsayoInstanciaResumenAlumnoComponent,
        EnsayosBrevesComponent,
        EvaluacionesPresencialesComponent,
        DatosGeneralesEvaluacionComponent,
        ListaInstrumentosPreHechosComponent,
        GenerarEvaluacionAlumnoGraduateComponent,
        ListaEvaluacionesPreHechasComponent,
        EnsayosRecomendadosComponent,
        EvaluacionesCompartidasAlumnoComponent,
        EditarEvaluacionComponent,
        VerPreguntaComponent,
        InprimirEnsayoExternoComponent,
        GenerarInstrumentoAdminComponent,
        EvaluacionInteractivaComponent,
        AdminEvaluacionesMasivasComponent,
        EvaluacionInteractivaStatusComponent,
        EvaluacionPortadaComponent,
        EvaluacionSecuencialComponent,
        CompartirResultadosEvaluacionComponent,
        EliminarEvaluacionComponent,
        EvaluacionesTECShowcaseComponent,
        GenerarEnsayoProfesorDynamicTabsComponent,
        EvaluacionesCBShowcaseBoxComponent,
        EvaluacionesInstanciasCBShowcaseBoxComponent,
        CompartirReforzamientosComponent,
        GenerarEnsayoMultipleProfesorComponent,
        SubirRespuestasImagenMultipleComponent,
        EvaluacionesMultiplesCompartidasAlumnoComponent,
        CorregirEnsayoCamaraComponent,
        SubirRespuestasImagenAlumnoComponent,
        EvaluacionPreviewModalComponent,
        ProgresoPreguntasEvaluacionSecuencialComponent,
        EvaluacionInstanciaMultipleResumenComponent,
        EvaluacionInstanciaMultipleDetalleClasificacionesComponent,
        TablaEvaluacionFormaEstudiantesComponent,
        DescargarCsvPrellenadoComponent,
        ModalImprimirEvaluacionAlumnosComponent,
        CorregirEnsayosTabsProfesorComponent,
        CorregirEnsayosTabsContenidoProfesorComponent,
        ResponderEvaluacionPropiaComponent,
        EvaluacionesPendientesAsignaturasComponent,
        EvaluacionesPendientesAsignaturaBoxComponent,
        ResultadosEvaluacionesAsignaturasComponent,
        ResultadosEvaluacionesAsignaturaBoxComponent,
        ResultadosEvaluacionComponent,
        EliminarResultadosPorAlumnoComponent,
        LibroNotasComponent,
        EvaluacionRecomendadaAlumnoCardComponent,
        EvaluacionesRecomendadasAlumnoComponent,
        EvaluacionesRecomendadasListComponent,
        EvaluacionRecomendadaNewComponent,
        EvaluacionRecomendadaEditComponent,
        EvaluacionRecomendadasToolNavbarComponent,
        EnsayosListaLinksComponent,
        ContestableLibreComponent,
        RespuestaLibreComponent,
        ContestableCompletableComponent,
        RespuestaCompletableComponent,
        ContestableImagenComponent,
        RespuestaImagenComponent,
        ContestableArchivoComponent,
        RespuestaPdfComponent,
        ResultadosEnsayosComponent,
        VerRetroalimentacionComponent,
        ThumbnailEvaluacionComponent,
        RecalificarEvaluacionComponent,
        EvaluacionInstanciaPreviewModalComponent,
        HistorialEvaluacionesComponent,
        InstruccionesFijasComponent,
        WidgetEvaluacionMenuPreguntasComponent,
        WidgetEvaluacionTiempoComponent,
        WidgetResponderEvaluacionPropiaComponent,
        CrearEvaluacionMultipleComponent,
        EvaluacionPorGeneradorComponent,
        EscalaGlobalAliasInstanciaComponent,
        EscalaGlobalAliasesInstanciaComponent,
        ResumenSemanalPracticarShowcaseboxComponent,
        AlertaEvaluacionTiempoComponent
    ],
    providers: [EvaluacionInteractivaStatusService, RealizarEnsayoService, EvaluacionInstanciaEscalasService]
})
export class EnsayosModule {}

import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"

import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"

import { routing } from "./profesores_instrumentos.routing"
import { TabsModule } from "ngx-bootstrap"
import { UtilModule } from "@puntaje/shared/core"
import { CUIInputsModule } from "@puntaje/shared/cui"
import { PuntajeInstrumentosModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProfesoresLayoutsModule,
        InstrumentosModule,
        routing,
        UtilModule,
        TabsModule,
        SharedModule,
        CUIInputsModule,
        PuntajeInstrumentosModule
    ],
    exports: [],
    declarations: []
})
export class ProfesoresInstrumentosModule {}

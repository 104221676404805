import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    OnInit,
    ChangeDetectorRef,
    ElementRef
} from "@angular/core"
import { select, Store } from "@ngrx/store"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import {
    Asignatura,
    Asignaturas,
    Pregunta,
    Preguntas,
    GrupoPregunta,
    Materiales,
    Material,
    CertificacionRecursos,
    CertificacionRecurso,
    Contestable
} from "@puntaje/nebulosa/api-services"
import {
    Estadisticas,
    Evaluacion,
    Evaluaciones,
    EvaluacionInstancia,
    EvaluacionInstancias,
    Instrumento,
    InstrumentoPreguntas,
    InstrumentoPregunta,
    EvaluacionForma,
    EvaluacionFormas,
    MonitoreoInstrumentos,
    MonitoreoInstrumento
} from "@puntaje/puntaje/api-services"
import { selectAsignaturasById, State } from "@puntaje/puntaje/store"
import { PdfView, DocsView } from "@puntaje/shared/core"
import { filter, first } from "rxjs/operators"
@Component({
    selector: "instrumento",
    templateUrl: "instrumento.component.html",
    styleUrls: ["instrumento.component.scss"]
})
export class InstrumentoComponent implements OnInit, OnDestroy {
    @Input() enableReporte: boolean = true
    _instrumento: Instrumento
    asignaturaInstrumento: Asignatura
    monitoreoInstrumento: MonitoreoInstrumento

    @Output() ready = new EventEmitter<any>()
    @Output() readyAsignatura = new EventEmitter<any>()
    @Output() onNuevaPregunta = new EventEmitter()
    preguntas: Pregunta[]
    dificultadPreguntas: { [id: number]: string }
    estadisticasNebuByPreguntaId: { [id: number]: any }
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("secondLoadingLayout") secondLoadingLayout: LoadingLayoutComponent
    preguntasAreVisible = false
    displayPreguntas: boolean
    showCambiarPregunta: boolean = false
    destroying: boolean = false
    @Input() showAsignaturaForGroup: boolean = false
    asignaturasById: any = {}
    preguntasCertificadas = {}

    instrumentoPreguntasFormas: { [id: number]: InstrumentoPregunta[] }
    preguntasFormas: { [id: number]: Pregunta[] }
    _evaluacionFormas: EvaluacionForma[] = []
    @Input() set evaluacionFormas(value: EvaluacionForma[]) {
        if (this.preguntas) {
            this.instrumentoPreguntasFormas = value.reduce((acc, ef) => {
                acc[ef.id] = ef.evaluacion_forma_instrumento_preguntas.map(efip => efip.instrumento_pregunta)

                return acc
            }, {})

            this.preguntasFormas = value.reduce((acc, ef) => {
                acc[ef.id] = ef.evaluacion_forma_instrumento_preguntas.map(efip => {
                    return this.preguntas.find(p => efip.instrumento_pregunta.pregunta_id == p.id)
                })

                return acc
            }, {})
        }
        this._evaluacionFormas = value
    }
    get evaluacionFormas(): EvaluacionForma[] {
        return this._evaluacionFormas
    }

    evaluacionForma: EvaluacionForma
    showInstrumentoMaterial: boolean = false
    fileParams: any = {}
    instrumentoMaterial: Material
    isactive: number = 0
    certificado: CertificacionRecurso[]
    hasInstances = false

    almostAllPiloto: boolean
    @Input() enableOpcionesPregunta: boolean = true

    constructor(
        protected instrumentoPreguntasService: InstrumentoPreguntas,
        protected preguntasService: Preguntas,
        protected evaluacionFormasService: EvaluacionFormas,
        protected asignaturasService: Asignaturas,
        protected store: Store<State>,
        protected evaluacionesService: Evaluaciones,
        protected simpleModalService: SimpleModalService,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected estadisticasService: Estadisticas,
        protected materialesService: Materiales,
        protected certificacionesRecursosService: CertificacionRecursos,
        protected monitoreoInstrumentoService: MonitoreoInstrumentos,
        protected cdr: ChangeDetectorRef,
        private elementRef: ElementRef
    ) {}

    @Input()
    get instrumento(): Instrumento {
        return this._instrumento
    }

    set instrumento(i: Instrumento) {
        if (!i) return

        this.preguntas = null

        this._instrumento = i

        if (this._instrumento.instrumento_material) {
            this.materialesService.find(this.instrumento.instrumento_material.material_id).then((m: Material) => {
                this.instrumentoMaterial = m
                this.showInstrumentoMaterial = true
                this.setParamsFile()
                this.loadingLayout.ready()
                this.cdr.detectChanges()
                this.secondLoadingLayout.ready()
                this.ready.emit()
            })

            return
        }

        this.setAlmostAllPilotaje()

        this.instrumentoMaterial = null
        this.showInstrumentoMaterial = false

        const preguntaIds = i.instrumento_preguntas.map(ip => ip.pregunta_id)

        this.updatePreguntasInfo(preguntaIds)

        this.preguntasService
            .where({
                per: preguntaIds.length,
                reduced: 1,
                methods: "[nombre_asignatura,nombre_autor,nombre_profesor]",
                with_grupo_pregunta: 1,
                with_clasificaciones: 1,
                with_clasificaciones_with_clasificacion_tipo: 1,
                pregunta: { id: preguntaIds }
            })
            .then(async (preguntas: Pregunta[]) => {
                const evaluaciones = (await this.evaluacionesService.where({
                    evaluacion: { instrumento_id: i.id },
                    mis_colegios: true
                })) as Evaluacion[]

                this.instrumentoPreguntasFormas = this.evaluacionFormas.reduce((acc, ef) => {
                    acc[ef.id] = ef.evaluacion_forma_instrumento_preguntas.map(efip => efip.instrumento_pregunta)

                    return acc
                }, {})

                this.preguntasFormas = this.evaluacionFormas.reduce((acc, ef) => {
                    acc[ef.id] = ef.evaluacion_forma_instrumento_preguntas.map(efip => {
                        return preguntas.find(p => efip.instrumento_pregunta.pregunta_id == p.id)
                    })

                    return acc
                }, {})

                this.showCambiarPregunta = !(await this.evaluacionInstanciasService.exists({
                    evaluacion_instancia: { evaluacion_id: evaluaciones.map(e => e.id) }
                }))
                this.preguntas = preguntas

                this.preguntas.forEach(p => {
                    this.preguntasCertificadas[p.id] = false
                })

                const params2 = {
                    certificacion_recurso: {
                        recurso_type: "Pregunta",
                        recurso_id: this.preguntas.map(pregunta => pregunta.id)
                    }
                }

                this.certificacionesRecursosService
                    .where(params2)
                    .then((certificadoRecurso: CertificacionRecurso[]) => {
                        certificadoRecurso.forEach(cr => {
                            this.preguntasCertificadas[cr.recurso_id] = true
                        })
                    })

                this.loadingLayout.ready()
                this.ready.emit()

                this.preguntasAreVisible = true
                this.secondLoadingLayout.ready()

                this.asignaturasService.find(this.instrumento.asignatura_id).then((asignatura: Asignatura) => {
                    this.asignaturaInstrumento = asignatura
                    this.readyAsignatura.emit(this.asignaturaInstrumento)
                })

                this.evaluacionInstanciasService
                    .exists({ instrumento: { id: this.instrumento.id } })
                    .then((evaluacionInstancias: EvaluacionInstancia[]) => {
                        this.hasInstances = evaluacionInstancias.length > 0
                    })
            })
    }

    ngOnInit() {
        this.saveVisitLog(true)

        const asignaturasById$ = this.store.pipe(
            select(selectAsignaturasById),
            filter(x => !!x),
            first()
        )

        // Para esto no guardo la subscripción porque solo le llega el primer valor que tenga (por el first)
        asignaturasById$.subscribe(asignaturasById => {
            this.asignaturasById = asignaturasById
        })
    }

    ngOnDestroy() {
        this.saveVisitLog()
        this.destroying = true
    }

    async saveVisitLog(withLoop = false) {
        const usuario = JSON.parse(localStorage.getItem("usuario"))
        const roles = localStorage.getItem("roles")
        const isDocente = roles?.includes("Docente") || false
        const i_id = this.instrumento?.id
        let time = 30000 // 30 seconds
        if (!isDocente || this.destroying) return

        if (i_id && i_id > 0) {
            if (this.monitoreoInstrumento === undefined) {
                this.monitoreoInstrumento = new MonitoreoInstrumento()
                this.monitoreoInstrumento.usuario_id = usuario?.id
                this.monitoreoInstrumento.instrumento_id = i_id
            }
            let call = undefined
            const m_id = this.monitoreoInstrumento.id
            if (m_id) {
                call = this.monitoreoInstrumentoService.update(m_id, this.monitoreoInstrumento)
            } else {
                call = this.monitoreoInstrumentoService.save(this.monitoreoInstrumento)
            }
            if (call === undefined) return

            await call
                .then((mi, status) => {
                    this.monitoreoInstrumento.id = mi.id
                })
                .catch(error => {
                    console.error(error)
                })
        } else {
            time = 5000 //something is not defined yet, try in 5 seconds
        }
        if (withLoop) {
            if (!this.visible()) {
                return
            }
            const timer = ms => new Promise(res => setTimeout(res, ms))
            await timer(time)
            this.saveVisitLog(withLoop)
        }
    }

    visible() {
        const element = this?.elementRef?.nativeElement
        if (!element) return false
        if (element.offsetParent === null) return false
        return true
    }

    cambiarGrupo(i: number, grupoPreguntaId: number) {
        this.instrumentoPreguntasService.enableIgnoreModel()
        this.instrumentoPreguntasService
            .cambiarGrupo({
                instrumento: { id: this.instrumento.id },
                pregunta: { grupo_pregunta_id: grupoPreguntaId },
                grupo_pregunta: { id: grupoPreguntaId }
            })
            .then((obj: any) => {
                this.instrumentoPreguntasService.disableIgnoreModel()

                if (obj.grupo_pregunta_id == grupoPreguntaId) {
                    this.simpleModalService.cleanModal()
                    this.simpleModalService.setModalHeader("Advertencia")
                    this.simpleModalService.setModalStringContent(
                        "No existen grupo preguntas disponibles con los mismos criterios del instrumento creado."
                    )
                    this.simpleModalService.showSimpleModal()
                    this.loadingLayout.ready()

                    return
                }

                const firstIndex = this.preguntas.findIndex(p => p.grupo_pregunta_id == grupoPreguntaId)

                obj.instrumento_preguntas.forEach((ip, index) => {
                    this.instrumento.instrumento_preguntas[firstIndex + index] = ip

                    // HACK: como se ignora el modelo en este endpoint, "rehacerlo" solo para la pregunta.
                    const pregunta = Object.assign(new Pregunta(), {
                        ...obj.preguntas[index]
                    })

                    pregunta.contestables = [
                        Object.assign(new Contestable(), {
                            ...pregunta.contestables[0]
                        })
                    ]

                    this.preguntas[firstIndex + index] = pregunta
                })
            })
    }

    onDeletedPregunta(instrumentoPreguntaId: number) {
        const instrumentoPreguntaIndex = this.instrumento.instrumento_preguntas.findIndex(
            ip => ip.id == instrumentoPreguntaId
        )

        this.instrumento.instrumento_preguntas.splice(instrumentoPreguntaIndex, 1)

        this.instrumento.instrumento_preguntas = this.instrumento.instrumento_preguntas.slice()

        this.preguntas.splice(instrumentoPreguntaIndex, 1)
        this.preguntas = this.preguntas.slice()

        this.evaluacionFormas.forEach(evaluacionForma => {
            const instrumentoPreguntaIndex = this.instrumentoPreguntasFormas[evaluacionForma.id].findIndex(
                ip => ip.id == instrumentoPreguntaId
            )

            this.instrumentoPreguntasFormas[evaluacionForma.id].splice(instrumentoPreguntaIndex, 1)
            this.instrumentoPreguntasFormas[evaluacionForma.id] =
                this.instrumentoPreguntasFormas[evaluacionForma.id].slice()

            this.preguntasFormas[evaluacionForma.id].splice(instrumentoPreguntaIndex, 1)
            this.preguntasFormas[evaluacionForma.id] = this.preguntasFormas[evaluacionForma.id].slice()
        })
    }

    setEvaluacionForma(evaluacionForma, index = 0) {
        this.isactive = index
        this.evaluacionForma = evaluacionForma
    }

    setAlmostAllPilotaje() {
        const cantidadInstrumentoPreguntas = this.instrumento.instrumento_preguntas.length
        let instrumentoPreguntas
        if (this.evaluacionForma) {
            instrumentoPreguntas = this.instrumentoPreguntasFormas[this.evaluacionForma.id]
        } else {
            instrumentoPreguntas = this.instrumento.instrumento_preguntas
        }

        this.almostAllPiloto = instrumentoPreguntas.filter(ip => ip.piloto).length >= cantidadInstrumentoPreguntas - 1
    }

    nuevaPregunta({ instrumento_pregunta, index }: { instrumento_pregunta: InstrumentoPregunta; index: number }) {
        let ip1 = this.instrumento.instrumento_preguntas.slice(0, index)
        let ip2 = this.instrumento.instrumento_preguntas.slice(index)

        this.instrumento.instrumento_preguntas = [...ip1, instrumento_pregunta, ...ip2]

        let p1 = this.preguntas.slice(0, index)
        let p2 = this.preguntas.slice(index)

        this.preguntas = [...p1, instrumento_pregunta.pregunta, ...p2]

        this.updatePreguntasInfo([instrumento_pregunta.pregunta.id])

        this.onNuevaPregunta.emit()
        this.cdr.detectChanges()
    }

    /**
     * Método que se llama cuando una pregunta es cambiada en el instrumento.
     * Acatualiza la pregunta dentro del array instrumento_preguntas y luego actualiza
     * las estadisticas.
     * @param instrumentoPregunta Instrumento pregunta que trae la nueva pregunta
     */
    changePregunta(instrumentoPregunta) {
        const index = this.instrumento.instrumento_preguntas.findIndex(ip => ip.id == instrumentoPregunta.id)
        this.instrumento.instrumento_preguntas[index] = instrumentoPregunta
        // se hace un object assign para que use la misma referencia, o renderea otra instancia del componente instrumento-pregunta y el historial de preguntas
        // para deshacer se pierde
        this.preguntas[index] = Object.assign(this.preguntas[index], instrumentoPregunta.pregunta)

        this.updatePreguntasInfo([instrumentoPregunta.pregunta.id])
    }

    setParamsFile() {
        let dato = this.instrumentoMaterial.getExtension()
        if (dato == "docx" || dato == "doc") {
            this.fileParams = { label: "Previsualizar", type: DocsView }
        } else {
            this.fileParams = { label: "Previsualizar", type: PdfView }
        }
    }

    /**
     * Actualiza las estadisticas (dificultad y correctas) de las preguntas.
     * @param preguntaIds Array de ids de preguntas
     */
    updatePreguntasInfo(preguntaIds) {
        const params = {
            collection: "EstadisticaPregunta",
            estadistica: {
                pregunta_id: preguntaIds
            }
        }

        this.dificultadPreguntas ||= {}
        for (const preguntaId of preguntaIds) {
            this.dificultadPreguntas[preguntaId] = "-"
        }

        this.estadisticasService.where(params).then((estadisticas: any) => {
            this.preguntasService.estadisticasAccum({ pregunta_ids: preguntaIds }).then(estadisticasByPreguntaId => {
                this.estadisticasNebuByPreguntaId ||= {}
                this.estadisticasNebuByPreguntaId = {
                    ...this.estadisticasNebuByPreguntaId,
                    ...estadisticasByPreguntaId
                }

                Object.keys(this.estadisticasNebuByPreguntaId).forEach(preguntaId => {
                    const estadistica = this.estadisticasNebuByPreguntaId[preguntaId]

                    const numeroRespuestas = estadistica.correctas + estadistica.incorrectas + estadistica.omitidas
                    const dificultad = estadistica.correctas / numeroRespuestas

                    let dificultadString = ""
                    if (dificultad <= 0.3) {
                        dificultadString = "Difícil"
                    } else if (dificultad <= 0.6) {
                        dificultadString = "Media"
                    } else {
                        dificultadString = "Fácil"
                    }

                    this.dificultadPreguntas[preguntaId] = dificultadString
                })

                this.cdr.detectChanges()
            })
        })
    }
}

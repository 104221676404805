import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { StreamingModule as StreamingMod } from "@puntaje/puntaje/new-modules/streaming"
import { BannersModule } from "@puntaje/puntaje/new-modules/banners"
import { routing } from "./streaming.routing"

import { UtilModule } from "@puntaje/shared/core"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { PuntajeStreamingModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        CommonModule,
        ProfesoresLayoutsModule,
        StreamingMod,
        routing,
        BannersModule,
        UtilModule,
        SharedModule,
        PuntajeStreamingModule
    ],
    exports: [],
    declarations: [],
    providers: []
})
export class ProfesoresStreamingModule {}

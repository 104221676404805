import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core"
import { EvaluacionInstancia } from "@puntaje/puntaje/api-services"
import { GenericModalComponent } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "evaluacion-instancia-preview-modal",
    templateUrl: "./evaluacion-instancia-preview-modal.component.html",
    styleUrls: ["./evaluacion-instancia-preview-modal.component.scss"]
})
export class EvaluacionInstanciaPreviewModalComponent implements OnInit {
    evaluacionInstancia: EvaluacionInstancia

    @Input() evaluacionInstanciaId: number
    @Input() modalId: string

    @ViewChild(GenericModalComponent, { static: true }) genericModal: GenericModalComponent
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    @Output() closeCrossCallback: EventEmitter<any> = new EventEmitter<any>()

    constructor() {}

    ngOnInit() {}

    onEvaluacionInstanciaReady($event) {
        this.evaluacionInstancia = $event
        this.loadingLayout.ready()
    }

    closeCross() {
        this.closeCrossCallback.emit()
    }

    public open() {
        this.genericModal.buttonPressed()
    }

    public close() {
        this.genericModal.close()
    }
}

import { Component, OnInit } from "@angular/core"

@Component({
    templateUrl: "./faq.component.html"
})
export class FAQComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}

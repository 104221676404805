import { Component, OnInit, Input, HostBinding } from "@angular/core"
import { Router } from "@angular/router"
import { AppConfig, AuthService } from "@puntaje/shared/core"

declare const config: AppConfig

@Component({
    selector: "menu-showcase",
    templateUrl: "./menu-showcase.component.html",
    styleUrls: ["./menu-showcase.component.scss"]
})
export class MenuShowcaseComponent implements OnInit {
    @Input() menuCustom?: any
    menu: any[] = [
        {
            url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Calendarizacion.png",
            descripcion: "Planes de clase",
            clase: "calendarizacion",
            link: "/plan_clases"
        },
        /* {
            url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Calendarizacion.png",
            descripcion: "Planes de clase estudiante",
            clase: "calendarizacion",
            link: "/plan_clases_alumnos"
        }, */
        {
            url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Evaluar.png",
            descripcion: "Evaluar",
            clase: "evaluar",
            link: "/ensayos/generar_ensayo"
        },
        {
            url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Corregir.png",
            descripcion: "Corregir",
            clase: "corregir",
            link: "/evaluacion_instancias/subir"
        },
        {
            url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Resultados.png",
            descripcion: "Resultados",
            clase: "resultados",
            link: "/evaluaciones/resultados"
        },
        /*  {
            url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Calendarizacion.png",
            descripcion: "Calendarización",
            clase: "calendarizacion",
            link: "/calendarizacion"
        }, */
        {
            url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Biblioteca.png",
            descripcion: "Biblioteca",
            clase: "biblioteca",
            link: "/bibliotecas"
        },
        {
            url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Orientacion.png",
            descripcion: "Orientación",
            clase: "orientacion",
            link: "/orientacion"
        }
    ]

    @HostBinding("class") innerClass = "showcasebox_style_1_primary"

    constructor(protected router: Router, protected authService: AuthService) {}

    ngOnInit() {
        this.menu = this.menuCustom ? this.menuCustom : this.menu
        if (this.authService.getEstablecimientos().length != 0 && config.app.name == "profesores") {
            let item = {
                url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Reportes.png",
                descripcion: "Reportes",
                clase: "reportes",
                link: "/estadisticas/resumen_uso"
            }
            this.menu.push(item)
        }
    }

    irA(link, params) {
        this.router.navigate([link], { queryParams: params })
    }
}

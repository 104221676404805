import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    templateUrl: "home_pruebas.component.html"
})
export class HomePruebasComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []

    constructor(protected store: Store<State>, protected loggedLayoutService: LoggedLayoutService) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            /*{{
                route: "/pruebas/generar_prueba/" + asignaturasByEvaluacionTipo["curricular"][0].id,
                params: { tipo_instrumento: "curricular", tab: "generar" },
                label: "Generar prueba curricular",
                text: "Genera pruebas de contenido curricular y compártelas con tus estudiantes. Con opciones personalizables.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },

			route: '/pruebas/generar_prueba/' + this.asignaturasByEvaluacionTipo['curricular PIE'][0].id,
			params: {tipo_instrumento: 'curricular PIE'},
			label: "Generar prueba curricular PIE",
			text: "Genera pruebas de contenido curricular PIE y compártelas con tus estudiantes. Con opciones personalizables.",
			linkText: "Comenzar",
			icon: "hacer-prueba"
		},*/
            {
                route: "/pruebas/compartidas/",
                params: { tipo_instrumento: "curricular", asignatura_id: 1 },
                label: "Historial de pruebas",
                text: "Encuentra aquí la lista de todas las pruebas compartidas, no compartidas y presenciales.",
                linkText: "Ver historial",
                icon: "guia"
            },
            /* {
                route: "/pruebas/generar_prueba/" + asignaturasByEvaluacionTipo["curricular"][0].id,
                params: { tipo_instrumento: "curricular", tab: "recomendados" },
                label: "Pruebas curriculares recomendadas",
                text: "Encuentra pruebas de contenido curricular y compártelas con tus estudiantes.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },*/
            {
                route: "/pruebas/progreso/",
                params: { tipo_instrumento: "curricular" },
                label: "Progreso de tus estudiantes",
                text: "Observa la evolución en el tiempo de los resultados obtenidos por tus estudiantes.",
                linkText: "Ver progreso",
                icon: "estadistica-o"
            },
            {
                route: "/pruebas/estadisticas/",
                params: { tipo_instrumento: "curricular" },
                label: "Estadísticas",
                text: "Encuentra aquí informes estadísticos generados a partir de los resultados.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            },
            /* {
                route: "/evaluacion_instancias/subir",
                label: "Subir Resultados",
                text: "Sube resultados de simulacros a partir de una hoja de respuesta o una planilla CSV.",
                linkText: "Cargar",
                icon: "hacer-prueba"
            },
            {
                route: "/instrumentos/subir_prueba_propia",
                params: { tipo_instrumento: "curricular" },
                label: "Subir pruebas propias",
                text: "Sube tus propias pruebas y compártelas con tus estudiantes.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            }, */
            {
                route: "/pruebas/libro",
                params: { tipo_instrumento: "curricular" },
                label: "Libro de Clases",
                text: "Aquí podrás encontrar información de tus alumnos y las pruebas que han rendido.",
                linkText: "Ver libro",
                icon: "libro-o"
            }
        ]
    }
}

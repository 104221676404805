<loading-layout #loadingLayout>
    <div *ngFor="let seccion of seccionesPreguntas; let i = index" class="wrap_container_level_1">
        <h4>{{ seccion.titulo }}</h4>
        <div *ngFor="let pregunta of seccion.preguntas; let i = index" class="wrap_container_level_1">
            <div class="title_level_1" [toggleExpandable]="contenidoNivel1" #firstLevelToggle>
                <div class="t-body">
                    <div class="t-cell">
                        <h2><div [innerHTML]="i + 1 + '.- ' + pregunta.enunciado"></div></h2>
                    </div>
                    <div class="t-cell title_level_1_icon">
                        <span class="glyphicon glyphicon-minus-sign expanded-sign"></span>
                        <span class="glyphicon glyphicon-plus-sign contracted-sign"></span>
                    </div>
                </div>
            </div>
            <div [expandableContent] class="wrapper_level_1" #contenidoNivel1>
                <div class="inner-margins_cont_level_1">
                    <div class="cont_level_1">
                        <div [innerHTML]="pregunta.respuesta"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</loading-layout>

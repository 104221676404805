import { Component, OnInit, Input } from "@angular/core"

@Component({
    selector: "countrys",
    templateUrl: "./countrys.component.html",
    styleUrls: ["../landing-aprendolibre.component.scss"]
})
export class CountrysComponent implements OnInit {
    @Input() pais: string = "mexico"
    constructor() {}

    ngOnInit() {}
}

import { Component } from "@angular/core"

import { AuthService } from "@puntaje/shared/core"

/*#################################

Template simple para evaluaciones. Incluye el navbar en la parte superior.
Implementa una sección de contenido, un título, y contenido lateral a la izquierda

evaluation-layout-titulo
evaluation-layout-contenido
evaluation-layout-contenido-lateral

#################################*/

@Component({
    templateUrl: "evaluation_layout.component.html",
    selector: "evaluation-layout",
    styleUrls: ["evaluation_layout.component.scss"]
})
export class EvaluationLayoutComponent {
    constructor(public authService: AuthService) {}

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }
}

<loading-layout #loadingLayout>
    <loading-layout #secondLoadingLayout></loading-layout>
</loading-layout>

<!-- <lista-resultados-instrumento [preguntas]="preguntas" *ngIf="instrumento && !showInstrumentoMaterial"
    [evaluacionTipo]="instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento"
    [generadorInstrumento]="instrumento.generador_instrumento"></lista-resultados-instrumento> -->
<ng-container *ngIf="showInstrumentoMaterial">
    <model-plain-value [params]="fileParams" [value]="instrumentoMaterial" key="url"></model-plain-value>
</ng-container>

<ng-container *ngIf="preguntas && evaluacionFormas?.length > 0">
    <a class="btn" [ngClass]="{ active: isactive === 0 }" (click)="setEvaluacionForma(null)">Original</a>
    <ng-container *ngFor="let evaluacionForma of evaluacionFormas; let i = index">
        <a class="btn" [ngClass]="{ active: isactive === i + 1 }" (click)="setEvaluacionForma(evaluacionForma, i + 1)">
            Forma {{ evaluacionForma.forma }}
        </a>
    </ng-container>
</ng-container>
<cui-tabs justifyContent="space-around">
    <cui-tab title="Versión digital" ariaLabelledBy="versión digital">
        <ng-container *ngIf="!evaluacionForma">
            <div
                *ngFor="let pregunta of preguntas; let i = index"
                [class.hide-preguntas]="!preguntasAreVisible"
                class="instrumento-pregunta-wrap"
            >
                <h4
                    class="subtitulo-asignatura clearfix {{ asignaturasById[pregunta.asignatura_id]?.clase }}"
                    *ngIf="
                        pregunta.nombre_asignatura != preguntas[i - 1]?.nombre_asignatura &&
                        showAsignaturaForGroup &&
                        asignaturasById
                    "
                >
                    <ogr-icon
                        class="icono"
                        name="{{
                            asignaturasById[pregunta.asignatura_id]
                                ? asignaturasById[pregunta.asignatura_id].icono
                                : 'libro2-o'
                        }}"
                    ></ogr-icon>
                    {{ pregunta.nombre_asignatura }}
                </h4>
                <div *ngIf="pregunta.grupo_pregunta && pregunta.grupo_pregunta.id" class="print-hide">
                    <cui-button
                        buttonType="cancel"
                        *ngIf="showCambiarPregunta"
                        class="btn_style_2"
                        (click)="cambiarGrupo(i, pregunta.grupo_pregunta_id)"
                    >
                        <fa name="exchange"></fa>
                        &nbsp;Cambiar Grupo
                    </cui-button>
                </div>
                <instrumento-pregunta
                    [instrumentoPregunta]="instrumento.instrumento_preguntas[i]"
                    [pregunta]="pregunta"
                    [instrumento]="instrumento"
                    [index]="i"
                    [grupoPreguntaPrev]="preguntas[i - 1]?.grupo_pregunta"
                    [showCambiarPregunta]="showCambiarPregunta"
                    [dificultad]="dificultadPreguntas[pregunta.id]"
                    [evaluacionTipo]="instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento"
                    [enableReportBtn]="enableReporte"
                    [certificada]="preguntasCertificadas[pregunta.id]"
                    (deletedPregunta)="onDeletedPregunta($event)"
                    [almostAllPiloto]="almostAllPiloto"
                    (togglePiloto)="setAlmostAllPilotaje()"
                    (nuevaPregunta)="nuevaPregunta($event)"
                    (changePregunta)="changePregunta($event)"
                    [enableOpcionesPregunta]="enableOpcionesPregunta && !hasInstances"
                    [estadisticaGlobal]="estadisticasNebuByPreguntaId?.[pregunta.id]"
                ></instrumento-pregunta>
            </div>
        </ng-container>

        <ng-container *ngIf="evaluacionForma">
            <div
                *ngFor="let pregunta of preguntasFormas[evaluacionForma.id]; let i = index"
                [class.hide-preguntas]="!preguntasAreVisible"
                class="instrumento-pregunta-wrap print-hide"
            >
                <h4
                    class="subtitulo-asignatura clearfix {{ asignaturasById[pregunta.asignatura_id]?.clase }}"
                    *ngIf="
                        pregunta.nombre_asignatura != preguntas[i - 1]?.nombre_asignatura &&
                        showAsignaturaForGroup &&
                        asignaturasById
                    "
                >
                    <ogr-icon
                        class="icono"
                        name="{{
                            asignaturasById[pregunta.asignatura_id]
                                ? asignaturasById[pregunta.asignatura_id].icono
                                : 'libro2-o'
                        }}"
                    ></ogr-icon>
                    {{ pregunta.nombre_asignatura }}
                </h4>

                <instrumento-pregunta
                    [instrumentoPregunta]="instrumentoPreguntasFormas[evaluacionForma.id][i]"
                    [pregunta]="pregunta"
                    [index]="i"
                    [grupoPreguntaPrev]="preguntas[i - 1]?.grupo_pregunta"
                    [showCambiarPregunta]="false"
                    [dificultad]="dificultadPreguntas[pregunta.id]"
                    [evaluacionTipo]="instrumento.generador_instrumento.tipo_instrumento.tipo_instrumento"
                    (deletedPregunta)="onDeletedPregunta($event)"
                    [almostAllPiloto]="almostAllPiloto"
                    (togglePiloto)="setAlmostAllPilotaje()"
                    (nuevaPregunta)="nuevaPregunta($event)"
                ></instrumento-pregunta>
            </div>
        </ng-container>
    </cui-tab>

    <cui-tab title="Vista previa PDF" ariaLabelledBy="vista previa PDF">
        <vista-previa-instrumento
            *ngIf="instrumento"
            [instrumento]="instrumento"
            [evaluacionFormas]="evaluacionFormas"
            [evaluacionForma]="evaluacionForma"
        ></vista-previa-instrumento>
    </cui-tab>
</cui-tabs>

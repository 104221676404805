import { Component, Directive, ViewChild, AfterContentInit, Input } from "@angular/core"

/*#################################


#################################*/

@Component({
    templateUrl: "public-layout.component.html",
    selector: "public-layout",
    styleUrls: ["public-layout.scss"]
})
export class PublicLayoutComponent {
    @Input() freeContentStyle = false

    @Input() subtitle = ""

    constructor() {}
}

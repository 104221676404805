import { Component, OnInit } from "@angular/core"

@Component({
    templateUrl: "./preguntas-frecuentes.component.html",
    styleUrls: ["./preguntas-frecuentes.component.scss"]
})
export class PreguntasFrecuentesComponent implements OnInit {
    seccionesPreguntas: any[]

    constructor() {}

    ngOnInit() {
        this.seccionesPreguntas = [
            {
                titulo: "Docente / Administrador",
                preguntas: [
                    {
                        enunciado: "¿Cómo puedo restablecer la contraseña de mis estudiantes?",
                        respuesta:
                            "<p>Para restablecer la contraseña de un estudiante debo ir a inicio en mi cuenta, seleccionar mis cursos y hacer click en la nómina de mis estudiantes. Al seleccionar el curso voy a tener la opción de ver cada uno de mis estudiantes, y al seleccionar el botón “Reiniciar Contraseña” se restablece la contraseña, a la contraseña por provisoria que corresponde a los 6 primeros dígitos del RUT.</p>"
                    },
                    {
                        enunciado: "¿Qué navegador debo usar para entrar a www.aprendolibre.cl?",
                        respuesta:
                            "<p>Se recomienda la utilización del navegador Google Chrome, ya que la plataforma está optimizada para ese navegador.</p>"
                    },
                    {
                        enunciado:
                            "¿Qué pasa si hay un imprevisto (falla de internet, electricidad, etc) cuando los estudiantes están realizando una evaluación?",
                        respuesta:
                            "<p>Si los estudiantes están realizando una evaluación y surge algún imprevisto, la evaluación se detendrá, y al volver a ingresar, se reanudará el tiempo restante.</p>" +
                            "<p>Así mismo, si por cualquier motivo cierra la pestaña en su navegador, al volver a cargar la página, le pedirá que inicie sesión nuevamente, y al volver a la evaluación que el alumno estaba realizando, habrán quedado guardadas las preguntas contestadas y el tiempo restante.</p>"
                    },
                    {
                        enunciado: "¿Qué hago si olvido mi contraseña?",
                        respuesta:
                            "<p>En caso que olvide su contraseña, diríjase a la(s) persona(s) que posee una cuenta “Administradora” en su establecimiento y podrá restablecer su contraseña desde su sesión o también puede contactarse con la monitora de su colegio, indicando su rut para que esta pueda restablecerla desde su computador. Se restablece la contraseña, a la contraseña por provisoria que corresponde a los 6 primeros dígitos del RUT.</p>"
                    },
                    {
                        enunciado: "¿Qué hacer si me aparece “Error 0” o “Error 404” en la página?",
                        respuesta:
                            "<p>Si por algún motivo estás realizando un acción en la plataforma y arroja “Error 0” o “Error 404”, recomendamos: a) chequear la conexión a internet y su navegador b) Cargar nuevamente la página (Ctrl + R o F5) para refrescar el contenido y que cargue correctamente. c) Cerrar sesión y volver a iniciar.</p>" +
                            "<p>Si aún así, intentando todos los pasos anteriores persiste el problema, contactarse directamente con su monitora asignada vía correo o teléfono. </p>"
                    },
                    {
                        enunciado: "¿Qué pasa si el alumno no recibe el ensayo que compartí?",
                        respuesta:
                            "<p>Debe verificar la fecha, horario de creación y de envío las cuales deben ser correlativas a lo que el profesor configuró, puesto que hay casos en que los estudiantes acceden posterior a las fechas y horario especificado por el docente.</p>" +
                            "<p>En caso de que esto no esté configurado correctamente usted desde su perfil docente puede editar información. O bien, hay que validar que el estudiante esté asociado al curso. </p>" +
                            "<p>Si el alumnos no está asociado al curso, habrá que cargarlo manualmente al curso y volver a compartir el ensayo.</p>"
                    },
                    {
                        enunciado: "¿Cómo puedo reportar pregunta(s) con error(es)?",
                        respuesta:
                            "<p>Para reportar preguntas o cualquier error que notes en la plataforma, es recomendado enviar evidencia en un correo a la monitora de su establecimiento para que esta pueda gestionarlo de la manera más eficiente. (es importante señalar el número de identificación de la pregunta, que aparece como está marcada la imagen)</p>" +
                            "<img class='img-responsive center-block img-thumbnail img-faq' src='https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/aprendolibre/ayuda/faq/reporte_id_pregunta.jpg' alt='navegador recomendado'>" +
                            "<p>También puedes reportar las preguntas con errores en la plataforma. Si está en la vista previa de alguna evaluación, se dará cuenta que existe la función “Reportar Pregunta”</p>" +
                            "<img class='img-responsive center-block img-thumbnail img-faq' src='https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/aprendolibre/ayuda/faq/reporte_pregunta.jpg' alt='navegador recomendado'>"
                    },
                    {
                        enunciado: "¿Cómo puedo agregar a un alumno o eliminarlo de mi curso?",
                        respuesta:
                            "<p>Para eliminar/agregar un alumno en la lista de “Mis cursos”, debe dirigirse a la persona que posea cuenta “Administradora” en su establecimiento y darle los datos de el alumno o docente que desea agregar/eliminar de su curso.</p>" +
                            "<p>También puede contactarse directamente con la monitora de su colegio para que lo ayude en la gestión de este requerimiento.</p>"
                    },
                    {
                        enunciado: "¿Qué pasa si a un alumno no se le guardaron los resultados de una evaluación?",
                        respuesta:
                            "<p>Puede pasar que al terminar una evaluación a algunos alumnos no se le guarden los resultados, esto pasa por que los propios alumnos no guardan las respuestas correctamente.</p>" +
                            "<p> Es recomendado como profesor especificar al principio y final de una evaluación que deben cliquear “Entregar evaluación” para que queden guardadas sus respuestas y evitar problemas posteriores.</p>" +
                            "<img class='img-responsive center-block img-thumbnail img-faq' src='https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/aprendolibre/ayuda/faq/entregar_evaluacion.jpg' alt='navegador recomendado'>"
                    }
                ]
            }
        ]
    }
}

import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent extends MenuComponent implements OnInit {
    menu: any[] = []

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected store: Store<State>,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    setMenuItems() {
        let asignaturasByEvaluacionTipo = this.asignaturasByEvaluacionTipo$.toPromise()

        this.menu = [
            {
                url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Calendarizacion.png",
                descripcion: "Planes de clase",
                clase: "calendarizacion",
                link: "/plan_estudios"
            },

            {
                url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Evaluar.png",
                descripcion: "Evaluar",
                clase: "evaluar",
                link: "/pruebas/generar_prueba/1"
            },
            {
                url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Corregir.png",
                descripcion: "Corregir",
                clase: "corregir",
                link: "/evaluacion_instancias/subir"
            },
            {
                url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Resultados.png",
                descripcion: "Resultados",
                clase: "resultados",
                link: "/pruebas"
            },
            {
                url: "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/puntaje/home/Biblioteca.png",
                descripcion: "Biblioteca",
                clase: "biblioteca",
                link: "/bibliotecas"
            }
        ]
    }
}

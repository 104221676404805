<div class="content">
    <p>Fecha de última modificación: 07 de diciembre de 2022</p>
    <br/>
    <p>
        El presente documento establece los Términos de uso y Condiciones generales para el uso de la Plataforma Web: <a>{{ site }}</a> (en adelante, “<b>{{ platform }}</b>”), los cuales se hacen aplicables a todos los Usuarios que participen del sitio web, en cualquier parte del mundo en el cual se tenga acceso a la Plataforma, a menos que se indique expresamente lo contrario.
    </p>
    <p>
        Al tenor del presente documento, se entiende que es Usuario toda persona que ingrese, utilice y/o participe de uno o más servicios que se ofrecen en la Plataforma.  
    </p>
    <p>
        El Usuario declara haber leído y acepta expresamente todos los Términos y las Condiciones de uso del Sitio Web, incluyendo las políticas de privacidad, de almacenamiento y tratamiento de datos personales establecidos por <b>{{ platform }}</b> para acceder y hacer uso de los servicios. 
    </p>
    <p>
        En caso de que cualquier Usuario no esté de acuerdo con los Términos y Condiciones de uso del Sitio Web, incluyendo las políticas de privacidad y de almacenamiento y tratamiento de datos personales de <b>{{ platform }}</b>, así como de sus posteriores modificaciones y/o actualizaciones, se ruega no hacer uso de ella. 
        
    </p>
    <p>
        Los Términos y Condiciones podrán ser modificados, total o parcialmente, y de manera unilateral por <b>{{ platform }}</b> y dichos cambios serán plenamente vigentes a partir del momento mismo en que sean publicados en el sitio web de <b>{{ platform }}</b>. Será responsabilidad del Usuario estar informado de las modificaciones efectuadas por <b>{{ platform }}</b>, aunque no medie notificación alguna. De todas maneras, aunque no signifique asunción de responsabilidad por parte de <b>{{ platform }}</b>, ésta se ocupará de notificar a los Usuarios de las modificaciones, oportunamente. 

    </p>
    <p>
        Encontrándose vigentes los nuevos Términos y Condiciones, incluyendo las Políticas de privacidad, si un Usuario usa de los servicios, se entenderá que aceptaron previamente las actualizaciones en la medida en que la ley lo permita. 
    </p>
    <p>
        Cualquier violación por parte de los Usuarios, a los Términos y Condiciones que en este documento se indican, le otorga a <b>{{ platform }}</b> el derecho de suspender la cuenta del Usuario desde la cual se haya efectuado la acción u omisión indebida.
    </p>

    <h3>1.- Servicios que ofrece <b>{{ platform }}</b></h3>
    <ng-container *ngIf="name === 'Puntaje Nacional'; else notPncl">
        <p>
            <b>1.1.</b> <b>{{ platform }}</b> es una Plataforma web que se ofrece de forma libre y gratuita a los Usuarios, en la cual de forma periódica y sistemática se publican contenidos e información útil al estudio y preparación de las pruebas de admisión universitaria. En particular <b>{{ platform }}</b> contribuye en ayudar a los estudiantes a rendir de forma satisfactoria la PAES, mediante la generación periódica de pruebas de práctica, informes estadísticos, material educativo y otros contenidos relacionados con la preparación de la prueba.
        </p>
        <p>
            <b>1.2.</b>  La información contenida en <b>{{ platform }}</b> se entiende complementaria a los materiales y a los estudios que el Usuario pueda recibir por otros medios. Para <b>{{ platform }}</b> no existe la obligación de satisfacer la totalidad de las necesidades formativas o educativas del Usuario, ni asegura un resultado determinado en la Prueba de Acceso a la Educación Superior. 
        </p>
    </ng-container>
    <ng-template #notPncl>
        <p>
            <b>1.1.</b> <b>{{ platform }}</b> es una Plataforma web que se ofrece de forma libre y gratuita a los Usuarios, en la cual de forma periódica y sistemática se publican contenidos e información útil para el desarrollo de la educación escolar de alumnos, principalmente, de Enseñanza Básica; así como también, útiles para profesores, de conformidad a las regulaciones del Ministerio de Educación de Chile. 
        </p>
        <p>
            <b>1.2.</b> La información contenida en <b>{{ platform }}</b> se entiende complementaria a los materiales y a los
            estudios que el Usuario pueda recibir por otros medios. Para <b>{{ platform }}</b> no existe la obligación de satisfacer
            la totalidad de las necesidades formativas o educativas del Usuario.
        </p>
    </ng-template>
    <p>
        <b>1.3.</b>  No obstante el carácter libre y gratuito de la Plataforma web, su utilización sólo se puede ejercer como Usuario debidamente registrado, de conformidad a las instrucciones que se indican en lo sucesivo. 
    </p>

    <h3>2.- Formas de registro en la Plataforma web</h3>
    <p>
        <b>2.1 Contratación (gratuita) para uso individual.</b> Para ser Usuario de <b>{{ platform }}</b> es obligatorio completar el formulario de registro (“Registro de Usuarios”), con la información personal que se pide en él. El Usuario se obliga a verificar que la información personal aportada en el formulario de inscripción sea verdadera y asume, desde ya, el compromiso de actualizarla cada vez que sea necesario. <b>{{ platform }}</b> no se responsabiliza de la veracidad de los datos otorgados por los Usuarios, siendo estos últimos, o sus representantes legales, según corresponda, quienes responderán íntegramente por el contenido de los mismos. 
    </p>
    <p>
        <b>2.1.1.</b> Salvo que se indique lo contrario, las respuestas a las preguntas del “Registro de Usuarios” son voluntarias, sin que la falta de respuesta implique una merma en la calidad o cantidad de servicios posibles de usar, a menos que oportunamente se indique otra cosa. 
    </p>
    <p>
        <b>2.1.2.</b> El Usuario será el único responsable por el uso y actividad efectuada desde su cuenta. Los datos de acceso son personales e intransferibles, siendo responsabilidad del Usuario resguardar su privacidad. El Usuario registrado se compromete a notificar a <b>{{ platform }}</b> de cualquier uso no autorizado de su clave, apenas tenga noticias del evento. 
    </p>
    <p>
        <b>2.2.</b> <b>{{ platform }}</b> se reserva el derecho de rechazar cualquier solicitud de registro o de cancelar un registro previamente aceptado. 
    </p>
    <p>
        <b>2.3.</b> Las personas que no cuenten con la capacidad legal para contratar y registrarse en nuestra plataforma y hacer uso de los servicios, deberán ser asistidas por sus representantes legales. Toda persona que haga uso de la Plataforma se entiende que cuenta con la capacidad legal o representación necesaria para su actuación y aceptación de estos Términos y Condiciones. 
    </p>

    <h3>3.- Envío de informaciones vía correo electrónico</h3>
    <p>
        <b>3.1.</b> El Usuario acepta y autoriza que <b>{{ platform }}</b> pueda enviar información que considere relevante para los Usuarios a la dirección de correo que éste indique en el Formulario de registro. La información podrá ser referida a <b>{{ platform }}</b>, o bien, a información publicitaria de terceros que esté relacionada con las áreas de educación media y universitaria. No obstante, <b>{{ platform }}</b> pondrá a disposición del Usuario mecanismos sencillos y explícitos para solicitar dejar de recibir correos. A este respecto, se hacen aplicables las cláusulas respecto del Uso de datos personales que están contenidas en el Punto Séptimo del presente documento.  
    </p>

    <h3>4.-  Derechos y obligaciones por la Información contenida en el Sitio web</h3>
    <p>
        <b>4.1. Propiedad Intelectual.</b> Todo el contenido, organización, gráficas, diseño, compilación y otros aspectos del Sitio web <b>{{ platform }}</b> se encuentran protegidos por las respectivas leyes de Propiedad Industrial e Intelectual, vigentes en la República de Chile. Su copia, redistribución, publicación, total o parcial, están prohibidas por la ley. Ingeniería de Sistemas Open Green Road S.A. (desde ahora, O.G.R. S.A.), titular de <b>{{ platform }}</b>, hace expresa reserva del ejercicio de todas las acciones que le asistan, sean civiles, administrativas y penales, destinadas al resguardo de sus legítimos derechos de propiedad intelectual e industrial.
    </p>
    <p>
        <b>4.1.1.</b> El Usuario declara y acepta conocer que toda la información y datos publicados en el presente sitio web son de propiedad de O.G.R. S.A., quien posee para todos los efectos legales los derechos de propiedad intelectual, industrial y derechos conexos sobre los mismos. El acceso a <b>{{ platform }}</b> no otorga a los Usuarios derecho, ni titularidad alguna sobre la propiedad intelectual de los contenidos que alberga esta Plataforma. 
    </p>
    <p>
        <b>4.1.2.</b> Se entenderá por “Contenidos” todas las informaciones, mensajes, gráficos, dibujos, diseños, logotipos, isotipos, nombres, marcas, archivos de sonido y/o imagen, fotografías, grabaciones, software y, en general, cualquier clase de material accesible a través del Sitio web de <b>{{ platform }}</b>.
    </p>
    <p>
        <b>4.1.3.</b> El usuario se obliga a: usar los contenidos de forma diligente, correcta y lícita; y, a no emplear los contenidos y, en particular, la información de cualquier otra clase obtenida a través de la Plataforma, para emitir publicidad o para obtener beneficio económico bajo cualquier formato, en Chile o en el extranjero. 
    </p>

    <h3>5.- Licencia para el uso de la Plataforma web</h3>
    <p>
        <b>5.1.</b>  <b>{{ platform }}</b> otorga al Usuario una licencia limitada, personal, no exclusiva, intransferible, no comercial y totalmente revocable para utilizar el Sitio Web <a>{{ site }}</a> en los dispositivos móviles o fijos en que se encuentre disponible la Plataforma, efectuar copias y/o descargas de documentos para uso personal, de conformidad a los términos contenidos en este documento. <b>{{ platform }}</b> se reserva todos los derechos sobre el Sitio Web no expresamente concedidos aquí.
    </p>
    <p>
        <b>5.2.</b> El Usuario acepta la prestación del servicio de este sitio, su contenido y materiales, en las condiciones en que efectivamente se entregan y asume personalmente las consecuencias de su decisión de hacer uso del mismo. 
    </p>
    <p>
        <b>5.3.</b> <b>{{ platform }}</b> no se hace responsable de ningún daño sufrido por el usuario que realiza una copia, descarga, transferencia, distribución o uso de cualquier contenido del Sitio Web protegido. No obstante, si bien <b>{{ platform }}</b> ha tomado las medidas necesarias para revisar los programas y archivos, a efectos de considerarlos libres de virus u otros medios tecnológicos de tipo destructivo, <b>{{ platform }}</b> no se responsabiliza por ningún daño que se pueda ocasionar derivado de archivos maliciosos. 
    </p>
    <p>
        <b>5.4.</b> El Usuario es el único responsable del uso de los Contenidos y/o de los datos que consulta o transfiere en Internet a través de la Plataforma. 
    </p>
    <p>
        <b>5.5.</b> El Usuario reconoce y acepta que el uso de Internet deriva de un servicio público de carácter internacional, sin que <b>{{ platform }}</b> pueda ser responsable directa o indirectamente de las acciones administrativas, técnicas o regulatorias que terceros (privados o públicos) apliquen a sus redes para proteger la integridad del contenido, sistemas, usuarios, etc. 
    </p>

    <h3>6.- Fallas en la Plataforma</h3>
    <p>
        <b>6.1.</b> <b>{{ platform }}</b> no se responsabiliza por cualquier tipo de daño, perjuicio o pérdida del Usuario causados por fallas en el sistema, en el servidor o en Internet.
    </p>

    <h3>7.- Política de Privacidad</h3>
    <p>
        <b>7.1.</b> Por medio del presente aviso, <b>{{ platform }}</b> informa a los Usuarios del sitio web acerca de su política de protección de datos de carácter personal.
    </p>
    <p>
        <b>7.1.1.</b> <b>{{ platform }}</b> declara que respeta y cumple con la obligación de proteger la privacidad de los datos personales de toda persona natural que visite su sitio web, en el almacenamiento y tratamiento de datos personales para uso exclusivo suyo y para transferencias a terceros, bajo los términos que se indicarán en lo sucesivo, sometiéndose al ordenamiento jurídico vigente en la República de Chile, en particular, a lo indicado en la Ley N° 19.628 sobre protección de la vida privada, y en todo lo que no sea contrario a la ley.
    </p>
    <p>
        <b>7.1.2.</b> Los datos personales consisten en toda aquella información de propiedad de personas naturales, identificadas o identificables (en adelante, los “Datos Personales”) que sean otorgadas por las instituciones educativas suscriptoras de servicios y/u obtenidas directamente de los Usuarios, según sea el caso. 
    </p>
    <p>
        Los datos serán objeto de un tratamiento automatizado e incorporados a ficheros de propiedad de <b>Ingeniería de Sistemas Open Green Road S.A. ({{ platform }})</b>, que no son accesibles al público; pudiendo incluir: nombre, fecha de nacimiento, teléfono, domicilio, dirección de correo electrónico, o cualquier otra información que permita individualizar al Usuario.
    </p>
    <p>
        <b>7.2.</b> Los datos personales de los Usuarios, indicados en el Punto anterior, serán tratados por <b>{{ platform }}</b>, con los siguientes propósitos: primero, para la adecuada prestación de los servicios ofrecidos, intentando actuar personalizadamente a las necesidades e intereses del Usuario; segundo, para estudiar y poner en práctica mejoras a los servicios, haciéndolos más atractivos y útiles al Usuario; tercero, para fines informativos y publicitarios, tanto de <b>{{ platform }}</b> como de terceros, por ofertas que puedan ser relevantes para los Usuarios; y, cuarto, para ser transferidos a instituciones universitarias y/o educativas, con el propósito de que los Usuarios sean contactados por éstas para entregarles información comercial que pueda ser relevante para los Usuarios. 
    </p>
    <p>
        <b>7.2.1. El Usuario, en este acto y de forma expresa, acepta que {{ platform }} haga uso y tratamiento de los datos personales del Usuario para los fines indicados en el Párrafo anterior.</b> No obstante, el Usuario mantendrá siempre su derecho de revocar los permisos otorgados en este instrumento.
    </p>
    <p>
        <b>7.3.</b> Por otra parte, <b>{{ platform }}</b>, eventualmente, podrá requerir información personal que, de conformidad a la Ley 19.628 pueda ser catalogada como “Datos sensibles”, por medio de formularios de perfil y encuestas. En específico, se podrá solicitar preferencias del Usuario ligadas al área de la educación y universitaria, así también, ligadas a otros intereses del Usuario de similar tenor. Del mismo modo, <b>{{ platform }}</b> podrá recabar información respecto de la cantidad y frecuencia de los visitantes a la Plataforma y a sus distintas páginas contenidas en el sitio web, de conformidad con lo indicado en Punto Octavo sobre el Uso de Cookies. 
    </p>
    <p>
        <b>7.3.1.</b> Los datos sensibles de los Usuarios, indicados en el Párrafo anterior, serán tratados por <b>{{ platform }}</b>, con los siguientes propósitos: primero, para la adecuada prestación de los servicios ofrecidos, intentando actuar personalizadamente a las necesidades e intereses del Usuario; segundo, para estudiar y poner en práctica mejoras a los servicios, haciéndolos más atractivos y útiles al Usuario; tercero, para fines informativos y publicitarios, tanto de <b>{{ platform }}</b> como de terceros, por ofertas que puedan ser relevantes para los Usuarios; y, cuarto, para ser transferidos a instituciones universitarias y/o educativas, con el propósito de que los Usuarios sean contactados por éstas para entregarles información comercial que pueda ser relevante para los Usuarios.
    </p>
    <p>
        <b>7.3.2. El Usuario, en este acto y de forma expresa, acepta que O.G.R. S.A., titular de {{ platform }} haga uso y tratamiento de los datos sensibles del Usuario para los fines indicados en el Párrafo anterior.</b> No obstante, el Usuario mantendrá siempre su derecho de revocar los permisos otorgados en este instrumento.
    </p>
    <p>
        <b>7.4.</b> Sin perjuicio de lo anterior, <b>{{ platform }}</b>, de forma preferencial y siempre que sea conveniente, procurará pormenorizar todo dato personal y/o sensible a datos estadísticos que no permitan asociar los datos a un titular identificado o identificable.
    </p>
    <p>
        <b>7.5.</b> El Usuario autoriza de forma expresa a <b>{{ platform }}</b> para contratar servicios de tratamiento y/o análisis de datos a terceros proveedores, caso en el cual los contratos con ellos tendrán cláusulas de privacidad similares a las contenidas en este documento. Del mismo modo, el Usuario autoriza expresamente a <b>{{ platform }}</b> a transferir tanto datos personales como datos sensibles a terceros, por lo fines indicados en los Puntos 7.2. y 7.3., respectivamente, caso en el cual los contratos con los terceros tendrán cláusulas de privacidad similares a las contenidas en este documento.
        <b>{{ platform }}</b> procurará que los derechos de los Usuarios titulares sean respetados por terceros. 
    </p>
    <p>
        <b>7.6.</b> En todo caso, el Usuario podrá ejercer sus derechos de acceso, revocación, rectificación y oposición, así como también, siempre tendrá el derecho de estar informado de las cesiones de datos que <b>{{ platform }}</b> efectúe a terceros. 
        El Usuario podrá revocar su autorización para la comunicación a terceros de sus Datos personales y Datos sensibles cuando correspondan a aquellos datos que no se encontraban en fuentes accesibles al público y en el evento de que los Datos personales no sean tratados para uso exclusivo de <b>{{ platform }}</b>, de conformidad a la Ley. En aquellos casos en que el Usuario revoque su autorización para la comunicación de sus Datos Personales y Datos sensibles, siendo aquellos imprescindibles para el otorgamiento correcto de los servicios ofrecidos al Usuario, se entiende que, en el acto de comunicar su negativa a la comunicación de dichos Datos Personales, el Usuario ha cancelado su registro en el sitio web, haciéndose responsable el Usuario de los efectos que resulten de dicha cancelación.    
    </p>
    <p>
        <b>7.7.</b> El acceso y uso del sitio web, efectuada por menores de edad o personas que carezcan de plena capacidad de acuerdo a la legislación vigente, es de responsabilidad de sus respectivos padres o representantes legales.
    </p>
    <p>
        <b>7.8.</b> <b>{{ platform }}</b> actúa con altos niveles de seguridad en la protección de los datos personales protegidos, procurando aplicar todos los sistemas y técnicas adicionales que tenga a su alcance para evitar la pérdida, uso inadecuado, alteración, acceso y robo de datos personales otorgados por el Usuario. No obstante, el Usuario debe ser consciente de que la seguridad en Internet no es inexpugnable, sin que existan sistemas absolutamente seguros para proteger toda la información. En razón de lo anterior, el Usuario entiende que <b>{{ platform }}</b> no puede garantizar que la información proporcionada por el Usuario estará completamente protegida.
    </p>
    <p>
        <b>7.9. Sobre datos derivados de encuestas.</b> Si el Usuario decide voluntariamente participar en las encuestas que se realicen en el Sitio Web, autoriza expresamente a <b>{{ platform }}</b> a utilizar su ID de usuario para acceder a estos datos, los que serán utilizados de manera interna y anónima, confidencial y solo con objeto investigativo. Asimismo, las respuestas de la encuesta se podrán unir a los datos recolectado desde otros bancos de datos pertenecientes a instituciones privadas o públicas de los países en los cuales O.G.R. S.A. o sus filiales tengan participación con alguna de sus Plataformas Webs. La decisión de participar en las encuestas es completamente voluntaria y no existen riesgos asociados a ella. Si el Usuario decide no contestar la encuesta, no habrá ninguna consecuencia negativa para él. 
    </p>
    <p>
        <b>7.10. Sobre datos derivados de Google.</b> <b>{{ platform }}</b> no transmitirá, comercializará, ni publicará la información de los Usuarios obtenida a través de Google. Esta información es utilizada de manera interna para la autenticación del sitio mediante la cuenta de Google. Para lo anterior, solo se guardará el ID del Usuario de Google, de manera de poder asociarlo a la cuenta de <b>{{ platform }}</b>, permitiendo la autenticación del sitio.  
    </p>

    <h3>8.- Uso de Cookies.</h3> 
    <p>
        <b>8.1.</b> Se entiende por Cookie un pequeño archivo que es descargado y almacenado en el disco duro de la computadora o dispositivo electrónico del Usuario que contiene caracteres y especificaciones particulares del Usuario, así como también, información sobre el uso que el Usuario le da al sitio. 
    </p>
    <p>
        <b>8.2.</b> <b>{{ platform }}</b> puede utilizar cookies, permitiendo su descarga al disco duro del Usuario, para permitir una navegación más expedita y personalizada al Usuario.
    </p>
    <p>
        <b>8.3.</b> El Usuario podrá configurar su navegador para evitar la instalación de cookies en su disco duro. De lo contrario, siempre tendrá la opción de explorar su disco duro y conocer en detalle los servidores desde los cuales se reciben las cookies, pudiendo optar por eliminarlos.
    </p>

    <h3>9.- Legislación, domicilio y forma especial de notificación.</h3> 
    <p>
        <b>9.1.</b> El presente acuerdo se rige por el ordenamiento jurídico de la República de Chile. Cualquier controversia derivada del presente acuerdo será sometida a las leyes aplicables en Chile y a los Tribunales de Justicia competentes para su conocimiento, fijando las partes para todos los efectos legales su domicilio en la ciudad y comuna de Santiago de Chile.
    </p>
    <p>
        <b>9.2.</b>  Todas las notificaciones y comunicaciones que deba efectuar el Usuario a <b>{{ platform }}</b> serán consideradas válidas, para todos los efectos legales, judiciales y contractuales, cuando se dirijan a Ingeniería de Sistemas Open Green Road S.A. de la siguiente forma: (a) El envío por correo postal a la siguiente dirección: Avenida Providencia 187, comuna de Providencia, ciudad de Santiago, República de Chile; (b) Para hacer efectivos derechos relacionados al Tratamiento de datos personales, en los casos que no se puedan ejercer dentro de la plataforma web, los titulares pueden escribir al correo electrónico: contacto@ogr.cl
    </p>
    <p>
        <b>9.3.</b> Todas las notificaciones y comunicaciones que deba efectuar <b>{{ platform }}</b> al Usuario serán consideradas válidas, para todos los efectos legales, judiciales y contractuales, cuando se dirijan a éste de las siguientes formas: (a) El envío por correo electrónico a cualquiera de las cuentas que el Usuario haya puesto a disposición de <b>{{ platform }}</b> al momento del registro o con posterioridad. 
    </p>
    <p>
        <b>9.4.</b> Para los efectos de las notificaciones, el Usuario declara y manifiesta que todos los datos otorgados por él son ciertos y correctos, comprometiéndose a comunicar a <b>{{ platform }}</b> cualquier modificación del correo electrónico disponible para efectuar las notificaciones. 
    </p>
</div>
<acerca-de-aprendolibre pais="chile">
    <titulo>
        En los últimos años hemos logrado apoyar a más de +200.000 estudiantes +75.000 profesores +300 establecimientos
        educativos
    </titulo>
    <descripcion>
        <p>
            Aprendo Libre es la plataforma de apoyo escolar para estudiantes y maestros. Sacamos lo mejor de cada
            estudiante al apoyarlo con planes de estudios, clases online y material de apoyo para reforzar contenidos.
            <br />
            Para luego ayudarlo a cumplir sus sueños.
            <br />
            <br />
            Los maestros usan muchas horas a la semana en tareas no lectivas, Aprendo Libre apoya a los maestros con las
            herramientas necesarias para guiar de mejor manera a sus estudiantes a cumplir sus sueños, mientras reduce
            el tiempo en aquellas tareas no lectivas, fomentando una enseñanza más eficiente.
            <br />
            La plataforma permite evaluar, corregir, ver resultados/estadísticas, compartir planes de clases
            personalizados, como también obtener y subir su propio material con tan sólo un par de clics.
            <br />
            <br />
            Aprendo Libre es una de las tantas plataformas educativas que ha desarrollado Open Green Road.
            <br />
            Te invito a conocer otras iniciativas: Puntaje Nacional
        </p>
    </descripcion>
</acerca-de-aprendolibre>

import { SharedLayoutComponent } from "@puntaje/platforms/alcl"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { LandingComponent } from "./landing.component"
import { HomeComponent } from "./home.component"
import { FAQComponent } from "./faq.component"
import { AcercaDeComponent } from "./acerca-de/acerca-de.component"
import { PlaceholderPageComponent, TermsAndConditionsComponent } from "@puntaje/puntaje/core"
import { TermsAndConditionsChileComponent } from "@puntaje/puntaje/new-modules/landing"

export const LandingRoutes: Routes = [
    { path: "landing", component: LandingComponent, canActivate: [TokenGuard] },
    {
        path: "home",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeComponent, canActivate: [AuthGuard, TokenGuard] }]
    },
    { path: "landing/home", redirectTo: "home", pathMatch: "full" },
    {
        path: "condicionesUso",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: TermsAndConditionsComponent,
                data: {
                    component: TermsAndConditionsChileComponent,
                    title: "Términos y condiciones de uso para usuarios de AprendoLibre.cl"
                }
            }
        ]
    },
    { path: "pronto", component: LoggedLayoutComponent, children: [{ path: "", component: PlaceholderPageComponent }] },
    {
        path: "preguntas_frecuentes",
        component: SharedLayoutComponent,
        children: [{ path: "", component: FAQComponent }]
    },
    { path: "acerca_de", component: AcercaDeComponent }
]

export const LandingRouting = RouterModule.forChild(LandingRoutes)

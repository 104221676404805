<div class="seccion" (window:resize)="onResize($event)">
    <loading-layout #loadingLayout [opaque]="true">
        <div>
            <cui-section-headline>
                {{
                    "evaluar.dynamic_tabs.seleccione"
                        | t: { evaluacionTipo: (evaluacionTipoAlias | transformInstrumento: "conArticuloIndefinido") }
                }}
            </cui-section-headline>
            <p class="tutorial" *ngIf="showTutorial">
                {{
                    "evaluar.dynamic_tabs.seleccione2"
                        | t
                            : {
                                  evaluacionTipo:
                                      (evaluacionTipoAlias | transformInstrumento: "conArticuloPlural" | capitalize)
                              }
                }}
                <br />
                {{ "evaluar.dynamic_tabs.seleccione3" | t }}
            </p>
            <div class="row">
                <!--     Tabs verticales      -->
                <div class="col-lg-3 col-md-4" *ngIf="!noTabs">
                    <ul class="vertical-tabs">
                        <li *ngIf="enableGenerador && asignaturaEvaluacionTipo?.with_items">
                            <a data-toggle="tab" #tabGenerar href="#tab_crear_instrumento" (click)="resetData()">
                                {{
                                    config.plataforma.textConPrueba?.generarInstrumento
                                        ? config.plataforma.textConPrueba.generarInstrumento
                                        : ("evaluar.dynamic_tabs.generar" | t)
                                }}
                            </a>
                        </li>
                        <li *ngIf="enableRecomendados && asignaturaEvaluacionTipo?.with_evaluaciones_recomendadas">
                            <a
                                data-toggle="tab"
                                #tabRecomendados
                                href="#tab_instrumento_recomendado"
                                (click)="resetData()"
                            >
                                {{
                                    config.plataforma.textConPrueba?.utilizarRecomendado
                                        ? config.plataforma.textConPrueba.utilizarRecomendado
                                        : ("evaluar.dynamic_tabs.utilizar" | t)
                                }}
                            </a>
                        </li>
                        <li *ngIf="enableBuscador && asignaturaEvaluacionTipo?.with_buscador_en_generador">
                            <a data-toggle="tab" #tabBuscador href="#tab_buscar_instrumento" (click)="resetData()">
                                {{ "evaluar.dynamic_tabs.buscar" | t }}
                            </a>
                        </li>
                        <li *ngIf="enableSubirPruebaPropia && asignaturaEvaluacionTipo?.with_pruebas_propias">
                            <a
                                data-toggle="tab"
                                #tabSubirPruebaPropia
                                href="#tab_subir_prueba_propia"
                                (click)="resetData()"
                            >
                                {{
                                    config.plataforma.textConPrueba?.subirPruebasPropias
                                        ? config.plataforma.textConPrueba.subirPruebasPropias
                                        : ("evaluar.dynamic_tabs.subir" | t)
                                }}
                            </a>
                        </li>
                        <li *ngIf="enableRepositorioPruebasPropias && asignaturaEvaluacionTipo?.with_pruebas_propias">
                            <a
                                data-toggle="tab"
                                #tabRepositorioPruebasPropias
                                href="#tab_repositorio_pruebas_propias"
                                (click)="resetData()"
                            >
                                {{
                                    config.plataforma.textConPrueba?.pruebasPropias
                                        ? config.plataforma.textConPrueba.pruebasPropias
                                        : ("evaluar.dynamic_tabs.mis_propias" | t)
                                }}
                            </a>
                        </li>
                    </ul>
                </div>
                <!--     Contenido Tabs verticales      -->
                <div [ngClass]="{ 'col-lg-9': !noTabs, 'col-md-8': !noTabs, 'col-md-12': noTabs }">
                    <div
                        [ngClass]="{ 'tab-content': !noTabs, 'vertical-tab-content': !noTabs }"
                        #externalFrameReference
                    >
                        <!--     Generar Instrumento      -->
                        <div
                            [ngClass]="{
                                'vertical-tabs-pane': !noTabs,
                                'tab-pane': !noTabs,
                                active: noTabs,
                                in: noTabs
                            }"
                            class="fade active in"
                            id="tab_crear_instrumento"
                            *ngIf="enableGenerador"
                        >
                            <generar-instrumento-profesor
                                [tipoInstrumento]="tipoEvaluacion"
                                [asignatura]="asignatura"
                                (onReadyInstrumentos)="cargarInstrumento($event)"
                                (onReadyInstrumento)="cargarInstrumentos($event)"
                            ></generar-instrumento-profesor>
                        </div>
                        <!--     Instrumento Recomendado      -->
                        <div
                            [ngClass]="{
                                'vertical-tabs-pane': !noTabs,
                                'tab-pane': !noTabs,
                                active: noTabs,
                                in: noTabs
                            }"
                            class="fade"
                            id="tab_instrumento_recomendado"
                            *ngIf="enableRecomendados"
                        >
                            <h4 class="h4_sub_title_1">
                                <fa name="arrow-right" class="highlight-chevron"></fa>
                                &nbsp;{{ "evaluar.dynamic_tabs.seleccione_recomendados" | t }}
                            </h4>
                            <recomendado-ensayo-profesor
                                [asignatura]="asignatura"
                                [evaluacionTipo]="tipoEvaluacion"
                                (onReadyInstrumento)="callbackRecomendado($event)"
                                (onLoadingInstrumento)="onExternalLoadingInstrumento()"
                            ></recomendado-ensayo-profesor>
                            <!--<p *ngIf="!showZonas" class="no-results">
								No hay contenido recomendado
							</p>-->
                        </div>
                        <!--     Buscar Instrumento      -->
                        <div
                            [ngClass]="{
                                'vertical-tabs-pane': !noTabs,
                                'tab-pane': !noTabs,
                                active: noTabs,
                                in: noTabs
                            }"
                            class="fade"
                            id="tab_buscar_instrumento"
                            *ngIf="enableBuscador"
                        >
                            <instrumentos-buscador (selected)="cargarInstrumento($event)"></instrumentos-buscador>
                        </div>
                        <!-- Subir Prueba Propia -->
                        <div
                            [ngClass]="{
                                'vertical-tabs-pane': !noTabs,
                                'tab-pane': !noTabs,
                                active: noTabs,
                                in: noTabs
                            }"
                            class="fade"
                            id="tab_subir_prueba_propia"
                            *ngIf="enableSubirPruebaPropia"
                        >
                            <subir-prueba-propia
                                [showCompartirMasTarde]="showCompartirMasTarde"
                                [evaluacionTipo]="tipoEvaluacion"
                            ></subir-prueba-propia>
                        </div>
                        <!-- Mis pruebas propias -->
                        <div
                            [ngClass]="{
                                'vertical-tabs-pane': !noTabs,
                                'tab-pane': !noTabs,
                                active: noTabs,
                                in: noTabs
                            }"
                            class="fade"
                            id="tab_repositorio_pruebas_propias"
                            *ngIf="enableRepositorioPruebasPropias"
                        >
                            <pruebas-propias
                                [asignaturaId]="asignaturaId"
                                [evaluacionTipo]="tipoEvaluacion"
                            ></pruebas-propias>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </loading-layout>
</div>

<div *ngIf="instrumento">
    <cui-section-headline id="instrumentoSeleccionado">
        {{ "evaluar.dynamic_tabs.compartir" | t }} {{ tipoEvaluacion | transformInstrumento: "singular" }}
        {{ "evaluar.dynamic_tabs.compartir2" | t }}
    </cui-section-headline>
    <p class="tutorial">
        {{ "evaluar.dynamic_tabs.terminar" | t }} {{ tipoEvaluacion | transformInstrumento: "conArticuloIndefinido"
        }}{{ "evaluar.dynamic_tabs.terminar2" | t }}
    </p>
    <div class="row">
        <div class="col-md-4 inverted-col">
            <ng-container *ngIf="instrumento && !instrumento.instrumento_material">
                <!-- Opciones costado derecho Instrumento -->
                <opciones-instrumento
                    [instrumento]="instrumento"
                    [tipoId]="1"
                    [tipoEvaluacion]="tipoEvaluacion"
                    [redirect]="rutaEvaluaciones"
                    [scrollAndFollow]="true"
                    [responsiveBreak]="991"
                    [topPadding]="20"
                    [bottomPadding]="20"
                    [followTopReference]="followReference"
                    [followBottomReference]="followReference"
                    [showButtonGuardar]="showButtonGuardar"
                ></opciones-instrumento>
            </ng-container>

            <ng-container *ngIf="instrumento && instrumento.instrumento_material">
                <div class="tab-content">
                    <ul class="nav nav-tabs">
                        <li class="active">
                            <a href="#tab1" role="tab" data-toggle="tab">{{ "evaluar.dynamic_tabs.tab_pauta" | t }}</a>
                        </li>
                        <li>
                            <a href="#tab2" role="tab" data-toggle="tab">
                                {{ "evaluar.dynamic_tabs.tab_compartir" | t }}
                            </a>
                        </li>
                    </ul>

                    <div id="tab1" class="active tab-pane clearfix" #tabContent>
                        <br />
                        <pauta-prueba-propia [instrumento]="instrumento"></pauta-prueba-propia>
                    </div>

                    <div id="tab2" class="tab-pane" #tabContent>
                        <opciones-instrumento
                            *ngIf="instrumento"
                            [instrumento]="instrumento"
                            [tipoId]="1"
                            [tipoEvaluacion]="tipoEvaluacion"
                            [redirect]="rutaEvaluaciones"
                            [scrollAndFollow]="true"
                            [responsiveBreak]="991"
                            [topPadding]="20"
                            [bottomPadding]="20"
                            [followTopReference]="followReference"
                            [followBottomReference]="followReference"
                            [showButtonGuardar]="showButtonGuardar"
                        ></opciones-instrumento>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-8" #followReference>
            <h3 class="titulo-instrumento">
                <div class="titulo-instrumento-wrap">
                    <span class="titulo-instrumento-icon"><ogr-icon [name]="'prueba'"></ogr-icon></span>
                    <span class="titulo-instrumento-text">
                        {{ "evaluar.dynamic_tabs.instrumento" | t }} #{{ instrumento.id }}
                        <span *ngIf="instrumentoAsignatura">- {{ instrumentoAsignatura.asignatura }}</span>
                    </span>
                </div>
            </h3>
            <instrumento
                [instrumento]="instrumento"
                (ready)="onInstrumentoReady()"
                (readyAsignatura)="onAsignaturaInstrumentoReady($event)"
                [enableReporte]="enableReporte"
                [enableOpcionesPregunta]="enableOpcionesPregunta"
            ></instrumento>
        </div>
    </div>
</div>

<ng-container *ngIf="instrumentos">
    <ul class="nav nav-tabs">
        <li *ngFor="let i of instrumentos" role="presentation" [ngClass]="{ active: selectedInstrumento == i }">
            <a data-toggle="tab" (click)="selectedInstrumento = i" href="#instrumento_{{ i.id }}">
                {{ i.generador_instrumento.generador_instrumento }}
            </a>
        </li>
    </ul>

    <div class="tab-content-instrumento" *ngFor="let i of instrumentos">
        <div id="instrumento_{{ i.id }}">
            <ng-container *ngIf="i == selectedInstrumento">
                <cui-section-headline id="instrumentoSeleccionado">
                    {{ "evaluar.dynamic_tabs.compartir" | t }} {{ tipoEvaluacion | transformInstrumento: "singular" }}
                    {{ "evaluar.dynamic_tabs.compartir2" | t }}
                </cui-section-headline>
                <p class="tutorial">
                    {{ "evaluar.dynamic_tabs.terminar" | t }}
                    {{ tipoEvaluacion | transformInstrumento: "conArticuloIndefinido"
                    }}{{ "evaluar.dynamic_tabs.terminar2" | t }}
                </p>
                <div class="row">
                    <div class="col-md-4 inverted-col">
                        <ng-container *ngIf="instrumentos && !i.instrumento_material">
                            <opciones-instrumento
                                [instrumento]="i"
                                [tipoId]="1"
                                [tipoEvaluacion]="tipoEvaluacion"
                                [scrollAndFollow]="true"
                                [responsiveBreak]="991"
                                [topPadding]="20"
                                [bottomPadding]="20"
                                [followTopReference]="followReference"
                                [followBottomReference]="followReference"
                                [showButtonGuardar]="showButtonGuardar"
                            ></opciones-instrumento>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="instrumentos && i.instrumento_material">
                        <div class="tab-content">
                            <ul class="nav nav-tabs">
                                <li class="active">
                                    <a href="#tab1" role="tab" data-toggle="tab">
                                        {{ "evaluar.dynamic_tabs.tab_pauta" | t }}
                                    </a>
                                </li>
                                <li>
                                    <a href="#tab2" role="tab" data-toggle="tab">
                                        {{ "evaluar.dynamic_tabs.tab_compartir" | t }}
                                    </a>
                                </li>
                            </ul>

                            <div id="tab1" class="active tab-pane clearfix" #tabContent>
                                <br />
                                <pauta-prueba-propia [instrumento]="instrumento"></pauta-prueba-propia>
                            </div>

                            <div id="tab2" class="tab-pane" #tabContent>
                                <opciones-instrumento
                                    *ngIf="instrumento"
                                    [instrumento]="instrumento"
                                    [tipoId]="1"
                                    [tipoEvaluacion]="tipoEvaluacion"
                                    [redirect]="rutaEvaluaciones"
                                    [scrollAndFollow]="true"
                                    [responsiveBreak]="991"
                                    [topPadding]="20"
                                    [bottomPadding]="20"
                                    [followTopReference]="followReference"
                                    [followBottomReference]="followReference"
                                    [showButtonGuardar]="showButtonGuardar"
                                ></opciones-instrumento>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-md-8" #followReference>
                        <h3 class="titulo-instrumento">
                            <div class="titulo-instrumento-wrap">
                                <span class="titulo-instrumento-icon"><ogr-icon [name]="'prueba'"></ogr-icon></span>
                                <span class="titulo-instrumento-text">
                                    {{ "evaluar.dynamic_tabs.instrumento" | t }} #{{ i.id }}
                                    <span *ngIf="instrumentoAsignatura">- {{ instrumentoAsignatura.asignatura }}</span>
                                </span>
                            </div>
                        </h3>
                        <instrumento
                            [instrumento]="i"
                            (ready)="onInstrumentoReady()"
                            (readyAsignatura)="onAsignaturaInstrumentoReady($event)"
                            [enableReporte]="enableReporte"
                        ></instrumento>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template ngFor let-establecimiento [ngForOf]="establecimientos">
    <div class="contenido" *ngIf="establecimiento.monitores.length > 0">
        <div class="menu_item_box">
            <div class="menu_item_box_wrapper">
                <div class="menu_item_row_wrapper">
                    <div class="menu_item_box_icon_container">
                        <div class="menu_item_box_icon">
                            <ogr-icon name="libro2-o" class="icono"></ogr-icon>
                        </div>
                    </div>
                    <div class="menu_item_box_body">
                        <h3 class="menu_item_box_title h3_sub_title_9c3">Monitor Establecimiento</h3>
                        <div class="menu_item_box_text">
                            {{ establecimiento.establecimiento }}
                        </div>
                        <div class="contenido_datos_monitor">
                            <div class="dato">
                                Nombre: {{ establecimiento.monitores[0].nombre }}
                                {{ establecimiento.monitores[0].apellido_paterno }}
                            </div>
                            <div class="dato">Email: {{ establecimiento.monitores[0].email }}</div>
                            <div class="dato">
                                Contacto: {{ establecimiento.monitores[0]["usuario_" + pais].telefono }}
                            </div>
                        </div>
                    </div>
                    <div class="contenido_avatar">
                        <div class="avatar user-circle-container pull-right">
                            <img
                                class="avatar"
                                *ngIf="establecimiento.monitores[0].avatar"
                                [src]="establecimiento.monitores[0].avatar | trustedurl"
                            />
                            <fa
                                *ngIf="!establecimiento.monitores[0].avatar"
                                class="fa-placeholder"
                                [name]="'user'"
                            ></fa>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- <div class="grid-container">
    <h3>Datos monitor</h3>
    <br />
    <div *ngFor="let establecimiento of establecimientos">
        <div *ngIf="establecimiento.monitores.length > 0">
            <div class="grid-item1">
                <p>
                    <b>Establecimiento:</b>
                    {{ establecimiento.establecimiento }}
                </p>
            </div>
            <div class="grid-item2">
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Email</th>
                            <th>Telefono</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let monitor of establecimiento.monitores">
                            <td>{{ monitor.nombre }} {{ monitor.apellido_paterno }} {{ monitor.apellido_materno }}</td>
                            <td>{{ monitor.email }}</td>
                            <td>{{ monitor["usuario_" + pais].telefono }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
 -->

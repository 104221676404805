<div class="showcasebox" [class.hollow-content]="!loadingLayout.loading">
    <div *ngIf="config.plataforma.showcaseBoxBlogWithTitle" class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container">
                    <ogr-icon name="dialogos" class="icon"></ogr-icon>
                </div>
            </div>
            <div class="showcasebox_title_text">Blog</div>
        </div>
    </div>
    <loading-layout #loadingLayout>
        <ng-container *ngIf="post">
            <div class="showcasebox-header">
                <img [src]="post.imagen_sm" class="" alt="{{ post.titulo }}" />
            </div>
            <div class="showcasebox-content">
                <p class="titulo">
                    <a [routerLink]="['/posts', post.slug]">{{ post.titulo }}</a>
                </p>
                <p class="resumen">
                    {{ post.resumen }}
                </p>
                <div class="btn-var">
                    <cui-button-link [routerLinkValue]="['/posts', post.slug]" type="button" class="btn_style">
                        Seguir leyendo
                    </cui-button-link>
                </div>
            </div>
        </ng-container>
        <p class="not-found" *ngIf="!post">{{ "orientacion.blog_showcasebox.not_found" | t }}</p>
    </loading-layout>
</div>

import { Component, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
/**
 * Componente que muestra los términos y condiciones de las plataformas de Chile (inicialmente PNcl y ALcl). 
 * Si llegan a variar los T&C para otras plataformas, considerar crear un componente dedicado para cada una.
 * 
 * @class TermsAndConditionsChileComponent
 **/
@Component({
    selector: "puntaje-terms-and-conditions-chile",
    templateUrl: "./terms-and-conditions-chile.component.html",
    styleUrls: ["./terms-and-conditions-chile.component.scss"]
})
export class TermsAndConditionsChileComponent implements OnInit {
    site: string
    platform: string
    name: string

    constructor() {
        this.site = config.plataforma.info.companySite
        this.platform = config.plataforma.info.companyName
        this.name = config.plataforma.name
    }

    ngOnInit(): void {}
}

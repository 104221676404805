import { Component, Input } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Footer, incluye información de la empresa.

#################################*/

@Component({
    templateUrl: "footer-aprendolibre-mexico.component.html",
    selector: "footer-aprendolibre-mexico",
    styleUrls: ["footer-aprendolibre-mexico.component.scss"]
})
export class FooterAprendolibreMexicoComponent {
    supportMail: string
    supportPhones: string[]
    config: typeof config
    pais: string = "mexico"
    profileName: string

    @Input() hidePhones: boolean = false

    constructor() {
        this.config = config
        this.supportMail = config.plataforma.info.soporte.mail
        this.supportPhones = config.plataforma.info.soporte.telefonos ? config.plataforma.info.soporte.telefonos : []
        this.profileName = config.app.name === "alumnos" ? "estudiantes" : "docentes"
    }

    trimSpaces(val: string) {
        return !val ? "" : val.replace(/ /g, "")
    }
}

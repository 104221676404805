import { ChangeDetectorRef, Component, EventEmitter, Injector, OnInit, Output, ViewChild } from "@angular/core"
import { FormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { Store } from "@ngrx/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Clasificacion, Clasificaciones, NebuAuthService, ReporteRazones } from "@puntaje/nebulosa/api-services"
import { AuthService, GenericModalComponent, SessionService } from "@puntaje/shared/core"
import {
    Establecimiento,
    Establecimientos,
    Lugar,
    Lugares,
    UsuarioRegistro,
    UsuarioRegistroForm,
    Usuarios,
    UsuarioRegistroAlClForm
} from "@puntaje/puntaje/api-services"
import { State } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { ModalSelectTipoEnvioComponent } from "../modales/modal-select-tipo-envio/modal-select-tipo-envio.component"
import { RegisterStepsService } from "../register-steps/register-steps.service"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { CarrerasAuthService } from "@puntaje/carreras/api-services"
import { BaseRegisterStepsComponent } from "../base-register-steps/base-register-steps.component"

@Component({
    selector: "usuario-registro-al-cl",
    templateUrl: "./register-aprendolibre-cl.component.html",
    styleUrls: ["../register.component.scss"]
})
export class RegisterAprendolibreClComponent extends BaseRegisterStepsComponent implements OnInit {
    usuarioRegistro: UsuarioRegistro = new UsuarioRegistro()
    usuarioRegistroForm: UsuarioRegistroAlClForm
    params: any = UsuarioRegistroAlClForm.formParams
    save_button_text = "Guardar"
    omitir = true // true mientras se acomoda lo de los msj
    selectEnvioConfirmacion = true //true mientras no funcione los msj
    egresado: Clasificacion
    telefono: string
    invalidLogin: boolean
    config: typeof config
    message: string

    openModal: EventEmitter<any> = new EventEmitter<any>()
    openModalRecuperar: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    @ViewChild(ModalSelectTipoEnvioComponent)
    modalSelectTipoEnvioComponent: ModalSelectTipoEnvioComponent

    @ViewChild("recuperarCuentaModal") recuperarCuentaModal: GenericModalComponent

    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()
    @Output() onUsuarioRegistrado: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected usuariosService: Usuarios,
        protected clasificacionesService: Clasificaciones,
        protected router: Router,
        protected injector: Injector,
        protected establecimientosService: Establecimientos,
        protected lugaresService: Lugares,
        protected cdr: ChangeDetectorRef,
        protected registerStepsService: RegisterStepsService,
        protected nebuAuthService: NebuAuthService,
        protected store: Store<State>,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected reporteRazonesService: ReporteRazones,
        protected simpleModalService: SimpleModalService,
        protected carrerasAuthService: CarrerasAuthService
    ) {
        super(usuariosService, router, null, establecimientosService, registerStepsService)
    }

    ngOnInit() {
        super.ngOnInit()

        this.form = UsuarioRegistroAlClForm.getForm(this.usuarioRegistro, null, this.injector)

        this.clasificacionesService
            .where({
                clasificacion_tipo: { clasificacion_tipo: "curso" },
                clasificacion: { clasificacion: "Egresado" }
            })
            .then((clasificaciones: Clasificacion[]) => {
                this.egresado = clasificaciones[0]
            })

        this.form.controls.rut.valueChanges.pipe(filter(x => !!x && x.length > 1)).subscribe((value: string) => {
            value = value.replace(/-/g, "")
            const almostLast = value.length - 1

            value = value.substring(0, almostLast) + "-" + value.substring(almostLast)

            this.form.controls.rut.setValue(value, { emitEvent: false })
        })

        this.stepValidations = {
            0: ["rut", "nombre", "apellido_paterno", "lugar"],
            1: ["email", "telefono", "password", "password_confirmation"],
            2: ["egresado", "terminos_y_condiciones"]
        }
    }

    public clear() {
        setTimeout(() => {
            this.usuarioRegistro = new UsuarioRegistro()
            UsuarioRegistroAlClForm.markFormControlsAsPristine(this.form)
            UsuarioRegistroAlClForm.markFormControlsAsUntouched(this.form)
        }, 150)
    }

    back() {
        this.router.navigate([""])
    }

    validarSerialCarnet() {
        this.openModalRecuperar.next()
    }

    onValidation(event) {
        if (event.status) {
            this.form.controls.serial.setValue(event.serial)
            this.form.controls.rut.setValue(event.rut)
            this.recuperarCuentaModal.close()
        }
    }

    getTipoEnvio() {
        UsuarioRegistroForm.markFormControlsAsTouched(this.form)
        this.checkStep()
        this.usuarioRegistro.lugar = this.lugar
        if (this.form.valid) {
            this.onUsuarioRegistrado.emit()
            this.modalSelectTipoEnvioComponent.open()
        }
    }

    goOmitir() {
        this.genericModal.close()
        this.selectEnvioConfirmacion = true
        this.omitir = true
        this.nextStep()
    }

    nextStep() {
        this.checkStep()
        if (this.omitir) {
            this.registerStepsService.goToStepIfValid(this.currentStep + 1)
        } else {
            if (this.currentStep == 1) {
                this.isTelefono()
            } else {
                this.registerStepsService.goToStepIfValid(this.currentStep + 1)
            }
        }
    }

    beforeGetEstablecimientos() {
        let lugares = []
        if (this.lugarColegio) lugares = [this.lugarColegio.id]
    }

    whereGetEstablecimientos(establecimientoFilter) {
        return {
            per: 100,
            raw: 1,
            establecimiento: { establecimiento: establecimientoFilter, lugar_id: this.lugarColegio.id },
            methods: "rbd"
        }
    }

    isTelefono() {
        this.telefono = this.form.value.telefono
        if (this.form.value.telefono == "") {
            this.openModal.next()
        } else {
            this.omitir = true
            this.selectEnvioConfirmacion = true
            this.nextStep()
        }
    }

    setMessage() {
        this.message = "Logged " + (this.authService.isLoggedIn() ? "in" : "out")
    }
}

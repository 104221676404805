import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"

import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Menú arriba del sitio.

#################################*/

@Component({
    templateUrl: "topmenu.component.html",
    selector: "topmenu",
    styleUrls: ["topmenu.component.scss"]
})
export class TopmenuComponent extends MenuComponent implements OnInit, OnDestroy {
    @Output() toggleTopmenuEvent = new EventEmitter()
    @ViewChild("topmenu") insideElement
    @HostListener("document:click", ["$event.target"]) public onClick(targetElement) {
        const clickedInside = this.insideElement.nativeElement.contains(targetElement)
        if (this.catchOutsideClick && !clickedInside) {
            this.toggleTopmenuEvent.emit()
        }
    }
    catchOutsideClick: boolean = false
    asignaturasByEvaluacionTipo: any
    @Input() config: any
    asignaturaId: number = 1
    planClasesRoute = config?.plataforma?.planClasesRoute || "plan_clases"
    itemsMenu: any[]
    ITEMS_MENU = [
        {
            name: "Planes de clase",
            route: "/plan_estudios",
            params: {
                tipo_instrumento: "curricular",
                asignatura_id: this.asignaturaId
            },
            icon: "planificacion-o",
            active: true
        },
        {
            name: "Evaluar",
            route: "/pruebas/generar_prueba/1",
            icon: "editar-prueba",
            active: true
        },
        {
            name: "Corregir",
            route: "/evaluacion_instancias/subir",
            icon: "upload",
            active: true
        },
        {
            name: "Resultados",
            route: "/pruebas",
            icon: "resultados",
            active: !this.sessionService.isFreeUser() || this.sessionService.getEstablecimientos().length > 0
        },

        {
            name: "Biblioteca",
            route: "/bibliotecas",
            icon: "libro2-o",
            active: true
        },
        {
            name: "Mis comunicaciones",
            route: "/noticias",
            icon: "megafono-o",
            active: true
        },
        {
            name: "Mis cursos",
            route: "/grupo_usuarios",
            icon: "comunidad-o",
            active: this.authService.getEstablecimientos().length == 0
        },
        {
            name: "Administrar",
            route: "/establecimientos",
            icon: "establecimiento-o",
            active: this.authService.getEstablecimientos().length != 0
        }
    ]

    constructor(store: Store<State>, public authService: AuthService, protected sessionService: SessionService) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.asignaturaId = asignaturasByEvaluacionTipo["curricular"][0].id
            this.asignaturasByEvaluacionTipo = asignaturasByEvaluacionTipo
        })
        setTimeout(() => {
            this.catchOutsideClick = true
        }, 300)

        this.itemsMenu = this.ITEMS_MENU
        const isFreeUser = this.sessionService.isFreeUser()
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }

    onMenuClickOutside() {
        this.toggleTopmenuEvent.emit()
    }

    close() {
        this.toggleTopmenuEvent.emit()
    }
}

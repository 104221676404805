import { Injectable } from "@angular/core"
import Polyglot from "node-polyglot"
import { AppConfig } from "../conf/app_config"

import { es } from "./locales/es"
import { esCl } from "./locales/es-cl"
import { esCO } from "./locales/es-co"
import { esPNCO } from "./locales/es-pn-co"
import { esMX } from "./locales/es-mx"
import { esGEN } from "./locales/es-gen"
import { pt } from "./locales/pt"
import { esPNCL } from "./locales/es-pn-cl"
import { esALCL } from "./locales/es-alcl"
import { esAbily } from "./locales/es-abily"
import { esAprendemas } from "./locales/es-aprendemas"
import { esUA } from "./locales/es-ua"
import { esZero } from "./locales/es-zero"

@Injectable()
export class I18nService {
    private polyglot = null

    private dictionaries = {
        es,
        pt,
        "es-cl": esCl,
        "es-co": esCO,
        "es-mx": esMX,
        "es-gen": esGEN, //sin código de país
        "es-pn-co": esPNCO,
        "es-pn-cl": esPNCL,
        "es-alcl": esALCL,
        "es-abily": esAbily,
        "es-aprendemas": esAprendemas,
        "es-ua": esUA,
        "es-zero": esZero
    }

    private dictionariesDependencies = {
        "es-pn-co": "es-co",
        "es-cl": "es",
        "es-co": "es",
        "es-mx": "es",
        "es-gen": "es",
        "es-pn-cl": "es",
        "es-alcl": "es",
        "es-abily": "es",
        "es-aprendemas": "es",
        "es-ua": "es",
        "es-zero": "es"
    }

    constructor(protected config: AppConfig) {
        const locale = config.plataforma?.locale || "es-cl"
        const phrases = this.phrasesByLocale(locale)

        this.polyglot = new Polyglot({ locale, phrases })
    }

    translate(phrase, options = {}) {
        return this.polyglot.t(phrase, options)
    }

    private phrasesByLocale(locale: string) {
        const localeDependencies = this.arrayDependencies(locale).reverse()
        const phrasesDependencies = localeDependencies.map(localeD => this.dictionaries[localeD])
        return this.deepMerge(...phrasesDependencies)
    }

    private arrayDependencies(locale: string) {
        const localeDependency = this.dictionariesDependencies[locale]
        if (localeDependency) {
            return [locale].concat(this.arrayDependencies(localeDependency))
        } else {
            return [locale]
        }
    }

    private deepMerge(...sources) {
        let acc = {}
        for (const source of sources) {
            if (source instanceof Array) {
                if (!(acc instanceof Array)) {
                    acc = []
                }
                acc = { ...acc, ...source }
            } else if (source instanceof Object) {
                for (let [key, value] of Object.entries(source)) {
                    if (value instanceof Object && key in acc) {
                        value = this.deepMerge(acc[key], value)
                    }
                    acc = { ...acc, [key]: value }
                }
            }
        }
        return acc
    }
}

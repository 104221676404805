import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core"
import { Router } from "@angular/router"
import { Establecimientos, Lugares, Usuarios } from "@puntaje/puntaje/api-services"
import { Subscription } from "rxjs"
import { RegisterStepsService, RegisterStepStatus } from "../register-steps/register-steps.service"
import { BaseRegisterComponent } from "../base-register/base-register.component"
import { FormControl, UntypedFormGroup } from "@angular/forms"

@Component({
    selector: "base-register-steps",
    templateUrl: "./base-register-steps.component.html",
    styleUrls: ["./base-register-steps.component.scss"]
})
export class BaseRegisterStepsComponent extends BaseRegisterComponent implements OnInit, OnDestroy {
    steps: any[]
    currentStep: number
    subSteps: Subscription
    subIndex: Subscription
    subValidateAndGo: Subscription
    omitir = true // true mientras se acomoda lo de los msj
    stepValidations: { [key: number]: any[] } = {}

    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()
    @Output() onUsuarioRegistrado: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        protected usuariosService: Usuarios,
        protected router: Router,
        protected lugaresService: Lugares,
        protected establecimientosService: Establecimientos,
        protected registerStepsService: RegisterStepsService
    ) {
        super(usuariosService, router, lugaresService, establecimientosService)
    }

    ngOnInit() {
        this.steps = this.registerStepsService.steps

        this.currentStep = this.registerStepsService.currentIndex
        this.onTitleChange.next(this.steps[this.currentStep].titulo)
        this.subIndex = this.registerStepsService.currentIndexSubject.subscribe((index: number) => {
            this.currentStep = index
            this.onTitleChange.next(this.steps[this.currentStep].titulo)
        })
        this.subSteps = this.registerStepsService.currentStepsSubject.subscribe((steps: any) => {
            this.steps = steps
        })
        this.subValidateAndGo = this.registerStepsService.validateAndGoToStepSubject.subscribe((index: number) => {
            this.checkStep()
            this.registerStepsService.goToStep(index)
        })

        super.ngOnInit()
    }

    nextStep() {
        this.checkStep()
        if (this.omitir) {
            this.registerStepsService.goToStepIfValid(this.currentStep + 1)
        } else {
            this.registerStepsService.goToStepIfValid(this.currentStep + 1)
        }
    }

    previousStep() {
        this.registerStepsService.goToStep(this.currentStep - 1)
    }

    ngOnDestroy() {
        this.subIndex.unsubscribe()
        this.subSteps.unsubscribe()
    }

    checkStep() {
        this.registerStepsService.updateStepStatus(
            this.checkControls(this.stepValidations[this.currentStep])
                ? RegisterStepStatus.Error
                : RegisterStepStatus.Ready,
            this.currentStep
        )
    }

    checkControls(controls: string[]) {
        return controls.reduce((acc, c) => {
            let control
            if (typeof c === "string") {
                control = this.form.controls[c]
            } else if (Array.isArray(c)) {
                // Caso para cuando hay forms de países métidos dentro de otro formGroup
                control = (this.form.controls[c[0]] as UntypedFormGroup).controls[c[1]]
            } else {
                return false
            }

            control.markAsTouched()

            return acc || control.status == "INVALID" || (control.global && !!this.form.errors)
        }, false)
    }
}

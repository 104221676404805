import { Component, Input, OnInit } from "@angular/core"

@Component({
    selector: "faq-base",
    templateUrl: "./faq-base.component.html",
    styleUrls: ["./faq-base.component.scss"]
})
export class FAQBaseComponent implements OnInit {
    constructor() {}
    @Input() nombreDocente?: string = "maestro"
    @Input() nombreDocentePlural?: string = "maestros"
    @Input() incomingFaqs?: any = []
    faqs: any

    ngOnInit() {
        if (this.incomingFaqs && this.incomingFaqs.length > 0) {
            this.faqs = this.incomingFaqs
        } else {
            this.setFaqs()
        }
    }

    setFaqs() {
        this.faqs = [
            {
                pregunta: "<p>¿Qué es Aprendo Libre?</p>",
                respuesta:
                    "<p>Aprendo Libre es una Plataforma de apoyo académico para estudiantes y " +
                    this.nombreDocentePlural +
                    ". En tan sólo un par de clics encontrarás material educativo audiovisual y descargable para apoyar cualquiera de las asignaturas disponibles.</p><p>¡Si eres " +
                    this.nombreDocente +
                    ", podrás incluso subir tu propio material, compartirlo con tus estudiantes y recibir estadísticas sobre su desempeño!</p>"
            },
            {
                pregunta: "<p>¿Por qué usar Aprendo Libre?</p>",
                respuesta:
                    "<p>Porque es una herramienta fácil y amigable de usar, brindando miles de oportunidades de aprendizaje, integrando el desarrollo de contenidos, habilidades y evaluaciones.</p>"
            },
            {
                pregunta: "<p>¿Quiénes pueden utilizar Aprendo Libre?</p>",
                respuesta:
                    "<p>La plataforma cuenta con una versión estudiante gratuita y otra orientadas a las necesidades de los establecimientos educacionales (involucra estudiantes y " +
                    this.nombreDocentePlural +
                    ").</p>"
            },
            {
                pregunta: "<p>¿Pertenecerá el colegio a quienes proveen la Plataforma Aprendo Libre?</p>",
                respuesta:
                    "<p>No. El Colegio utiliza Aprendo Libre como una plataforma fortalecedora de su propio proyecto educativo institucional. Lo integra para reforzar, mejorar y facilitar el aprendizaje en sus estudiantes, así como generar evaluaciones que permitan obtener una ruta de aprendizaje personalizada para cada estudiante.</p>"
            },
            {
                pregunta:
                    "<p>¿El colegio cambia su filosofía o modelo educativo al implementar la Plataforma Aprendo Libre?</p>",
                respuesta:
                    "<p>No. El Colegio NO cambia su filosofía, ni sus valores, ni su esencia, sólo es una herramienta adicional de valor. La Plataforma educativa pretende fortalecer el proceso de planeación y enseñanza antes y durante el aula, es adaptable al modelo educativo actual del Colegio.</p>"
            },
            {
                pregunta: "<p>¿Por qué el Colegio debería integrar una Plataforma de Evaluación Educativa?</p>",
                respuesta:
                    "<p>Los ambientes de aprendizaje para las nuevas generaciones necesitan una actualización metodológica y pedagógica adecuada a las competencias que un ciudadano del siglo XXI requiere. La dirección del Colegio está convencida que la tecnología ya es parte de nuestras vidas y al ofrecer una educación integral, se preocupa y ocupa de que sus alumnos aprendan a utilizarla -dándole el uso correcto, aprendan a manejarla -y no que la tecnología los maneje a ellos; teniendo así una cultura de buenas prácticas como ciudadanos digitales competentes.</p>"
            },
            {
                pregunta:
                    "<p>¿Estará toda la mañana el alumno trabajando en un dispositivo para utilizar Aprendo Libre?</p>",
                respuesta:
                    "<p>No. El dispositivo se utiliza como una herramienta de apoyo y no pretende sustituir la labor del Docente, por lo que el Docente adecuará y planificará los tiempos de uso de la plataforma.</p>"
            },
            {
                pregunta: "<p>¿Qué necesito para poder utilizar la Plataforma?</p>",
                respuesta:
                    "<p>Sólo se requiere un dispositivo con conexión a internet. La Plataforma está optimizada para ser utilizada en PC, tablets y/o celulares.</p>"
            },
            {
                pregunta:
                    "<p>¿Qué requiere el Colegio como infraestructura requerida para implementar Aprendo Libre?</p>",
                respuesta: "<p>Cualquier dispositivo electrónico o móvil con conexión a internet.</p>"
            },
            {
                pregunta:
                    "<p>¿En qué se diferencia Aprendo Libre de los otras Plataformas Educativas utilizadas por establecimientos educacionales?</p>",
                respuesta:
                    "<p>En que es una Plataforma Integral en línea que se centra en la creación de Evaluaciones Educativas, apoyando a " +
                    this.nombreDocentePlural +
                    " y Estudiantes, con estadísticas de desempeño, planes de clases y biblioteca con material audiovisual, generador de evaluaciones de las áreas de español, matemáticas y ciencias.</p>"
            },
            {
                pregunta:
                    "<p>¿Si soy estudiante y mi Institución Educativa no utiliza la plataforma, puedo registrarme?</p>",
                respuesta:
                    "<p>Sí, desarrollamos un usuario estudiante gratuito donde podrás apoyar tu aprendizaje con miles de clases, material audiovisual y planes de estudio.</p>"
            },
            {
                pregunta:
                    "<p>¿Si soy " +
                    this.nombreDocente +
                    " y quiero que mi Institución Educativa contrate la plataforma, cómo lo hago?</p>",
                respuesta: "<p>Simplemente llene el formulario y nos pondremos en contacto lo antes posible.</p>"
            },
            {
                pregunta: "<p>¿Qué pasa si tengo problemas con mi cuenta?</p>",
                respuesta:
                    "<p>Si no puede acceder con el usuario y contraseña facilitados, tendrá que ponerse en contacto con nosotros para que podamos resolver la incidencia a través de la opción ¿Olvidaste tu contraseña?.</p>"
            }
        ]
    }
}

import { Component, OnInit } from "@angular/core"

@Component({
    selector: "acerca-de",
    templateUrl: "./acerca-de.component.html"
})
export class AcercaDeComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}

import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { GrupoUsuariosModule } from "@puntaje/puntaje/new-modules/grupo-usuarios"

import { grupoUsuariosRouting } from "./profesores_grupo_usuarios.routing"
import { PuntajeGrupoUsuariosModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProfesoresLayoutsModule,
        GrupoUsuariosModule,
        grupoUsuariosRouting,
        PuntajeGrupoUsuariosModule
    ],
    declarations: [],
    exports: [],
    providers: []
})
export class ProfesoresGrupoUsuariosModule {}

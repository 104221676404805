import { Component, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription, combineLatest } from "rxjs"

import { Asignatura } from "@puntaje/nebulosa/api-services"

import { Store, select } from "@ngrx/store"
import {
    State,
    SetSelectedAsignaturaWithId,
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    selectEvaluacionTipoAliases
} from "@puntaje/puntaje/store"
import { AuthService } from "@puntaje/shared/core"
import { filter, map } from "rxjs/operators"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "generar_ensayo_profesor.component.html"
})
export class GenerarEnsayoProfesorComponent implements OnInit, OnDestroy {
    asignaturaId: number
    tipoInstrumento: string
    asignatura: Asignatura
    sub: Subscription
    subQuery: Subscription
    subData: Subscription
    displayGenerarProfesor: boolean = false
    defaultTab: string = "generar"

    enableGenerar = true
    enableSiteWarning = true
    enableReporte = true
    showTitleOverMenu = true
    showTutorial = true
    showButtonGuardar = true
    showCompartirMasTarde = true
    evaluacionTipos: string[] = []
    evaluacionTipoAlias: string

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        protected store: Store<State>,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.ensayos.generar_ensayo_profesor")
        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$, this.evaluacionTipoAliases$).subscribe(
            ([asignatura, evaluacionTipo, evaluacionTipoAliases]) => {
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo
                this.evaluacionTipoAlias = evaluacionTipoAliases[evaluacionTipo]
                this.shouldShow()
                this.cdr.detectChanges()
            }
        )

        this.subData = this.route.data.subscribe(data => {
            this.enableGenerar = data.enableGenerar ?? this.enableGenerar
            this.enableReporte = data.enableReporte ?? this.enableReporte
            this.showTitleOverMenu = data.showTitleOverMenu ?? this.showTitleOverMenu
            this.showTutorial = data.showTutorial ?? this.showTutorial
            this.evaluacionTipos = data.evaluacionTipos ?? this.evaluacionTipos
            this.showButtonGuardar = data.showButtonGuardar ?? this.showButtonGuardar
            this.showCompartirMasTarde = data.showCompartirMasTarde ?? this.showCompartirMasTarde
            this.enableSiteWarning = data.enableSiteWarning ?? this.enableSiteWarning
        })

        this.shouldShow()
        this.cdr.detectChanges()
    }

    onReady(asignatura) {
        this.asignatura = asignatura
        this.cdr.detectChanges()
    }

    ngOnDestroy() {
        if (this.subQuery) this.subQuery.unsubscribe()
    }

    private shouldShow() {
        this.displayGenerarProfesor = this.showGeneradorProfesor()
    }

    private showGeneradorProfesor() {
        return !!this.asignaturaId && !!this.tipoInstrumento
    }
}

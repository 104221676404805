<div class="mascara" id="topPage">
    <section id="section1" class="caja">
        <div class="caja-inner-margin">
            <div class="logo-landing">
                <div class="logo-wrap">
                    <a routerLink="/">
                        <img
                            class="logo"
                            src="{{ config.app.assets.logoLarge }}"
                            alt="{{ config.plataforma.info.companyName }}"
                            title="{{ config.plataforma.info.companyName }}"
                        />
                    </a>
                </div>
            </div>
            <h1 class="titulo principal">
                <ng-content select="titulo"></ng-content>
            </h1>
            <div class="descripcion_acerca_de">
                <ng-content select="descripcion"></ng-content>
            </div>
        </div>
    </section>
</div>
<footer-aprendolibre [pais]="pais"></footer-aprendolibre>

import {
    Component,
    OnInit,
    ViewChild,
    Input,
    SimpleChanges,
    ChangeDetectorRef,
    EventEmitter,
    Output,
    OnChanges
} from "@angular/core"
import { LoadingLayoutComponent, SimpleModalService } from "@puntaje/shared/layouts"
import { PaginatorComponent, GenericModalComponent } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    EvaluacionInstancia,
    EvaluacionInstancias,
    EvaluacionTiempo,
    EvaluacionTiempos,
    Usuario,
    Usuarios,
    GrupoUsuario,
    Excels,
    PlanPersonal,
    PlanPersonales
} from "@puntaje/puntaje/api-services"
import { FiltroEstadisticasService } from "../filtro_estadisticas.service"
import { select, Store } from "@ngrx/store"
import {
    selectAsignaturasByEvaluacionTipo,
    selectAsignaturasList,
    selectSelectedAsignatura,
    State
} from "@puntaje/puntaje/store"
import { filter, first } from "rxjs/operators"
import { Asignatura, Asignaturas, AsignaturaWithConfig } from "@puntaje/nebulosa/api-services"

@Component({
    selector: "tabla-resumen-evaluacion-por-alumno",
    templateUrl: "tabla-resumen-evaluacion-por-alumno.component.html",
    styleUrls: [
        "tabla-resumen-evaluacion-por-alumno.component.scss",
        "../tabla-desempeno-por-usuario-clasificacion/estadisticas_tablas_graficos.component.scss"
    ]
})
export class TablaResumenEvaluacionPorAlumnoComponent implements OnInit, OnChanges {
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    @Input() evaluacion: any
    @Input() evaluacionInstancias: EvaluacionInstancia[] // solo oficiales
    @Input() allEvaluacionInstancias: EvaluacionInstancia[] = []
    @Input() previousEvaluacionInstancias: EvaluacionInstancia[]
    @Input() evaluacionTiempos: EvaluacionTiempo[]
    @Input() grupoUsuario: GrupoUsuario
    @Input() estadisticas: any[]
    @Input() hideEvolucion: boolean = false
    @Input() actualizarFiltro = true
    @Output() actualizarData: EventEmitter<any> = new EventEmitter<any>()
    @Input() disableSelectOficial: boolean = false
    @Input() disableActions = false
    @Input() hideEstadisticas = false
    @Input() enableColegio = false

    cantidadUsuarios: number
    @Input() usuarios: Usuario[]
    order: number
    key: string
    promedio: number
    totOmitidas: number
    totCorrectas: number
    totIncorrectas: number
    notaMin: number
    notaMax: number
    notaProm: number
    minimo: number
    maximo: number
    showModal: boolean = false
    hideReforzamientos: boolean = config.plataforma.hideReforzamientos || false
    enableNotaSobreSiete: boolean

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent, { static: false }) paginator: PaginatorComponent

    data: any = []
    dataFull: any = []
    currentStatus: number = 0
    estadoReforzamientoPorEvaluacion: any = {}
    reforzamientosByEvaluacionId: any = {}
    datoInstanciaModal: any
    idEvaluacionInstaciaEdit: number
    loadingEvaluacionInstancia: boolean = false
    hasForma: boolean = false

    usuariosTabla: any = []
    dataRespaldo: any = []
    totalRespaldo: any
    usaronFiltro: boolean = false
    paramsSearch: any = {}
    restablecerDatos: boolean = false

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x),
        first()
    )

    asignaturas: Asignatura[]

    constructor(
        protected usuariosService: Usuarios,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected simpleModalService: SimpleModalService,
        protected excelService: Excels,
        protected cdr: ChangeDetectorRef,
        protected planPersonalesService: PlanPersonales,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected filtroEstadisticasService: FiltroEstadisticasService,
        protected store: Store<State>,
        protected asignaturasService: Asignaturas
    ) {}

    async ngOnInit() {
        this.enableNotaSobreSiete = ["simce", "ensayo", "prueba de transición", "paes"].includes(
            this.evaluacion.evaluacion_tipo.evaluacion_tipo
        )

        this.getReforzamientos()

        const asignatura = await this.asignatura$.toPromise()
        this.hideReforzamientos =
            !asignatura.confByEvaluacionTipo[this.evaluacion.evaluacion_tipo.evaluacion_tipo].with_reforzamientos ||
            this.evaluacion.instrumento.propio

        this.loadingLayout.ready()
    }

    asignaturasIds() {
        let asignaturasIds = this.evaluacionInstancias
            .map(ei => ei.evaluacion_instancia_asignaturas.map(eia => eia.asignatura_id))
            .flat()
        asignaturasIds = Array.from(new Set(asignaturasIds))

        return asignaturasIds
    }

    async getAsignaturas() {
        const asignaturasByEvaluacionTipo = await this.store
            .pipe(
                select(selectAsignaturasByEvaluacionTipo),
                filter(x => !!x),
                first()
            )
            .toPromise()

        let asignaturasIds = (this.allEvaluacionInstancias ? this.allEvaluacionInstancias : this.evaluacionInstancias)
            .map(ei => ei.evaluacion_instancia_asignaturas?.map(eia => eia.asignatura_id))
            .flat()
        asignaturasIds = Array.from(new Set(asignaturasIds))

        const asignaturasNebu = await this.asignaturasService.where({ asignatura: { id: asignaturasIds } })

        const asignaturaPlataformas = asignaturasByEvaluacionTipo[this.evaluacion.evaluacion_tipo.evaluacion_tipo]
        this.asignaturas = asignaturasNebu.map(asignatura => {
            const asignaturaPlataforma =
                asignaturaPlataformas && asignaturaPlataformas.length > 0
                    ? asignaturaPlataformas.find(a => a.id == asignatura.id)
                    : null

            return {
                id: asignatura.id,
                asignatura: asignaturaPlataforma ? asignaturaPlataforma.asignaturaPlataforma : asignatura.asignatura
            } as Asignatura
        })
    }

    getIdentificador(usuario: Usuario) {
        if (usuario.usuario_chile) {
            return usuario.usuario_chile.rut || "-"
        }

        if (usuario.usuario_colombia) {
            return usuario.usuario_colombia.ti || usuario.usuario_colombia.cc || "-"
        }

        if (usuario.usuario_mexico) {
            return usuario.usuario_mexico.numero_unico || "-"
        }

        return "-"
    }

    setData(page: number, per: number, order: number = null, key: string = null) {
        if (!order && this.order) {
            order = this.order
            key = this.key
        } else if (order) {
            this.order = order
            this.key = key
            this.paginator.changePage(1)
        }

        this.currentStatus = this.evaluacionInstancias.length
        // TODO: esto hace mucho, quizás debería venir más ordenado desde la API
        const organizeData = (resolve, total, slice: boolean = false) => {
            this.hasForma = false
            this.usuariosTabla = this.usuarios

            if (this.usaronFiltro) {
                if (!this.restablecerDatos) {
                    if (this.paramsSearch.usuariosFiltrados && this.paramsSearch.usuariosFiltrados.length > 0) {
                        this.usuariosTabla = this.paramsSearch.usuariosFiltrados

                        if (this.paramsSearch.conRespuestas != null) {
                            if (this.paramsSearch.conRespuestas) {
                                this.usuariosTabla = this.usuariosTabla.filter(usuario => {
                                    return !!this.evaluacionInstancias.find(ei => ei.usuario_id == usuario.id)
                                })
                            } else {
                                this.usuariosTabla = this.usuariosTabla.filter(usuario => {
                                    return !this.evaluacionInstancias.find(ei => ei.usuario_id == usuario.id)
                                })
                            }
                        }

                        total = this.usuariosTabla.length
                    }
                } else {
                    this.usuariosTabla = this.dataRespaldo
                    // this.usuariosFiltrados = []
                    this.restablecerDatos = false
                    total = this.usuariosTabla.length
                }
            } else {
                //primer recorrido respalda
                this.dataRespaldo = this.usuariosTabla
                total = this.dataRespaldo.length
            }

            this.cantidadUsuarios = total
            const grupoUsuarios = this.grupoUsuarios()

            this.data = this.usuariosTabla.map(u => {
                const data: any = {}

                const [grupoUsuario, a] = this.grupoUsuarioAlumno(u, grupoUsuarios)

                this.setDataGeneral(data, a, grupoUsuario, u)

                const evaluacionInstancia = this.evaluacionInstancias.find(ei => ei.usuario_id == u.id)
                const previousEvaluacionInstancia =
                    this.previousEvaluacionInstancias &&
                    this.previousEvaluacionInstancias.find(ei => ei.usuario_id == u.id)

                const evaluacionTiempo = this.evaluacionTiempos.find(et => et.usuario_id == u.id)
                if (evaluacionTiempo) {
                    data.evaluacion_tiempo_id = evaluacionTiempo.id
                    data.tiempo = ~~(
                        (new Date().getTime() - new Date(evaluacionTiempo.created_at).getTime()) /
                        (1000 * 60)
                    )
                }

                if (evaluacionInstancia) {
                    this.setDataEvaluacion(data, evaluacionInstancia, u, a)
                }

                if (previousEvaluacionInstancia && evaluacionInstancia) {
                    data.calificacion_anterior = previousEvaluacionInstancia.calificacion
                    data.evolucion = 100 * (data.puntaje / data.calificacion_anterior - 1)
                    data.comparacion_curso = 100 * (data.puntaje / this.estadisticas[0].promedio - 1)
                }

                return data
            })

            if (order) {
                if (key == "puntaje") {
                    if (order == 1) {
                        this.data = this.data.sort((a: any, b: any) => (a[this.key] || 0) - (b[this.key] || 0))
                    } else if (order == -1) {
                        this.data = this.data.sort((a: any, b: any) => (b[this.key] || 0) - (a[this.key] || 0))
                    }
                } else {
                    if (order == 1) {
                        this.data = this.data.sort((a: any, b: any) =>
                            a[this.key].toLowerCase() > b[this.key].toLowerCase() ? 1 : -1
                        )
                    } else if (order == -1) {
                        this.data = this.data.sort((a: any, b: any) =>
                            a[this.key].toLowerCase() > b[this.key].toLowerCase() ? -1 : 1
                        )
                    }
                }
            }

            if (slice) {
                this.data = this.data.slice((page - 1) * per, page * per)
            }

            this.cdr.detectChanges()

            resolve(total)

            this.setPromedio()
        }

        const promise = new Promise<number>((resolve, reject) => {
            const cantidadUsuarios = this.usuarios.length
            organizeData(resolve, cantidadUsuarios, true)
        })

        return promise

        /*this.data = [
        {colegio: "Colegio Puntajenacional.cl", curso:"Cuarto Medio A", rut:"88888888-8",nombre:"Lorem Ipsum",correctas:"40", incorrectas:"20", omitidas:"20", puntaje:"600", tiempo:"00:00:20", fecha:"01-01-17"},
        {colegio: "Colegio Puntajenacional.cl", curso:"Cuarto Medio A", rut:"88888888-8",nombre:"Lorem Ipsum",correctas:"40", incorrectas:"20", omitidas:"20", puntaje:"600", tiempo:"00:00:20", fecha:"01-01-17"},
        {colegio: "Colegio Puntajenacional.cl", curso:"Cuarto Medio A", rut:"88888888-8",nombre:"Lorem Ipsum",correctas:"40", incorrectas:"20", omitidas:"20", puntaje:"600", tiempo:"00:00:20", fecha:"01-01-17"},
        {colegio: "Colegio Puntajenacional.cl", curso:"Cuarto Medio A", rut:"88888888-8",nombre:"Lorem Ipsum"},
        {colegio: "Colegio Puntajenacional.cl", curso:"Cuarto Medio A", rut:"88888888-8",nombre:"Lorem Ipsum"}];*/
    }

    actualizarTabla(paramsSearch) {
        this.paramsSearch = paramsSearch
        this.usaronFiltro = true
        this.paginator.changePage(1)
    }

    restablecerDatosTabla(restablecer: boolean) {
        this.restablecerDatos = restablecer
        this.usaronFiltro = restablecer
        this.paginator.changePage(1)
    }

    grupoUsuarios() {
        let grupoUsuarios
        grupoUsuarios = this.evaluacion.evaluacion_usuarios
        grupoUsuarios = grupoUsuarios.filter(eu => eu.grupo_usuario).map(eu => eu.grupo_usuario)

        return grupoUsuarios
    }

    grupoUsuarioAlumno(u, grupoUsuarios) {
        let grupoUsuario = u.grupo_usuarios.find(gu => !!grupoUsuarios.find(g => gu.id == g.id))
        let a
        if (!grupoUsuario) {
            grupoUsuario = u.grupo_usuarios[0]
            a = grupoUsuario
        } else {
            a = grupoUsuarios.find(g => g.id == grupoUsuario.id)
        }

        return [grupoUsuario, a]
    }

    setDataGeneral(data, a, grupoUsuario, usuario) {
        this.infoEscala(a?.establecimiento?.escala_evaluacion)
        data.curso = grupoUsuario?.nombre
        data.nombre = usuario.nombreCompletoApellido()
        data.rut = this.getIdentificador(usuario)
        data.usuarioId = usuario.id
        data.evaluacion_id = this.evaluacion.id
        data.evaluacion_nombre = this.evaluacion.evaluacion
        data.colegio = grupoUsuario?.establecimiento?.establecimiento
        data.establecimiento_id = grupoUsuario?.establecimiento?.id
        data.evaluacionMultiple = false
    }

    setDataFull(page: number, per: number) {
        // TODO: esto hace mucho, quizás debería venir más ordenado desde la API
        const organizeData = (resolve, total) => {
            this.cantidadUsuarios = total
            const grupoUsuarios = this.grupoUsuarios()
            this.dataFull = this.usuarios.map(u => {
                const data: any = {}

                const [grupoUsuario, a] = this.grupoUsuarioAlumno(u, grupoUsuarios)

                this.setDataGeneral(data, a, grupoUsuario, u)

                const evaluacionInstancia = this.evaluacionInstancias.find(ei => ei.usuario_id == u.id)
                if (evaluacionInstancia) {
                    this.setDataEvaluacion(data, evaluacionInstancia, u, a)
                }
                return data
            }) /*.sort((data1, data2) => {
                if(!data1.puntaje) return 1;
                if(!data2.puntaje) return -1;

                return data2.puntaje - data1.puntaje;
            });*/

            resolve(total)
            this.setPromedio()
        }
        const promise = new Promise<number>((resolve, reject) => {
            const cantidadUsuarios = this.usuarios.length
            organizeData(resolve, cantidadUsuarios)
        })
        return promise
    }

    deleteEvaluacionTiempo(data) {
        const nombres = data.nombre.split(",")[1]
        const apellidos = data.nombre.split(",")[0]
        const tiempo_restante = this.evaluacion?.instrumento?.tiempo - data.tiempo
        this.evaluacionTiemposService.enableIgnoreCatch()
        this.evaluacionTiemposService
            .find(data.evaluacion_tiempo_id)
            .then(evaluacionTiempo => {
                this.evaluacionTiemposService.disableIgnoreCatch()
                this.simpleModalService.setModalHeader("Confirmación")
                this.simpleModalService.setModalStringContent(
                    `${nombres}, ${apellidos} está actualmente realizando la evaluación y le queda ${tiempo_restante} min.
                ¿Está seguro de querer reiniciar la evaluación?`
                )
                this.simpleModalService.setModalCallback(() => {
                    this.evaluacionTiemposService.remove(data.evaluacion_tiempo_id).then(() => {
                        delete data.evaluacion_tiempo_id
                        delete data.tiempo
                    })
                })
                this.simpleModalService.showSimpleModal()
            })
            .catch(error => {
                let msj = ""
                if (error.status) {
                    switch (error.status) {
                        case 404:
                            msj = `${nombres}, ${apellidos} ya ha realizado la evaluación ó el tiempo para completarla se acabo
                            y no es posible reiniciar esta evaluacion`
                            break

                        default:
                            msj = "Hubo un problema con el servidor. Error " + error.status + "."
                            break
                    }
                } else {
                    console.error("Error inesperado.")
                }
                this.evaluacionTiemposService.disableIgnoreCatch()
                this.simpleModalService.setModalHeader("Aviso")
                this.simpleModalService.setModalStringContent(msj)
                this.simpleModalService.setDisableBtnCancel(true)
                this.simpleModalService.showSimpleModal()
            })
    }

    labelsExcel() {
        const labels = {
            colegio: "Colegio",
            curso: "Curso",
            evaluacion_id: "ID Evaluación",
            evaluacion_nombre: "Nombre Evaluación",
            rut: "Rut",
            nombre: "Nombre",
            correctas: "Correctas",
            incorrectas: "Incorrectas",
            omitidas: "Omitidas",
            puntaje: "Puntaje",
            ...(this.evaluacion?.instrumento?.propio ? { puntuacion: "Puntuación" } : {}),
            tiempo: "Tiempo en segundos",
            fecha: "Fecha de entrega"
        }

        return labels
    }

    getExcel() {
        this.excelService.enableIgnoreModel()

        const buff = Buffer.from(JSON.stringify(this.dataFull))
        const prepareData = buff.toString("base64")

        const zone = Intl.DateTimeFormat().resolvedOptions().timeZone
        this.excelService.fromData({ labels: this.labelsExcel(), data: prepareData, zone }).then(resp => {
            let filename
            if (this.grupoUsuario) {
                filename = "resumen_estudiantes_" + this.grupoUsuario.nombre + ".xlsx"
            } else {
                const primero = this.data[0]
                let dato = ""
                if (primero && primero.colegio) {
                    dato = primero.colegio
                }
                filename = "resumen_estudiantes_" + dato + ".xlsx"
            }
            this.excelService.downdloadExcel(resp, filename)
        })
    }

    getNotaEscalaSiete(correctas: number, incorrectas: number, omitidas: number, escala: any) {
        let min, mid, max, p_mid
        mid = 4.0
        if (escala != null) {
            min = escala.min
            max = escala.max
            p_mid = escala.p_mid
            mid = escala.mid || mid
        } else {
            min = 1.0
            max = 7.0
            p_mid = 0.6
        }
        const totales = correctas + incorrectas + omitidas
        const fraccion = (1.0 * correctas) / totales
        let nota = min

        if (fraccion <= p_mid) {
            nota = min + fraccion * ((mid - min) / p_mid)
        } else {
            const fraccionAprobado = (correctas - p_mid * totales) / (totales - p_mid * totales)
            const temp = mid + fraccionAprobado * (max - mid) * 1.0
            nota = temp > max ? max : temp
        }

        return nota
    }

    getReforzamientos() {
        this.estadoReforzamientoPorEvaluacion = {}
        this.reforzamientosByEvaluacionId = {}
        if (this.evaluacionInstancias.length > 0) {
            const reforzamientosParams = {
                plan_personal_usuario: {
                    receptor_type: "Usuario",
                    receptor_id: this.usuarios.map(u => u.id)
                },
                plan_personal_ciclo: {
                    evaluacion_id: this.evaluacionInstancias.map(instancia => instancia.evaluacion_id)
                },
                evaluacion: {
                    tarea: 1
                },
                plan_personal_tipo: {
                    tipo: ["reforzamiento"]
                },
                render_options: {
                    include: {
                        plan_personal_usuarios: null,
                        plan_personal_ciclos: {
                            include: {
                                evaluacion: null,
                                plan_personal_sesiones: {
                                    include: {
                                        plan_personal_contenidos: {
                                            include: [
                                                "plan_personal_material_instancias",
                                                "plan_personal_evaluacion_instancias"
                                            ]
                                        }
                                    },
                                    methods: ["activa"]
                                }
                            }
                        }
                    }
                }
            }

            return this.planPersonalesService.wherePost(reforzamientosParams).then((planPersonales: PlanPersonal[]) => {
                planPersonales.forEach(reforzamiento => {
                    const evaluacion_ref_id = reforzamiento.plan_personal_ciclos[0].evaluacion_id
                    const realizado = reforzamiento.plan_personal_ciclos[0].plan_personal_sesiones.every(pps =>
                        pps.checkAvance()
                    )
                    if (!this.estadoReforzamientoPorEvaluacion[evaluacion_ref_id])
                        this.estadoReforzamientoPorEvaluacion[evaluacion_ref_id] = {}
                    this.estadoReforzamientoPorEvaluacion[evaluacion_ref_id][reforzamiento.usuario_id] = realizado
                    if (!this.reforzamientosByEvaluacionId[evaluacion_ref_id])
                        this.reforzamientosByEvaluacionId[evaluacion_ref_id] = {}
                    this.reforzamientosByEvaluacionId[evaluacion_ref_id][reforzamiento.usuario_id] = reforzamiento
                })
            })
        }

        return null
    }

    onGenerarReforzamientoReload() {
        this.getReforzamientos()
    }

    getNombreEstudiante = (data: any, key: any) => {
        const nombre = data.nombre
        if (!nombre) return ""

        return nombre
    }

    getPuntajeEstudiante = (data: any, key: any) => {
        const puntaje = data.puntaje
        if (!puntaje) return 0

        return puntaje
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["evaluacionInstancias"] && !changes["evaluacionInstancias"].firstChange) {
            this.paginator.changePage(1)
        }

        if (changes["estadisticas"]) {
            this.setPromedio()
        }
    }

    setPromedio() {
        if (this.estadisticas && this.estadisticas.length > 0) {
            const numeroEvaluaciones = this.estadisticas.reduce((acc, e) => acc + e.numero_evaluaciones, 0)
            this.promedio =
                this.estadisticas.reduce((acc, e) => acc + e.promedio * e.numero_evaluaciones, 0) / numeroEvaluaciones
            this.minimo = this.estadisticas.reduce(function (prev, current) {
                return prev.minimo < current.minimo ? prev : current
            }).minimo
            this.maximo = this.estadisticas.reduce(function (prev, current) {
                return prev.maximo < current.maximo ? current : prev
            }).maximo
            const estadistica_asignaturas = this.estadisticas.map(e => e.estadistica_asignaturas).flat()
            this.totOmitidas = estadistica_asignaturas.reduce((acc, e) => acc + e.omitidas, 0) / numeroEvaluaciones
            this.totCorrectas = estadistica_asignaturas.reduce((acc, e) => acc + e.correctas, 0) / numeroEvaluaciones
            this.totIncorrectas =
                estadistica_asignaturas.reduce((acc, e) => acc + e.incorrectas, 0) / numeroEvaluaciones
        }
    }

    infoEscala(escala) {
        if (escala != null) {
            this.notaMin = escala.min
            this.notaMax = escala.max
            this.notaProm = escala.p_mid * 100
        } else {
            this.notaMin = 4.0
            this.notaMax = 7.0
            this.notaProm = 60
        }
    }

    openModalEvaluacionInstancias(datoInstancia) {
        this.datoInstanciaModal = datoInstancia
        this.showModal = true
        this.loadingLayout.ready()
        setTimeout(() => {
            this.genericModal.buttonPressed()
        }, 100)
    }

    checkOficial(id) {
        this.idEvaluacionInstaciaEdit = id
        this.datoInstanciaModal.instancias.forEach(element => {
            if (element.id == id) {
                element.oficial = !element.oficial // false -> true
            } else {
                element.oficial = false
            }
        })
    }

    closeModalEvaluacionInstancias() {
        this.datoInstanciaModal.instancias.forEach(element => {
            element.oficial = false
        })
        this.datoInstanciaModal.instancias.forEach(ei => {
            if (ei.id == this.datoInstanciaModal.evaluacion_instancia_id) {
                ei.oficial = !ei.oficial
            }
        })
    }

    closeModal() {
        this.closeModalEvaluacionInstancias()
        this.genericModal.close()
    }

    setDataInstancias(data, instancias, usuario, evaluacionInstancia) {
        instancias = instancias.filter(ei => ei.usuario_id == usuario.id)
        if (instancias) {
            data.instancias = instancias.map(element => {
                const dataAllEvaluacionInstancia: any = {}
                dataAllEvaluacionInstancia.originalOficialEvaluacionInstanciaId = evaluacionInstancia.id
                dataAllEvaluacionInstancia.id = element.id
                dataAllEvaluacionInstancia.correctas = element.correctas
                dataAllEvaluacionInstancia.incorrectas = element.incorrectas
                dataAllEvaluacionInstancia.omitidas = element.omitidas
                dataAllEvaluacionInstancia.tiempo = element.tiempo_ocupado
                dataAllEvaluacionInstancia.fecha = new Date(element.created_at)
                dataAllEvaluacionInstancia.oficial = element.oficial
                dataAllEvaluacionInstancia.evaluacion_multiple_instancia_id = element.evaluacion_multiple_instancia_id
                dataAllEvaluacionInstancia.notaSobreSiete = this.getNotaEscalaSiete(
                    element.correctas,
                    element.incorrectas,
                    element.omitidas,
                    element.escala || data.escala
                )
                return dataAllEvaluacionInstancia
            })
        }
    }

    setDataEvaluacionGeneral(data, grupoUsuario) {
        data.escala = grupoUsuario?.establecimiento?.escala_evaluacion
        data.evaluacionInstanciasAcumuladas = []
    }

    setDataInstanciaOficial(data, evaluacionInstancia) {
        this.hasForma = this.hasForma || !!evaluacionInstancia.has_forma
        data.evaluacion_instancia_id = evaluacionInstancia.id
        data.evaluacion_usuarios_id = evaluacionInstancia.usuario_id
        data.correctas = evaluacionInstancia.correctas
        data.incorrectas = evaluacionInstancia.incorrectas
        data.omitidas = evaluacionInstancia.omitidas
        data.puntaje = evaluacionInstancia.calificacion
        data.tiempo = evaluacionInstancia.tiempo_ocupado
        data.fecha = new Date(evaluacionInstancia.created_at)
        data.forma = evaluacionInstancia.has_forma
        data.evaluacion_multiple_instancia_id = evaluacionInstancia.evaluacion_multiple_instancia_id
        data.puntuacion = evaluacionInstancia.puntuacion
        if (this.enableNotaSobreSiete) {
            data.notaSobreSiete = this.getNotaEscalaSiete(data.correctas, data.incorrectas, data.omitidas, data.escala)
        }
    }

    setDataEvaluacion(data, evaluacionInstancia, usuario, grupoUsuario) {
        this.setDataEvaluacionGeneral(data, grupoUsuario)
        this.setDataInstanciaOficial(data, evaluacionInstancia)

        this.setDataInstancias(data, this.allEvaluacionInstancias, usuario, evaluacionInstancia)
    }

    instanciasOldNewOficial() {
        const instanciaOldOficial = this.datoInstanciaModal.instancias.find(
            ei => ei.id == this.datoInstanciaModal.evaluacion_instancia_id
        )

        const instanciaNewOficial = this.datoInstanciaModal.instancias.find(
            ei => ei.id == this.idEvaluacionInstaciaEdit
        )

        return [instanciaOldOficial, instanciaNewOficial]
    }

    generadorInstrumentoId() {
        return this.evaluacion.instrumento.generador_instrumento_id
    }

    asignaturaId() {
        return this.evaluacion.instrumento.asignatura_id
    }

    updateEvaluacionInstanciaOficial() {
        if (this.datoInstanciaModal.instancias.find(ei => ei.oficial == true)) {
            if (this.idEvaluacionInstaciaEdit != this.datoInstanciaModal.evaluacion_instancia_id) {
                this.loadingEvaluacionInstancia = true
                const evaluacion_instancias = this.instanciasOldNewOficial()

                this.evaluacionInstanciasService
                    .updateInstanciaAndEstadistica({
                        evaluacion_instancias: evaluacion_instancias
                    })
                    .then(response => {
                        if (this.actualizarFiltro) {
                            this.filtroEstadisticasService.setFiltrosId(
                                this.datoInstanciaModal.establecimiento_id,
                                this.grupoUsuario ? this.grupoUsuario.id : null,
                                null,
                                this.evaluacion.id,
                                this.generadorInstrumentoId(),
                                this.evaluacion.evaluacion_tipo_id,
                                this.asignaturaId()
                            )
                        } else {
                            this.actualizarData.emit()
                        }

                        this.loadingEvaluacionInstancia = false
                        this.genericModal.close()
                    })
            }
        }
    }
}

import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        achievements: urls.achievementsUrl
    },
    googleAnalyticsPropertyId: {
        profesores: "UA-161860635-2",
        alumnos: "UA-161860635-1"
    },
    googleTagManagerPropertyId: {
        profesores: "GTM-N4H5XM8",
        alumnos: "GTM-MLGBTKD"
    },
    facebookAnalyticsPropertyId: {},
    domain: {
        profesores: "www.aprendolibre.cl",
        alumnos: "alumnos.aprendolibre.cl",
        admin: "admin.aprendolibre.cl"
    },
    facebook: {
        appId: "355103678235615",
        apiVersion: "v2.10"
    }
}

export const oldEnvironment = {
    production: true,
    facebookAppId: null,
    facebookApiVersion: null,
    baseUrl: urls.baseUrl,
    nebuUrl: urls.nebuUrl,
    achievementsUrl: urls.achievementsUrl,
    domain: {
        profesores: "www.aprendolibre.cl",
        alumnos: "alumnos.aprendolibre.cl",
        admin: "admin.aprendolibre.cl"
    }
}

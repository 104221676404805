<div *ngIf="asignaturasReady">
    <div *ngIf="generadorInstrumentos" class="lista-tipos-container">
        <ul class="lista-tipos" *ngIf="generadorInstrumentos && generadorInstrumentos.length > 1">
            <li *ngFor="let gi of generadorInstrumentos">
                <button
                    class="btn btn_default {{ asignaturas[asignatura.id]?.clase }}"
                    [class.active]="gi == generadorInstrumentoSelected"
                    (click)="onSelectGeneradorInstrumento(gi)"
                >
                    {{ gi.generador_instrumento }}
                </button>
            </li>
        </ul>
    </div>
    <loading-layout #loadingLayout>
        <div
            #recomendados
            [class.recomendados]="instrumentoPredefinidos && instrumentoPredefinidos.length != 0"
            *ngIf="generadorInstrumentoSelected"
        >
            <ng-container *ngIf="instrumentoPredefinidos && instrumentoPredefinidos.length != 0">
                <ng-container *ngFor="let ordenGrupo of instrumentoPredefinidosByGrupo | keys; let j = index">
                    <ng-container *ngFor="let nombreGrupo of instrumentoPredefinidosByGrupo[ordenGrupo] | keys">
                        <h3 *ngIf="nombreGrupo">{{ nombreGrupo }}</h3>
                        <div class="flex-container">
                            <ng-container
                                *ngFor="let key of instrumentoPredefinidosByGrupo[ordenGrupo][nombreGrupo] | keys"
                            >
                                <div
                                    class="mini-table {{
                                        asignaturas[
                                            instrumentoPredefinidosByGrupo[ordenGrupo][nombreGrupo][key].asignatura_id
                                        ]?.clase
                                    }}"
                                >
                                    <div class="t_wrapper">
                                        <div class="t_row">
                                            <div class="t_cell icon_cell">
                                                <div class="icon_container">
                                                    <ogr-icon
                                                        class="icono"
                                                        name="{{
                                                            asignaturas[
                                                                instrumentoPredefinidosByGrupo[ordenGrupo][nombreGrupo][
                                                                    key
                                                                ].asignatura_id
                                                            ]?.icono
                                                        }}"
                                                    ></ogr-icon>
                                                </div>
                                            </div>
                                            <div class="t_cell">
                                                <h3 class="title">
                                                    {{
                                                        instrumentoPredefinidosByGrupo[ordenGrupo][nombreGrupo][key]
                                                            .nombre
                                                    }}
                                                </h3>
                                                <h4 class="asignatura">
                                                    {{
                                                        asignaturas[
                                                            instrumentoPredefinidosByGrupo[ordenGrupo][nombreGrupo][key]
                                                                .asignatura_id
                                                        ].asignatura
                                                    }}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content-button">
                                        <a
                                            class="descripcion"
                                            [popover]="
                                                instrumentoPredefinidosByGrupo[ordenGrupo][nombreGrupo][key]
                                                    .descripcion || 'no tiene descripción'
                                            "
                                        >
                                            {{ "evaluar.recomendados.ver" | t }}
                                        </a>
                                        <button
                                            type="button"
                                            class="btn btn-default pull-right btn-continue btn-sm"
                                            (click)="
                                                seleccionar(
                                                    instrumentoPredefinidosByGrupo[ordenGrupo][nombreGrupo][key]
                                                        .instrumento_id,
                                                    instrumentoPredefinidosByGrupo[ordenGrupo][nombreGrupo][key].id
                                                )
                                            "
                                        >
                                            {{ "evaluar.recomendados.seleccionar" | t }}
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <p class="not-found" *ngIf="!(instrumentoPredefinidos && instrumentoPredefinidos.length > 0)">
            {{ "evaluar.recomendados.not_found" | t }}
        </p>
    </loading-layout>
</div>

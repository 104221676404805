import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit, OnDestroy } from "@angular/core"
import { Subscription } from "rxjs"
import { ActivatedRoute } from "@angular/router"
import { GrupoUsuarios, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { AppConfig, AuthService } from "@puntaje/shared/core"

declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "planes-personales-asignaturas",
    templateUrl: "./planes-personales-asignaturas.component.html"
})
export class PlanesPersonalesAsignaturasComponent implements OnInit {
    evaluacionTipo: string = config.plataforma.evaluacionDefault
    evaluacionTipoDiagnostico: string = config.plataforma.evaluacionTipoAdaptiveDefault
    planesPersonalesRoute: string = "/plan_personal"
    gruposLoaded = false
    nivelId: number

    fechaInicial: string
    fechaFinal: string

    constructor(
        protected titleService: TitleService,
        protected route: ActivatedRoute,
        protected authService: AuthService,
        protected grupoUsuariosService: GrupoUsuarios,
        protected loggedLayoutService: LoggedLayoutService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.planes_personales_asignaturas")
        this.fechaInicial = new Date(new Date().getFullYear(), 0, 1).toISOString()
        this.fechaFinal = new Date(new Date().getFullYear() + 1, 0, 1).toISOString()
        this.getNivelUsuario()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    private getNivelUsuario() {
        this.gruposLoaded = false
        const params = {
            establecimiento_id_not_nil: 1,
            activos: 1,
            grupo_usuario_usuario: {
                propietario: 0
            },
            propios: 1,
            fecha_inicial: this.fechaInicial,
            fecha_final: this.fechaFinal
        }

        this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[]) => {
            if (grupoUsuarios.length > 0) {
                this.nivelId = grupoUsuarios.find(g => !!g.curso_id).curso_id
            }
            this.gruposLoaded = true
        })
    }
}

<cui-evaluation-layout [contenidoLateralXL]="false">
    <cui-layout-header>
        <navbar *ngIf="isLoggedIn()"></navbar>
    </cui-layout-header>
    <cui-layout-footer>
        <footer-component></footer-component>
        <div class="edificios-wrapper"><div class="edificios"></div></div>
        <simple-modal></simple-modal>
    </cui-layout-footer>
</cui-evaluation-layout>

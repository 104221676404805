import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { DetallePlanAlumnoComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "plan_personal/:id/detalle_alumno/:usuario_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: DetallePlanAlumnoComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)

import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { routing } from "./profesores_ensayos.routing"
import { UtilModule, FormModule, TranslationsModule } from "@puntaje/shared/core"
import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { TabsModule } from "ngx-bootstrap/tabs"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"
//import { PreguntaEnsayoComponent } from "./pregunta_ensayo.component";
//import { RespuestaEnsayoComponent } from "./respuesta_ensayo.component";
import { HomeSimceComponent } from "./home_simce.component"
import { HomePruebasComponent } from "./home_pruebas.component"
import { NebuModule } from "@puntaje/nebulosa/api-services"
import { PuntajeEnsayosModule, PuntajeEstadisticasModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        InstrumentosModule,
        ProfesoresLayoutsModule,
        TabsModule,
        UtilModule,
        routing,
        Angular2FontawesomeModule,
        FormModule,
        SharedModule,
        NebuModule,
        TranslationsModule,
        CUILayoutsModule,
        PuntajeEnsayosModule,
        PuntajeEstadisticasModule
    ],
    declarations: [HomeSimceComponent, HomePruebasComponent],
    exports: []
})
export class ProfesoresEnsayosModule {}

import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { AyudaModule as AyudaMod } from "@puntaje/puntaje/new-modules/ayuda"

import { ayudaRouting } from "./profesores_ayuda.routing"
import { PreguntasFrecuentesComponent } from "./preguntas-frecuentes/preguntas-frecuentes.component"
import { PuntajeAyudaModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ProfesoresLayoutsModule,
        ayudaRouting,
        AyudaMod,
        CUILayoutsModule,
        PuntajeAyudaModule
    ],
    declarations: [PreguntasFrecuentesComponent],
    exports: [],
    providers: []
})
export class AyudaModule {}

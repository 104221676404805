<loading-layout #loadingLayout>
    <ng-container *ngIf="!loadingLayout.loading">
        <div *ngIf="planPersonal">
            <div class="informacion-container">
                <div class="item">
                    <p>
                        Estudiante:
                        <br />
                        <b>{{ nombreAlumno }}</b>
                    </p>
                    <p>
                        Evaluación:
                        <br />
                        <b>
                            {{ planPersonal.plan_personal_ciclos[0].evaluacion.evaluacion }} #{{
                                planPersonal.plan_personal_ciclos[0].evaluacion.id
                            }}
                        </b>
                    </p>
                </div>
                <div class="item">
                    Progreso total
                    <br />
                    <progressbar
                        class="active progressbar"
                        [value]="progressPercentagePlan"
                        [animate]="true"
                    ></progressbar>
                </div>
            </div>

            <div *ngFor="let ciclo of planPersonal.plan_personal_ciclos">
                <div *ngFor="let sesion of ciclo.plan_personal_sesiones" class="sesion-container">
                    <div class="info-container">
                        <div class="item">
                            <label class="numero-sesion">
                                {{
                                    planPersonal.plan_personal_ciclos.length > 1 ? "Ciclo " + ciclo.orden + 1 + ":" : ""
                                }}
                                {{ nombreSesion }} {{ sesion.orden + 1 }}
                            </label>
                        </div>
                        <div class="item">
                            Progreso de la sesión
                            <br />
                            <progressbar
                                class="active progressbar"
                                [value]="progressPercentageBySession[sesion.id]"
                                [animate]="true"
                            ></progressbar>
                        </div>
                    </div>
                    <div class="contenidos-container">
                        <div *ngFor="let contenido of sesion.plan_personal_contenidos; let i = index" class="contenido">
                            <div class="contenido-thumbnail" (click)="openPreview(contenido)">
                                <thumbnail-material
                                    class="icono"
                                    *ngIf="materialTipos && contenido.material"
                                    [material]="contenido.material"
                                    [materialTipos]="materialTipos"
                                ></thumbnail-material>
                                <ogr-icon *ngIf="!contenido.material" class="icono" name="hacer-prueba"></ogr-icon>
                                <div *ngIf="avanceContenidos[contenido.id]" class="check visto">
                                    {{ contenido.material ? "Visto" : "Entregado" }}
                                    <fa [name]="'check'"></fa>
                                </div>
                                <div *ngIf="!avanceContenidos[contenido.id]" class="check pendiente">Pendiente</div>
                                <div class="index">
                                    <b>{{ i + 1 }}</b>
                                </div>
                            </div>
                            <div class="contenido-info">
                                <p>
                                    <a
                                        *ngIf="contenido.material"
                                        (click)="openPreview(contenido)"
                                        href="javascript:void(0)"
                                    >
                                        {{ contenido.material.material ? contenido.material.material : "Material" }}
                                    </a>
                                    <a
                                        *ngIf="contenido.evaluacion"
                                        (click)="openPreview(contenido)"
                                        href="javascript:void(0)"
                                    >
                                        {{
                                            contenido.evaluacion.evaluacion
                                                ? contenido.evaluacion.evaluacion
                                                : "Evaluación"
                                        }}
                                    </a>
                                </p>
                                <div *ngIf="contenido.material">
                                    <p *ngIf="avanceContenidos[contenido.id]">
                                        Visto durante:
                                        <b>
                                            {{
                                                contenido.plan_personal_material_instancias[0].tiempo
                                                    | secondsToTime: "s":"hrminseg"
                                                    | easyplaceholder: "-"
                                            }}
                                        </b>
                                    </p>
                                    <cui-button class="btn_style" (click)="openMaterialPreview(contenido.material)">
                                        Ver Material
                                    </cui-button>
                                </div>
                                <div *ngIf="contenido.evaluacion">
                                    <ng-container *ngIf="avanceContenidos[contenido.id]">
                                        <p>
                                            <fa [name]="'clock-o'"></fa>
                                            Duración:
                                            <br />
                                            <b>
                                                {{
                                                    contenido.plan_personal_evaluacion_instancias[0]
                                                        .evaluacion_instancia.tiempo_ocupado
                                                        | secondsToTime: "s":"hrminseg"
                                                        | easyplaceholder: "-"
                                                }}
                                            </b>
                                        </p>
                                        <div class="result">
                                            <p>
                                                Calificación:
                                                <br />
                                                <b>
                                                    {{
                                                        contenido.plan_personal_evaluacion_instancias[0]
                                                            .evaluacion_instancia.calificacion | roundfloat
                                                    }}
                                                    %
                                                </b>
                                            </p>
                                            <span class="separador"></span>
                                            <cui-button
                                                class="btn_style"
                                                (click)="
                                                    openEvalInstPreview(
                                                        contenido.plan_personal_evaluacion_instancias[0]
                                                            .evaluacion_instancia.id
                                                    )
                                                "
                                            >
                                                Ver resultados
                                            </cui-button>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!avanceContenidos[contenido.id]">
                                        <cui-button
                                            class="btn_style"
                                            (click)="openEvalPreview(contenido.evaluacion.id)"
                                        >
                                            Ver evaluacion
                                        </cui-button>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <evaluacion-preview-modal
            *ngIf="modalEvaluacionId"
            [modalId]="'evaluacion'"
            [evaluacionId]="modalEvaluacionId"
            (closeCrossCallback)="closeEvalPreview()"
            [enableReporte]="false"
            [enableImprimir]="false"
        ></evaluacion-preview-modal>
        <material-preview-modal
            *ngIf="materialOpen"
            [modalId]="'material'"
            (closeCrossCallback)="closeMaterialPreview()"
        ></material-preview-modal>
        <evaluacion-instancia-preview-modal
            *ngIf="modalEvaluacionInstanciaId"
            [modalId]="'evaluacion-instancia'"
            [evaluacionInstanciaId]="modalEvaluacionInstanciaId"
            (closeCrossCallback)="closeEvalInstPreview()"
        ></evaluacion-instancia-preview-modal>
    </ng-container>
</loading-layout>

import {
    Component,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    OnInit,
    SimpleChanges,
    OnChanges,
    ElementRef
} from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig, AuthService } from "@puntaje/shared/core"
declare const config: AppConfig
import { AbstractGenerarEnsayoComponent } from "./abstract_generar_ensayo.component"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import {
    Asignatura,
    Asignaturas,
    GeneradorInstrumentos,
    GeneradorInstrumento,
    AsignaturaWithConfig
} from "@puntaje/nebulosa/api-services"
import {
    InstrumentoPredefinido,
    InstrumentoPredefinidos,
    Instrumento,
    Instrumentos,
    MonitoreoInstrumentoPredefinidos,
    MonitoreoInstrumentoPredefinido
} from "@puntaje/puntaje/api-services"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { Subscription, Observable } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "recomendado-ensayo-profesor",
    templateUrl: "ensayos_recomendados.component.html",
    styleUrls: ["ensayos_recomendados.component.scss"]
})
export class EnsayosRecomendadosComponent implements OnInit, OnChanges {
    @ViewChild("loadingLayout") loadingLayout: LoadingLayoutComponent
    @Input() asignatura: Asignatura
    @Input() evaluacionTipo: string = config.plataforma.evaluacionDefault
    @Input() generadorInstrumentoSelected: any
    @Output() onLoadingInstrumento: EventEmitter<any> = new EventEmitter<any>()
    @Output() onReadyInstrumento: EventEmitter<any> = new EventEmitter<any>()
    instrumentoPredefinidos: InstrumentoPredefinido[]
    asignaturas: { [key: number]: AsignaturaWithConfig } = {}
    asignaturasReady: boolean = false
    @ViewChild("recomendados") recomendados: ElementRef
    @Input() externalFrameReference: ElementRef
    zonas: string[]
    generadorInstrumentos: GeneradorInstrumento[]
    listaFiltroGeneradoresInstrumento: number[]
    instrumentoPredefinidosByGrupo: { [index: number]: { [grupo: string]: InstrumentoPredefinido[] } }

    sub: Subscription
    asignaturasByEvaluacionTipo$: Observable<{ [evaluacionTipo: string]: AsignaturaWithConfig[] }> = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    lastInstrumentoPredefinidoIdSelected: number

    constructor(
        protected route: ActivatedRoute,
        protected instrumentosService: Instrumentos,
        protected instrumentoPredefinidosService: InstrumentoPredefinidos,
        protected store: Store<State>,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected monitoreoInstrumentoPredefinidosService: MonitoreoInstrumentoPredefinidos,
        protected authService: AuthService
    ) {}

    async ngOnInit() {
        if (this.loadingLayout) this.loadingLayout.ready()

        this.getAsignaturas()

        this.zonas = config.instrumentosPredefinidos.zonas
        //this.getInstrumentoPredefinidos();
    }

    getAsignaturas() {
        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            asignaturasByEvaluacionTipo[this.evaluacionTipo].forEach(a => {
                this.asignaturas[a["id"]] = a
                this.asignaturasReady = true
            })
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        this.getAsignaturas()
        if (changes["asignatura"]) {
            if (this.loadingLayout) this.loadingLayout.standby()
            this.instrumentoPredefinidos = null
            if (this.asignatura && this.listaFiltroGeneradoresInstrumento) {
                this.filterByAvailableContent()
            }
            if (this.asignatura && !this.listaFiltroGeneradoresInstrumento) {
                this.getFilteredList()
            }
        }
    }

    getFilteredList() {
        this.instrumentoPredefinidosService.enableIgnoreModel()
        this.instrumentoPredefinidosService.getGeneradorInstrumentoIds().then((response: any) => {
            if (!this.listaFiltroGeneradoresInstrumento) this.listaFiltroGeneradoresInstrumento = response
            this.instrumentoPredefinidosService.disableIgnoreModel()
            //this.getInstrumentoPredefinidos();
            if (this.asignatura) this.filterByAvailableContent()
        })
    }

    filterByAvailableContent() {
        this.generadorInstrumentos = this.asignatura.generador_instrumentos.filter(g => {
            return this.listaFiltroGeneradoresInstrumento.find(e => g.id == e)
        })
        this.generadorInstrumentos = this.generadorInstrumentos
            .sort((gi1, gi2) => {
                return gi1.orden - gi2.orden
            })
            .filter(gi => gi.visible)

        if (this.generadorInstrumentos.length > 0) {
            this.onSelectGeneradorInstrumento(this.generadorInstrumentos[0])
        } else {
            if (this.loadingLayout) this.loadingLayout.ready()
        }
    }

    onSelectGeneradorInstrumento(gi: GeneradorInstrumento) {
        this.generadorInstrumentoSelected = gi
        this.getInstrumentoPredefinidos()
    }

    getInstrumentoPredefinidos() {
        if (!this.generadorInstrumentoSelected) return
        let params = {
            sort_by: "created_at",
            order: "desc",
            instrumento_predefinido: {
                tag: this.zonas,
                asignatura_id: this.asignatura.id
            },
            evaluacion_tipo: {
                evaluacion_tipo: this.evaluacionTipo
            },
            instrumento: {
                generador_instrumento_id: this.generadorInstrumentoSelected.id
            },
            render_options: {
                include: {
                    grupo_recursos: {
                        include: "grupo_recurso_tipo"
                    }
                }
            }
        }
        this.instrumentoPredefinidosService.where(params).then((response: InstrumentoPredefinido[]) => {
            this.instrumentoPredefinidos = response

            this.instrumentoPredefinidosByGrupo = this.instrumentoPredefinidos.reduce((acc, ip) => {
                ip.grupo_recursos.forEach(gr => {
                    acc[gr.grupo_recurso_tipo.orden + 1] = acc[gr.grupo_recurso_tipo.orden + 1] || {}
                    acc[gr.grupo_recurso_tipo.orden + 1][gr.grupo_recurso_tipo.grupo_recurso_tipo] =
                        acc[gr.grupo_recurso_tipo.orden + 1][gr.grupo_recurso_tipo.grupo_recurso_tipo] || []
                    acc[gr.grupo_recurso_tipo.orden + 1][gr.grupo_recurso_tipo.grupo_recurso_tipo][gr.orden] = ip
                })

                if (ip.grupo_recursos.length == 0) {
                    acc[0] = acc[0] || {}
                    acc[0][""] = acc[0][""] || []
                    acc[0][""].push(ip)
                }

                return acc
            }, {})

            if (this.loadingLayout) this.loadingLayout.ready()
        })
    }

    seleccionar(id: number, instrumentoPredefinidoId: number) {
        if (!id) {
            return
        }

        const shouldMonitor = this.lastInstrumentoPredefinidoIdSelected != instrumentoPredefinidoId
        this.lastInstrumentoPredefinidoIdSelected = instrumentoPredefinidoId

        this.onLoadingInstrumento.emit()
        this.instrumentosService
            .find(id, { include: "[instrumento_material]", with_generador_instrumento: 1 })
            .then(async (i: Instrumento) => {
                this.onReadyInstrumento.emit(i)

                if (shouldMonitor) {
                    const monitoreoInstrumentoPredefinido = new MonitoreoInstrumentoPredefinido()
                    monitoreoInstrumentoPredefinido.usuario_id = this.authService.getUserData().id
                    monitoreoInstrumentoPredefinido.instrumento_id = i.id
                    monitoreoInstrumentoPredefinido.instrumento_predefinido_id = instrumentoPredefinidoId

                    await this.monitoreoInstrumentoPredefinidosService.save(monitoreoInstrumentoPredefinido)
                }
            })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}

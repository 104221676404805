import { Component, HostBinding, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Post, Posts } from "@puntaje/puntaje/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "blog-showcasebox-profesor",
    templateUrl: "./blog-showcasebox-profesor.component.html",
    styleUrls: ["./blog-showcasebox-profesor.component.scss"]
})
export class BlogShowcaseboxProfesorComponent implements OnInit {
    config = config
    post: Post
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @HostBinding("class") innerClass = "showcasebox_style_1_blog"

    constructor(protected postsServices: Posts) {}

    ngOnInit() {
        this.loadingLayout.standby()
        const params = {
            page: 1,
            per: 1,
            sort_by: "created_at",
            order: "desc",
            include: "[usuario]",
            post_perfil: {
                post_perfil: ['profesores', 'todos']
            },
        }

        this.postsServices.where(params).then((post: Post[], total: number) => {
            if (post.length > 0) this.post = post[0]
            this.loadingLayout.ready()
            return total
        })
    }
}

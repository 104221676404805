import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ChangeDetectorRef } from "@angular/core"
import {
    PlanPersonales,
    PlanPersonal,
    PlanPersonalInstancias,
    PlanPersonalInstancia
} from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { MaterialTipos, MaterialTipo } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { EvaluacionPreviewModalComponent } from "@puntaje/puntaje/new-modules/ensayos"
import { MaterialPreviewModalComponent } from "@puntaje/puntaje/new-modules/materiales"
import { EvaluacionInstanciaPreviewModalComponent } from "@puntaje/puntaje/new-modules/ensayos"

@Component({
    selector: "detalle-plan-alumno",
    templateUrl: "./detalle-plan-alumno.component.html",
    styleUrls: ["./detalle-plan-alumno.component.scss"]
})
export class DetallePlanAlumnoComponent implements OnInit {
    planPersonal: PlanPersonal
    progressPercentageBySession: { [key: number]: number } = {}
    progressPercentagePlan: number
    avanceContenidos: { [key: number]: boolean } = {}
    materialTipos: MaterialTipo[]
    nombreSesion = config.plataforma.sesionAlias || "Sesión"
    modalEvaluacionId: number
    modalEvaluacionInstanciaId: number
    materialOpen = false
    nombreAlumno: string = ""

    @Input() planPersonalId: number
    @Input() usuarioId: number
    @Output() onReadyPlan: EventEmitter<any> = new EventEmitter<any>()

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(EvaluacionPreviewModalComponent)
    evaluacionPreviewModalComponent: EvaluacionPreviewModalComponent
    @ViewChild(EvaluacionInstanciaPreviewModalComponent)
    evaluacionInstanciaPreviewModalComponent: EvaluacionPreviewModalComponent
    @ViewChild(MaterialPreviewModalComponent)
    materialPreviewModalComponent: MaterialPreviewModalComponent

    constructor(
        protected cdr: ChangeDetectorRef,
        protected planPersonalesService: PlanPersonales,
        protected authService: AuthService,
        protected planPersonalInstanciasService: PlanPersonalInstancias,
        protected materialTiposService: MaterialTipos
    ) { }

    ngOnInit() {
        this.materialTiposService.where().then(materialTipos => {
            this.materialTipos = materialTipos
        })

        this.getPlan()
    }

    getPlan() {
        if (this.planPersonalId && this.usuarioId) {
            const reforzamientosParams = {
                plan_personal: {
                    id: this.planPersonalId
                },
                plan_personal_usuario: {
                    receptor_type: "Usuario",
                    receptor_id: this.usuarioId
                },
                render_options: {
                    include: {
                        plan_personal_usuarios: {
                            include: { usuario_receptor: null }
                        },
                        plan_personal_ciclos: {
                            include: {
                                evaluacion: null,
                                plan_personal_sesiones: {
                                    include: {
                                        plan_personal_contenidos: {
                                            include: {
                                                evaluacion: {
                                                    include: { instrumento: null }
                                                },
                                                material: null
                                            }
                                        }
                                    },
                                    methods: ["activa"]
                                }
                            }
                        }
                    }
                }
            }
            return this.planPersonalesService.where(reforzamientosParams).then((planPersonales: PlanPersonal[]) => {
                if (planPersonales.length > 0) {
                    this.planPersonal = planPersonales[0]
                    this.nombreAlumno = this.planPersonal.plan_personal_usuarios[0].usuario_receptor.nombreCompleto()

                    this.onReadyPlan.emit(this.planPersonal)

                    this.planPersonalInstanciasService
                        .where({
                            plan_personal_instancia: {
                                plan_personal_id: this.planPersonalId,
                                usuario_id: this.usuarioId
                            },
                            render_options: {
                                include: {
                                    plan_personal_material_instancias: null,
                                    plan_personal_evaluacion_instancias: {
                                        include: {
                                            evaluacion_instancia: null
                                        }
                                    }
                                }
                            }
                        })
                        .then((planPersonalInstancias: PlanPersonalInstancia[]) => {
                            this.setPlanPersonalesWithInstancias(planPersonalInstancias)
                            this.setEstadoPlan()

                            this.loadingLayout.ready()
                        })
                } else {
                    this.loadingLayout.ready()
                }
            })
        } else {
            this.loadingLayout.ready()

            return null
        }
    }

    setPlanPersonalesWithInstancias(planPersonalInstancias) {
        const ppInstancias = planPersonalInstancias.filter(ppi => ppi.plan_personal_id == this.planPersonal.id)
        if (ppInstancias.length > 0) {
            const ppInstancia = ppInstancias[0]

            this.setPlanPersonalWithInstancia(ppInstancia)
        } else {
            const ppInstancia = {
                plan_personal_material_instancias: [],
                plan_personal_evaluacion_instancias: []
            }
            this.setPlanPersonalWithInstancia(ppInstancia)
        }
    }

    setPlanPersonalWithInstancia(ppinstancia) {
        this.planPersonal.plan_personal_ciclos.forEach(ppciclo => {
            ppciclo.plan_personal_sesiones.forEach(ppsesion => {
                ppsesion.plan_personal_contenidos.forEach(ppcontenido => {
                    if (ppcontenido.tipo == "Material") {
                        ppcontenido.plan_personal_material_instancias =
                            ppinstancia.plan_personal_material_instancias.filter(
                                ppmi => ppmi.plan_personal_contenido_id == ppcontenido.id
                            )
                        ppcontenido.plan_personal_evaluacion_instancias = []
                    } else if (ppcontenido.tipo == "Evaluacion") {
                        ppcontenido.plan_personal_evaluacion_instancias =
                            ppinstancia.plan_personal_evaluacion_instancias.filter(
                                ppei => ppei.plan_personal_contenido_id == ppcontenido.id
                            )
                        ppcontenido.plan_personal_material_instancias = []
                    }
                })
            })
        })
    }

    setEstadoPlan() {
        let countTotalContenidos = 0
        let countTotalContenidosVistos = 0

        this.planPersonal.plan_personal_ciclos.every(ppc => {
            ppc.plan_personal_sesiones.every(pps => {
                const avanceContenidos = pps.checkAvanceByContenido()

                this.avanceContenidos = { ...this.avanceContenidos, ...avanceContenidos }

                const countContenidos = pps.plan_personal_contenidos.length
                const countContenidosVistos = Object.values(avanceContenidos).reduce((acc: number, avance: boolean) => {
                    return acc + (avance ? 1 : 0)
                }, 0) as number

                this.progressPercentageBySession[pps.id] = (countContenidosVistos / countContenidos) * 100

                countTotalContenidos += countContenidos
                countTotalContenidosVistos += countContenidosVistos
            })
        })

        this.progressPercentagePlan = (countTotalContenidosVistos / countTotalContenidosVistos) * 100
    }

    openEvalPreview(evaluacionId: number) {
        this.modalEvaluacionId = evaluacionId
        this.cdr.detectChanges()
        this.evaluacionPreviewModalComponent.open()
    }

    openEvalInstPreview(evaluacionInstanciaId: number) {
        this.modalEvaluacionInstanciaId = evaluacionInstanciaId
        this.cdr.detectChanges()
        this.evaluacionInstanciaPreviewModalComponent.open()
    }

    closeEvalPreview() {
        this.modalEvaluacionId = undefined
    }

    closeEvalInstPreview() {
        this.modalEvaluacionInstanciaId = undefined
    }

    openMaterialPreview(material) {
        this.materialOpen = true
        this.cdr.detectChanges()
        this.materialPreviewModalComponent.open(material)
    }

    closeMaterialPreview() {
        this.materialOpen = false
        this.modalEvaluacionId = undefined
    }

    openPreview(contenido) {
        if (contenido.material) {
            this.openMaterialPreview(contenido.material)
        } else if (contenido.evaluacion) {
            if (this.avanceContenidos[contenido.id]) {
                this.openEvalInstPreview(contenido.plan_personal_evaluacion_instancias[0].evaluacion_instancia.id)
            } else {
                this.openEvalPreview(contenido.evaluacion.id)
            }
        }
    }
}

import { Component, OnInit } from "@angular/core"

@Component({
    selector: "acerca-de-aprendolibre-chile",
    templateUrl: "./acerca-de-aprendolibre-chile.component.html"
})
export class AcercaDeAprendolibreChileComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}

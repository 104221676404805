import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { ClasePlanClaseComponent, PlanClaseAlumnoComponent, PlanClasesComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "plan_estudios/:id/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ClasePlanClaseComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_estudios/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanClaseAlumnoComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "plan_estudios",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesComponent,
                canActivate: [AuthGuard],
                data: {
                    enableSiteWarning: false
                }
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)

import { Component, OnInit } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { AuthService, TabService } from "@puntaje/shared/core"
import { LoggedLayoutService } from "@puntaje/shared/cui"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "home_ayudas_profesor.component.html"
})
export class HomeAyudasProfesorComponent implements OnInit {
    config: typeof config = config
    cursos: Clasificacion[]
    menuItems: MenuItemBoxData[] = []
    enableMenu: boolean = false
    usuarioId: number

    constructor(
        protected titleService: TitleService,
        protected csService: Clasificaciones,
        protected tabService: TabService,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService,
        protected i18nService: I18nService
    ) {
        this.usuarioId = this.authService.getUserData().id
    }

    ngOnInit() {
        this.titleService.setTitle("titles.core.ayudas.home_ayudas_profesor")
        this.setMenuItems()
        this.loggedLayoutService.setOptions({
            freeContentStyle: true
        })
    }

    setMenuItems() {
        let curso = 1
        if (this.cursos && this.cursos.length > 0) curso = this.cursos[0].id
        this.menuItems = [
            {
                route: "/ayudas/preguntas_frecuentes/",
                label: this.i18nService.translate("puntaje_core.home_ayudas.label_preguntas_frecuentes"),
                text: this.i18nService.translate("puntaje_core.home_ayudas.text_preguntas_frecuentes"),
                linkText: "Ver",
                icon: "libro2-o"
            },
            {
                route: "/ayudas/materiales/",
                label: this.i18nService.translate("puntaje_core.home_ayudas.label_tutoriales"),
                text: this.i18nService.translate("puntaje_core.home_ayudas.text_tutoriales"),
                linkText: "Ver",
                icon: "libro2-o"
            }
        ]
        this.enableMenu = true
    }
}

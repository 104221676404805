import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { Router } from "@angular/router"
import { ErroresService, AppEnv } from "@puntaje/shared/core"
import { NebuAuthService } from "./nebu_auth.service"
import { NebuBaseService } from "./nebu_base.service"
import { Alternativa } from "./alternativas.model"

@Injectable()
export class Alternativas extends NebuBaseService<Alternativa> {
    tableName = "alternativas"
    singularTableName = "alternativa"
    modelClass = Alternativa

    constructor(
        protected http: HttpClient,
        protected auth: NebuAuthService,
        protected router: Router,
        protected errores: ErroresService,
        protected environment: AppEnv
    ) {
        super(http, auth, router, errores, environment)
    }

    public updateMany(params: any) {
        console.log(params)
        return this.all().concatExtra("update_many").post(params)
    }

    public wherePost(params: any = null, timeout: number = 30000) {
        return this.all().concatExtra("post").post(params, timeout)
    }
}

import { Component } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Footer, incluye información de la empresa.

#################################*/

@Component({
    templateUrl: "footer.component.html",
    selector: "footer-component",
    styleUrls: ["footer.component.scss"]
})
export class FooterComponent {
    supportMail: string
    supportPhones: string[]
    informacionContacto: any
    supportPhone: string

    config: typeof config
    constructor() {
        this.config = config

        this.supportMail = config.plataforma.info.soporte.mail
        this.supportPhones = config.plataforma.info.soporte.telefonos || []
        this.informacionContacto = config.app.informacionContacto || []
        this.supportPhone = config.plataforma.info.soporte?.telefono || ""
    }

    trimSpaces(val: string) {
        return !val ? "" : val.replace(/ /g, "")
    }

    isString(obj): obj is string {
        return typeof obj === "string"
    }
}

import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnInit, HostBinding } from "@angular/core"
import { Asignaturas } from "@puntaje/nebulosa/api-services"

import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasByEvaluacionTipo } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "materiales-showcasebox",
    templateUrl: "materiales_showcasebox.component.html",
    styleUrls: ["materiales_showcasebox.component.scss"]
})
export class MaterialesShowcaseBoxComponent implements AfterViewInit, OnInit {
    @ViewChild("wrapper", { static: true }) wrapper: ElementRef
    @Input() evaluacionTipo: string
    enableSmall: boolean = false
    lista_asignaturas: any = []
    lista_asignaturas_by_row: any = []
    row_size: number = 2

    sub: Subscription
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    @HostBinding("class") innerClass = "showcasebox_style_1"

    constructor(protected store: Store<State>) {}

    ngOnInit() {
        this.setAsignaturas()
    }

    ngAfterViewInit() {
        this.checkSize()
    }

    checkSize() {
        if (this.wrapper) {
            this.enableSmall = this.wrapper.nativeElement.offsetWidth <= 300
        } else {
            this.enableSmall = false
        }
    }

    setAsignaturas() {
        this.sub = this.asignaturasByEvaluacionTipo$.subscribe(asignaturasByEvaluacionTipo => {
            this.lista_asignaturas =
                asignaturasByEvaluacionTipo[this.evaluacionTipo || config.plataforma.evaluacionDefault]

            this.lista_asignaturas_by_row = this.lista_asignaturas.reduce((acc, x, i) => {
                if (i % this.row_size == 0) {
                    acc[(i / this.row_size) | 0] = [x]
                } else {
                    acc[(i / this.row_size) | 0][i % this.row_size] = x
                }
                return acc
            }, [])
        })
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.sub.unsubscribe()
    }
}

import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { LoadingLayoutComponent, CarouselSlide } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    private totalHeight: number
    private windowHeight: number

    video_width: number = 600
    video_height: number = 360
    video_max_width: number = 600
    video_max_height: number = 360
    @ViewChild("iframeContainer", { static: true }) iframeContainer: ElementRef
    video_url: string = "https://www.youtube.com/embed/sac-DOQw7rE?rel=0"
    safe_video_url: string
    safeUrl: SafeResourceUrl

    config: typeof config
    contactMail: string

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public authService: AuthService,
        private sanitizer: DomSanitizer
    ) {}

    slides_1: CarouselSlide[] = []
    slides_2: CarouselSlide[] = []

    // constructor(protected router: Router, protected route: ActivatedRoute,
    // 						protected authService: AuthService) {
    // 	let companyName = config.plataforma.info.companyName;
    // 	this.slides_1 = [
    // 	{titulo:"1. Regístrate gratis en "+companyName, url:"https://cdn.puntajenacional.cl/layout2015/images/prelog/slide01.png"},
    // 	{titulo:"2. Realiza un ensayo.", url:"https://cdn.puntajenacional.cl/layout2015/images/prelog/slide02.png"},
    // 	{titulo:"3. Recibe inmediatamente tus resultados y revisa tus errores.", url:"https://cdn.puntajenacional.cl/layout2015/images/prelog/slide03.png"},
    // 	{titulo:"4. Estudia y refuerza tus conocimientos más débiles con nuestro material de estudio.", url:"https://cdn.puntajenacional.cl/layout2015/images/prelog/slide04.png"},
    // 	{titulo:"5. ¡Sube tu puntaje!", url:"https://cdn.puntajenacional.cl/layout2015/images/prelog/slide05.png"}];

    // 	this.slides_2 = [
    // 	{titulo:"1. Profesor genera Ensayos personalizados.", url:"https://cdn.puntajenacional.cl/layout2015/images/prelog/paso01.png"},
    // 	{titulo:"2. Los estudiantes realizan ensayos impresos u online.", url:"https://cdn.puntajenacional.cl/layout2015/images/prelog/paso02.png"},
    // 	{titulo:"3. "+companyName+" identifica fortalezas y debilidades.", url:"https://cdn.puntajenacional.cl/layout2015/images/prelog/paso03.png"},
    // 	{titulo:"4. Colegio recibe estadí­sticas y reflexiona sobre los resultados.", url:"https://cdn.puntajenacional.cl/layout2015/images/prelog/paso04.png"},
    // 	{titulo:"5. Profesor genera estrategias de mejora.", url:"https://cdn.puntajenacional.cl/layout2015/images/prelog/paso05.png"}];
    // }

    ngOnInit() {
        this.config = config

        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.video_url)
        this.resizeElements()
        this.setVideoRatioSize()

        this.contactMail = config.plataforma.info.contacto.mail || ""
    }

    onResize(event) {
        this.resizeElements()
    }

    resizeElements() {
        this.windowHeight = window.innerHeight
        var cajas = document.querySelectorAll(".caja"),
            i
            /*for (i = 0; i < cajas.length; ++i) {
			(<HTMLElement>cajas[i]).style.minHeight = this.windowHeight+"px";
		}*/
        ;(<HTMLElement>cajas[0]).style.minHeight = this.windowHeight + "px"
        //(<HTMLElement>document.querySelector('.mascara')).style.minHeight = (this.windowHeight * cajas.length)+"px";
    }

    autoplayVideo() {
        let v = this.video_url + "&autoplay=1"
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(v)
    }

    onResizeVideo(event) {
        this.setVideoRatioSize()
    }

    setVideoRatioSize() {
        if (this.iframeContainer) {
            if (this.iframeContainer.nativeElement.offsetWidth > this.video_max_width) {
                this.video_width = this.video_max_width
            } else {
                this.video_width = this.iframeContainer.nativeElement.offsetWidth
            }
        }
        this.video_height = (this.video_width * this.video_max_height) / this.video_max_width
    }

    onLoadIframe() {
        this.setVideoRatioSize()
    }
}

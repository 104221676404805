/** @format */

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"

import { ProfesoresBlogModule } from "./blog/profesores_blog.module"
import { ProfesoresLayoutsModule } from "./layouts/profesores_layouts.module"
import { ProfesoresLandingModule } from "./landing/profesores_landing.module"
import { ProfesoresUsuariosModule } from "./usuarios/profesores_usuarios.module"
import { ProfesoresEnsayosModule } from "./ensayos/profesores_ensayos.module"
import { ProfesoresMaterialesModule } from "./materiales/profesores_materiales.module"
import { ProfesoresEstadisticasModule } from "./estadisticas/profesores_estadisticas.module"
import { ProfesoresInstrumentosModule } from "./instrumentos/profesores_instrumentos.module"
import { ProfesoresEstablecimientosModule } from "./establecimientos/profesores_establecimientos.module"
import { ProfesoresGrupoUsuariosModule } from "./grupo_usuarios/profesores_grupo_usuarios.module"
import { ProfesoresNoticiasModule } from "./noticias/profesores_noticias.module"
import { ProfesoresPlanEstudiosModule } from "./plan_estudios/profesores_plan_estudios.module"
import { ProfesoresPlanPersonalesModule } from "./plan-personales/profesores_plan_personales.module"
import { ProfesoresPlanClasesModule } from "./plan-clases/profesores-plan-clases.module"
import { ProfesoresStreamingModule } from "./streaming/profesores_streaming.module"
import { config } from "../config/config"
import { environment } from "../environments/environment"
import { AppComponent, getProviders, PuntajeCoreModule } from "@puntaje/puntaje/core"
import { AyudaModule } from "./ayuda/profesores_ayuda.module"

@NgModule({
    imports: [
        ProfesoresBlogModule,
        ProfesoresLayoutsModule,
        ProfesoresLandingModule,
        ProfesoresUsuariosModule,
        ProfesoresEnsayosModule,
        ProfesoresMaterialesModule,
        ProfesoresEstadisticasModule,
        ProfesoresInstrumentosModule,
        ProfesoresEstablecimientosModule,
        ProfesoresGrupoUsuariosModule,
        ProfesoresNoticiasModule,
        ProfesoresPlanEstudiosModule,
        ProfesoresPlanPersonalesModule,
        ProfesoresPlanClasesModule,
        ProfesoresStreamingModule,
        AyudaModule,
        PuntajeCoreModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    providers: getProviders(config, environment)
})
export class AppModule {}

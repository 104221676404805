<form [formGroup]="form" novalidate (ngSubmit)="save(form.value, form.valid)">
    <div class="container">
        <p class="alert alert-success" *ngIf="showSuccess">
            {{ "ayudas.home.contacto.enviado" | t }}
        </p>
        <div class="row">
            <h2>{{ "ayudas.home.contacto.contactanos" | t }}</h2>
            <div class="formulario">
                <div class="input-container">
                    <label>{{ "ayudas.home.contacto.establecimiento" | t }}</label>
                    <select
                        class="form-control"
                        formControlName="establecimiento_id"
                        [(ngModel)]="establecimientoIdSelected"
                    >
                        <option [ngValue]="undefined" disabled selected hidden>
                            {{ "ayudas.home.contacto.placeholder_establecimiento" | t }}
                        </option>
                        <option *ngFor="let establecimiento of establecimientos" [ngValue]="establecimiento.id">
                            {{ establecimiento.establecimiento }}
                        </option>
                    </select>
                </div>
                <div class="input-container">
                    <label>
                        {{ "ayudas.home.contacto.telefono" | t }}
                        <span class="required-mark"></span>
                    </label>
                    <input type="text" formControlName="telefono" class="form-control" placeholder="Ej: +56912345678" />
                    <div
                        class="warning-error"
                        *ngIf="!form.controls.telefono.valid && (form.controls.telefono.touched || submitted)"
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        {{ "ayudas.home.contacto.telefono_requerido" | t }}
                    </div>
                </div>
                <div class="input-container">
                    <label>
                        {{ "ayudas.home.contacto.mensaje" | t }}
                        <span class="required-mark"></span>
                    </label>
                    <textarea
                        formControlName="mensaje"
                        class="form-control"
                        placeholder="{{ 'ayudas.home.contacto.motivo' | t }}"
                        rows="10"
                    ></textarea>
                    <div
                        class="warning-error"
                        *ngIf="!form.controls.mensaje.valid && (form.controls.mensaje.touched || submitted)"
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        {{ "ayudas.home.contacto.motivo_requerido" | t }}
                    </div>
                </div>
                <cui-button type="submit" [isBlock]="true" class="btn_style">
                    {{ "ayudas.home.contacto.enviar_mensaje" | t }}
                </cui-button>
            </div>
        </div>
    </div>
</form>
